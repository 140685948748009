<template>
    <console-content max-width="1140">
        <v-layout class="px-1" align="center">
            <v-flex shrink class="headline">테마 설정</v-flex>
        </v-layout>
        
        <v-list-item-group class="mt-4">
            <v-layout class="mx-n2">
                <v-flex v-for="(theme, index) in themes" :key="index" xs12 md6 class="px-2 py-2 py-md-0">
                    <v-list-item :ripple="false" class="flex">
                        <v-list-item-icon class="elevation-3 radius-3 white">
                            <v-img v-if="theme?.images?.[0]" :src="theme.images[0]" width="256" height="192"/>
                            <v-sheet v-else width="256" height="192"></v-sheet>
                        </v-list-item-icon>
                        <v-list-item-content class="fill-height align-self-stretch px-2 py-4">
                            <v-responsive class="mb-auto">
                                <v-list-item-title class="d-flex" @click="details(theme)">
                                    <b>{{ theme.name }}</b>
                                    <v-spacer/>
                                    <v-icon @click="details(theme)" class="mr-2">{{ mdiCog }}</v-icon>
                                    <v-icon @click.stop="remove(theme)">mdi-delete</v-icon>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <p class="body-2 grey--text mt-1" v-html="theme?.description?.replace?.(/\n/g, '<br>')"></p>
                                </v-list-item-subtitle>
                            </v-responsive>
                        </v-list-item-content>
                    </v-list-item>
                </v-flex>
            </v-layout>
        </v-list-item-group>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

        <v-btn fab fixed bottom right color="accent" @click="create"><v-icon>mdi-pencil</v-icon></v-btn>

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import { mdiCog, mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent
    },
    data(){
        return {
            mdiCog,
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            themes: [],
            themesHeaders: [
                { text: "no", value: "no", align: "center", /* width: 10 */ },
                { text: "이름", value: "name", align: "center", /* width: 10 */ },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, themes } = await api.console.themes.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.themes = themes;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        create(){
            this.$router.push(`/console/themes/create`);
        },
        edit(theme){
            this.$router.push(`/console/themes/${theme._id}`);
        },
        details(theme){
            this.$router.push(`/console/themes/${theme._id}`);
        },
        async remove(theme){
            if(confirm("삭제하시겠습니까?")){
                await api.console.themes.delete(theme);
                this.search(false);
            }
        },
        async excel(){
            let { themes } = await api.console.themes.gets({ headers: { limit: 0 }, params: this.filter });

            let rows = [];
            themes.forEach(theme => {
                rows.push({
                    "no": theme.no,
                    "이름": theme.name,
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "엑셀시트이름");
            XLSX.writeFile(workbook, "엑셀파일이름.xlsx");
        }
    }
}
</script>