var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.date,
      "transition": "scale-transition",
      "offset-y": "",
      "max-width": "290px",
      "min-width": "auto"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.date = $event;
      },
      "update:return-value": function ($event) {
        _vm.date = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "shrink",
          staticStyle: {
            "width": "160px"
          },
          attrs: {
            "label": _vm.label,
            "readonly": "",
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          scopedSlots: _vm._u([{
            key: "prepend-inner",
            fn: function () {
              return [_c('v-icon', {
                on: {
                  "click": function ($event) {
                    $event.preventDefault();
                    _vm.date = _vm.$dayjs(_vm.date).add(-1, 'month').format('YYYY-MM');
                  }
                }
              }, [_vm._v("mdi-chevron-left")])];
            },
            proxy: true
          }, {
            key: "append",
            fn: function () {
              return [_c('v-icon', {
                on: {
                  "click": function ($event) {
                    $event.preventDefault();
                    _vm.date = _vm.$dayjs(_vm.date).add(1, 'month').format('YYYY-MM');
                  }
                }
              }, [_vm._v("mdi-chevron-right")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.date,
            callback: function ($$v) {
              _vm.date = $$v;
            },
            expression: "date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }])
  }, [_c('v-date-picker', {
    attrs: {
      "type": "month",
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function (value) {
        return _vm.$refs.menu.save(value);
      }
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }