<template>
    <console-content v-if="ready" max-width="1024">

        <v-row class="mt-0 mt-md-4 px-1" align="center">
            <v-col cols="auto" class="headline text-start">
                <span v-if="member._id">회원상세</span>
                <span v-else>회원등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-row class="mt-2">
            <v-col cols="12" md="8" class="pr-md-1">
                <v-card class="mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="member.username" label="아이디" persistent-placeholder hide-details class="mx-1"/>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-password-field v-model="member.password" label="비밀번호" persistent-placeholder hide-details class="mx-1"/>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="member.name" label="이름" persistent-placeholder hide-details class="mx-1 mt-4"/>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="member.birthday" label="생년월일(YYMMDD)" persistent-placeholder hide-details class="mx-1 mt-4"/>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="member.phone" label="연락처" persistent-placeholder hide-details class="mx-1 mt-4"/>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="member.email" label="이메일" persistent-placeholder hide-details class="mx-1 mt-4"/>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="member.postcode" label="우편번호" persistent-placeholder hide-details readonly @click="$refs.daumPostcode.open()" class="mx-1 mt-4"/>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="member.address1" label="기본주소" persistent-placeholder hide-details readonly @click="$refs.daumPostcode.open()" class="mx-1 mt-4"/>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="member.address2" label="상세주소" persistent-placeholder hide-details class="mx-1 mt-4"/>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" md="6">
                                <v-select v-model="member.levelCode" :items="levels" item-text="name" item-value="code" label="회원등급" persistent-placeholder hide-details class="mx-1 mt-4"/>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select v-model="member.riskManagement.giftcard.level" :items="riskManagement.giftcard.levels" item-text="text" item-value="value" label="과금등급" persistent-placeholder hide-details class="mx-1 mt-4"/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="4" class="pl-md-1">

                <v-card class="mx-1 mt-3 mt-md-0">
                    <v-card-title>
                        <span class="subtitle-2">로그인 실패횟수 ({{ member.loginFailedCount[$dayjs().format("YYYY-MM-DD")] || 0 }}/5)</span>
                        <v-spacer/>
                        <v-icon size="20" @click="member.loginFailedCount = new Map()">{{ mdiRefresh }}</v-icon>
                    </v-card-title>
                </v-card>

                <v-card class="mx-1 mt-4">
                    <v-card-title>
                        <span class="subtitle-2">차단여부 (차단안함/차단)</span>
                        <v-spacer/>
                        <v-switch v-model="member.isBlock" hide-details class="mt-0"/>
                    </v-card-title>
                </v-card>
                <v-card class="mx-1 mt-2">
                    <v-card-title>
                        <span class="subtitle-2">휴면여부 (휴면안함/휴면)</span>
                        <v-spacer/>
                        <v-switch v-model="member.isDormancy" hide-details class="mt-0"/>
                    </v-card-title>
                </v-card>
                
                <v-card class="mx-1 mt-4">
                    <v-card-title>
                        <span class="subtitle-2">SMS 수신여부 (수신안함/수신)</span>
                        <v-spacer/>
                        <v-switch v-model="member.smsEnabled" hide-details class="mt-0"/>
                    </v-card-title>
                </v-card>
                <v-card class="mx-1 mt-2">
                    <v-card-title>
                        <span class="subtitle-2">Email 수신여부 (수신안함/수신)</span>
                        <v-spacer/>
                        <v-switch v-model="member.emailEnabled" hide-details class="mt-0"/>
                    </v-card-title>
                </v-card>

                <v-card class="mx-1 mt-4">
                    <v-card-title>
                        <span class="subtitle-2">주문제한해제 (제한/해제)</span>
                        <v-spacer/>
                        <v-switch v-model="member.riskManagement.disabled" hide-details class="mt-0"/>
                    </v-card-title>

                    <template v-if="siteInfo.customLimitEnabled">
                        <v-divider />
                        <v-card :disabled="member.riskManagement.disabled">
                            <v-card-title>
                                <span class="subtitle-2">회원별 구매제한금액(일간)</span>
                                <v-spacer/>
                                <v-text-field v-model="member.riskManagement.giftcard.dailyLimitAmount" type="number" hide-details class="mt-0"/>
                            </v-card-title>
                            <v-card-title>
                                <span class="subtitle-2">회원별 구매제한금액(월간)</span>
                                <v-spacer/>
                                <v-text-field v-model="member.riskManagement.giftcard.monthlyLimitAmount" type="number" hide-details class="mt-0"/>
                            </v-card-title>
                        </v-card>
                    </template>
                </v-card>

                <v-card v-if="siteInfo.isDisapproval === '1'" class="mx-1 mt-4">
                    <v-card-title>
                        <span class="subtitle-2">가입승인 (승인/미승인)</span>
                        <v-spacer/>
                        <v-switch v-model="member.isDisapproval" color="red" hide-details class="mt-0"/>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">관리자 메모</v-card-title>
            <v-card-text>
                <v-textarea v-model="member.note" outlined hide-details rows="2" />
            </v-card-text>
        </v-card>

        <purchase-list />

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

        <daum-postcode ref="daumPostcode" @input="({ postcode, address }) => { member.postcode = postcode; member.address1 = address; }" />

    </console-content>
</template>

<script>
import api from "@/api";
import { mdiRefresh } from "@mdi/js";
import CryptoAES from "@/plugins/crypto-aes";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import PurchaseList from "@/components/console/user/purchase-list.vue";
import { mapActions, mapState } from "vuex";


export default {
    components: {
        ConsoleContent,
        NaverSmarteditor,
        VPasswordField,
        DaumPostcode,
        PurchaseList
    },
    data(){
        return {
            mdiRefresh,
            
            ready: false,

            member: {
                _id: this.$route.params._member
            },

            levels: [{ name: "normal", code: "normal" }, { name: "vip", code: "vip" }],

            riskManagement: {
                giftcard: {
                    levels: [
                        { text: "normal", value: "normal" },
                        { text: "vip", value: "vip" },
                        { text: "vvip", value: "vvip" },
                    ]
                }
            },
        }
    },
    mounted(){
        this.getSiteInfo();
        this.init();
    },
    computed: {
        ...mapState(["siteInfo"]),
    },
    methods: {
        ...mapActions("siteInfo", ["getSiteInfo"]),
        async init(){
            if(this.member._id){
                let { member } = await api.console.members.get(this.member);
                this.member = member;
            }

            this.ready = true;
        },
        async save(){
            this.member._id ? await api.console.members.put({
                ...this.member,
                password: this.member.password ? CryptoAES.encrypt(this.member.password) : undefined
            }) : await api.console.members.post({
                ...this.member,
                password: this.member.password ? CryptoAES.encrypt(this.member.password) : undefined
            });
            alert("저장되었습니다");
            this.$router.go(-1);
        }
    }
}
</script>