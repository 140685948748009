var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 mt-md-8 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.question._id ? _c('span', [_vm._v("문의상세")]) : _c('span', [_vm._v("문의등록")])]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.question.subject,
      callback: function ($$v) {
        _vm.$set(_vm.question, "subject", $$v);
      },
      expression: "question.subject"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.$dayjs(_vm.question.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      "label": "문의일자",
      "placeholder": " ",
      "hide-details": "",
      "readonly": ""
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("문의내용")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.question.content,
      callback: function ($$v) {
        _vm.$set(_vm.question, "content", $$v);
      },
      expression: "question.content"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("답변")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.question.answer,
      callback: function ($$v) {
        _vm.$set(_vm.question, "answer", $$v);
      },
      expression: "question.answer"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }