<template>
    <console-content max-width="1024">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">교환·반품·품절 안내</v-col>
            <v-spacer />
        </v-row>

        <v-card class="mt-4">
            <term-desc-table v-model="setting.informations" v-bind="{ initItems }" />
        </v-card>

        <v-layout justify-end class="mt-4"> <v-btn color="primary" @click="save">저장하기</v-btn> </v-layout>
    </console-content>
</template>

<script>
import api from "@/api";
import TermDescTable from "@/components/console/dumb/term-desc-table.vue";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import VImageField from "@/components/plugins/vuetify/v-image-field.vue";

let initItems = () => [
    { id: Math.random(), term: "반품/교환방법", desc: null },
    { id: Math.random(), term: "반품/교환가능 기간", desc: null },
    { id: Math.random(), term: "반품/교환비용", desc: null },
    { id: Math.random(), term: "반품/교환 불가 사유", desc: null },
    { id: Math.random(), term: "상품 품절", desc: null },
    { id: Math.random(), term: "소비자 피해보상<br/>환불지연에 따른 배상", desc: null },
    { id: Math.random(), term: "청약 철회 방침", desc: null },
];

export default {
    components: {
        TermDescTable,
        ConsoleContent,
        VImageField,
    },
    created() {
        this.init();
    },
    data() {
        return {
            setting: {
                informations: [],
            },

            initItems,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            var { setting } = await api.console.gshop.setting.get();
            this.setting = setting;
        },

        async save() {
            let { _id, informations } = this.setting;
            await api.console.gshop.setting.put({ _id, informations });
            alert("저장되었습니다.");
        },
    },
};
</script>
