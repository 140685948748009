var render = function render(){
  var _vm$summaries$reduce, _vm$summaries$reduce$, _vm$summaries$reduce2, _vm$summaries$reduce3, _vm$summaries$reduce4, _vm$summaries$reduce5, _vm$summaries$reduce6, _vm$summaries$reduce7, _vm$summaries$reduce8, _vm$summaries$reduce9, _vm$summaries$reduce10, _vm$summaries$reduce11, _vm$summaries$reduce12, _vm$summaries$reduce13, _vm$summaries$reduce14, _vm$summaries$reduce15, _vm$idle, _vm$idle$totalCount, _vm$idle$totalCount$f, _vm$issued, _vm$issued$totalCount, _vm$issued$totalCount2, _vm$canceled, _vm$canceled$totalCou, _vm$canceled$totalCou2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', {
    staticClass: "overflow-visible"
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-title', [_c('v-layout', {
    attrs: {
      "align-center": ""
    }
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("상품권 옵션 목록")])]), _c('v-spacer'), (_vm.scope.includes('root') || _vm.siteInfo.pinBtn) && _vm.siteInfo.nanuriGiftcardEnabled ? _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "color": "secondary",
      "small": "",
      "height": "36"
    },
    on: {
      "click": function ($event) {
        return _vm.add({
          platform: `nanuri-giftcard`
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("나누리 대행 옵션 추가")], 1) : _vm._e(), _vm.scope.includes('root') || _vm.siteInfo.pinBtn ? _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "small": "",
      "height": "36"
    },
    on: {
      "click": function ($event) {
        return _vm.add({
          platform: 'nanuri'
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("나누리핀 옵션 추가")], 1) : _vm._e(), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "small": "",
      "height": "36"
    },
    on: {
      "click": function ($event) {
        return _vm.add({
          platform: 'excel'
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("엑셀 옵션 추가")], 1)], 1)], 1), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "items": _vm.options.filter(function (option) {
        return !option.isDeleted;
      }),
      "headers": _vm.optionsHeaders.filter(function (header) {
        return !header.scope || _vm.scope.includes(header.scope);
      })
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function (_ref) {
        var _vm$placeholders$inde;
        var item = _ref.item,
          index = _ref.index;
        return _c('v-text-field', {
          attrs: {
            "dense": "",
            "hide-details": "",
            "outlined": "",
            "placeholder": ((_vm$placeholders$inde = _vm.placeholders[index]) === null || _vm$placeholders$inde === void 0 ? void 0 : _vm$placeholders$inde.name) || ''
          },
          model: {
            value: item.name,
            callback: function ($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        });
      }
    }, {
      key: "item.platform",
      fn: function (_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return _c('v-select', {
          attrs: {
            "items": [`excel`, `nanuri`, `nanuri-api`, `nanuri-excel`, `nanuri-giftcard`],
            "outlined": "",
            "hide-details": "",
            "dense": "",
            "disabled": !_vm.scope.includes('root')
          },
          model: {
            value: item.platform,
            callback: function ($$v) {
              _vm.$set(item, "platform", $$v);
            },
            expression: "item.platform"
          }
        });
      }
    }, {
      key: "item.code",
      fn: function (_ref3) {
        var _vm$placeholders$inde2;
        var item = _ref3.item,
          index = _ref3.index;
        return _c('v-text-field', {
          attrs: {
            "dense": "",
            "hide-details": "",
            "outlined": "",
            "placeholder": ((_vm$placeholders$inde2 = _vm.placeholders[index]) === null || _vm$placeholders$inde2 === void 0 ? void 0 : _vm$placeholders$inde2.code) || ''
          },
          model: {
            value: item.code,
            callback: function ($$v) {
              _vm.$set(item, "code", $$v);
            },
            expression: "item.code"
          }
        });
      }
    }, {
      key: "item.faceamt",
      fn: function (_ref4) {
        var _vm$placeholders$inde3;
        var item = _ref4.item,
          index = _ref4.index;
        return _c('v-text-field', {
          attrs: {
            "dense": "",
            "hide-details": "",
            "outlined": "",
            "suffix": "원",
            "placeholder": ((_vm$placeholders$inde3 = _vm.placeholders[index]) === null || _vm$placeholders$inde3 === void 0 ? void 0 : _vm$placeholders$inde3.faceamt) || ''
          },
          model: {
            value: item.faceamt,
            callback: function ($$v) {
              _vm.$set(item, "faceamt", $$v);
            },
            expression: "item.faceamt"
          }
        });
      }
    }, {
      key: "item.salePrice",
      fn: function (_ref5) {
        var _vm$placeholders$inde4;
        var item = _ref5.item,
          index = _ref5.index;
        return _c('v-text-field', {
          attrs: {
            "dense": "",
            "hide-details": "",
            "outlined": "",
            "suffix": "원",
            "placeholder": ((_vm$placeholders$inde4 = _vm.placeholders[index]) === null || _vm$placeholders$inde4 === void 0 ? void 0 : _vm$placeholders$inde4.salePrice) || ''
          },
          model: {
            value: item.salePrice,
            callback: function ($$v) {
              _vm.$set(item, "salePrice", $$v);
            },
            expression: "item.salePrice"
          }
        });
      }
    }, {
      key: "item.commissionPrice",
      fn: function (_ref6) {
        var item = _ref6.item,
          index = _ref6.index;
        return _c('span', {}, [_vm._v(" " + _vm._s((Math.floor(item.salePrice * _vm.giftcard.commission.mobile / (1000 * _vm.giftcard.commission.cutUnit)) * _vm.giftcard.commission.cutUnit * 10).format()) + "원 ")]);
      }
    }, {
      key: "item.actions",
      fn: function (_ref7) {
        var item = _ref7.item,
          index = _ref7.index;
        return [_c('v-btn', {
          staticClass: "px-1 mr-1 primary--text",
          staticStyle: {
            "min-width": "auto"
          },
          attrs: {
            "color": "white",
            "width": "36",
            "height": "36"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(_vm.options, item, index);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }])
  })], 1), _c('v-card', {
    staticClass: "mx-1 mt-4",
    attrs: {
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', [_c('v-layout', {
    attrs: {
      "align-center": ""
    }
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("핀 누적 집계")])]), _c('v-spacer')], 1)], 1), _c('v-divider'), _c('v-simple-table', {
    attrs: {
      "dense": ""
    }
  }, [_c('tbody', {
    staticClass: "text-end"
  }, [_c('tr', [_c('th', {
    staticClass: "text-end"
  }, [_vm._v("전체")]), _c('th', {
    staticClass: "text-end"
  }, [_vm._v("미발행")]), _c('th', {
    staticClass: "text-end"
  }, [_vm._v("발행완료")]), _c('th', {
    staticClass: "text-end"
  }, [_vm._v("취소완료")])]), _c('tr', [_c('td', [_vm._v(_vm._s((_vm$summaries$reduce = _vm.summaries.reduce(function (totalCount, summary) {
    return totalCount + +summary.totalCount;
  }, 0)) === null || _vm$summaries$reduce === void 0 ? void 0 : (_vm$summaries$reduce$ = _vm$summaries$reduce.format) === null || _vm$summaries$reduce$ === void 0 ? void 0 : _vm$summaries$reduce$.call(_vm$summaries$reduce)) + "건")]), _c('td', [_vm._v(_vm._s((_vm$summaries$reduce2 = _vm.summaries.reduce(function (totalCount, summary) {
    return totalCount + (summary.status == "미발행" ? summary.totalCount : 0);
  }, 0)) === null || _vm$summaries$reduce2 === void 0 ? void 0 : (_vm$summaries$reduce3 = _vm$summaries$reduce2.format) === null || _vm$summaries$reduce3 === void 0 ? void 0 : _vm$summaries$reduce3.call(_vm$summaries$reduce2)) + "건")]), _c('td', [_vm._v(_vm._s((_vm$summaries$reduce4 = _vm.summaries.reduce(function (totalCount, summary) {
    return totalCount + (summary.status == "발행완료" ? summary.totalCount : 0);
  }, 0)) === null || _vm$summaries$reduce4 === void 0 ? void 0 : (_vm$summaries$reduce5 = _vm$summaries$reduce4.format) === null || _vm$summaries$reduce5 === void 0 ? void 0 : _vm$summaries$reduce5.call(_vm$summaries$reduce4)) + "건")]), _c('td', [_vm._v(_vm._s((_vm$summaries$reduce6 = _vm.summaries.reduce(function (totalCount, summary) {
    return totalCount + (summary.status == "취소완료" ? summary.totalCount : 0);
  }, 0)) === null || _vm$summaries$reduce6 === void 0 ? void 0 : (_vm$summaries$reduce7 = _vm$summaries$reduce6.format) === null || _vm$summaries$reduce7 === void 0 ? void 0 : _vm$summaries$reduce7.call(_vm$summaries$reduce6)) + "건")])]), _c('tr', [_c('td', [_vm._v(_vm._s((_vm$summaries$reduce8 = _vm.summaries.reduce(function (totalAmount, summary) {
    return totalAmount + summary.totalCount * summary.faceamt;
  }, 0)) === null || _vm$summaries$reduce8 === void 0 ? void 0 : (_vm$summaries$reduce9 = _vm$summaries$reduce8.format) === null || _vm$summaries$reduce9 === void 0 ? void 0 : _vm$summaries$reduce9.call(_vm$summaries$reduce8)) + "원")]), _c('td', [_vm._v(_vm._s((_vm$summaries$reduce10 = _vm.summaries.reduce(function (totalAmount, summary) {
    return totalAmount + (summary.status == "미발행" ? summary.totalCount * summary.faceamt : 0);
  }, 0)) === null || _vm$summaries$reduce10 === void 0 ? void 0 : (_vm$summaries$reduce11 = _vm$summaries$reduce10.format) === null || _vm$summaries$reduce11 === void 0 ? void 0 : _vm$summaries$reduce11.call(_vm$summaries$reduce10)) + "원")]), _c('td', [_vm._v(_vm._s((_vm$summaries$reduce12 = _vm.summaries.reduce(function (totalAmount, summary) {
    return totalAmount + (summary.status == "발행완료" ? summary.totalCount * summary.faceamt : 0);
  }, 0)) === null || _vm$summaries$reduce12 === void 0 ? void 0 : (_vm$summaries$reduce13 = _vm$summaries$reduce12.format) === null || _vm$summaries$reduce13 === void 0 ? void 0 : _vm$summaries$reduce13.call(_vm$summaries$reduce12)) + "원")]), _c('td', [_vm._v(_vm._s((_vm$summaries$reduce14 = _vm.summaries.reduce(function (totalAmount, summary) {
    return totalAmount + (summary.status == "취소완료" ? summary.totalCount * summary.faceamt : 0);
  }, 0)) === null || _vm$summaries$reduce14 === void 0 ? void 0 : (_vm$summaries$reduce15 = _vm$summaries$reduce14.format) === null || _vm$summaries$reduce15 === void 0 ? void 0 : _vm$summaries$reduce15.call(_vm$summaries$reduce14)) + "원")])]), _c('tr')])])], 1), _c('v-card', {
    staticClass: "mx-1 mt-4",
    attrs: {
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', [_c('v-layout', {
    attrs: {
      "align-center": ""
    }
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("핀 목록")])]), _c('v-spacer'), _c('v-btn', {
    staticClass: "my-auto mr-2",
    attrs: {
      "disabled": !_vm.giftcard._id,
      "color": "green",
      "outlined": "",
      "height": "36",
      "to": `/console/gshop/giftcards/${_vm.$route.params._giftcard}/stock`
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(_vm._s(_vm.mdiMicrosoftExcel))]), _c('span', [_vm._v("재고관리 페이지")])], 1)], 1)], 1), _c('v-divider'), !_vm.loading ? [_c('v-tabs', {
    attrs: {
      "height": "56"
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('v-tab', [_vm._v("미발행(" + _vm._s(((_vm$idle = _vm.idle) === null || _vm$idle === void 0 ? void 0 : (_vm$idle$totalCount = _vm$idle.totalCount) === null || _vm$idle$totalCount === void 0 ? void 0 : (_vm$idle$totalCount$f = _vm$idle$totalCount.format) === null || _vm$idle$totalCount$f === void 0 ? void 0 : _vm$idle$totalCount$f.call(_vm$idle$totalCount)) || 0) + ")")]), _c('v-tab', [_vm._v("발행(" + _vm._s(((_vm$issued = _vm.issued) === null || _vm$issued === void 0 ? void 0 : (_vm$issued$totalCount = _vm$issued.totalCount) === null || _vm$issued$totalCount === void 0 ? void 0 : (_vm$issued$totalCount2 = _vm$issued$totalCount.format) === null || _vm$issued$totalCount2 === void 0 ? void 0 : _vm$issued$totalCount2.call(_vm$issued$totalCount)) || 0) + ")")]), _c('v-tab', [_vm._v("취소(" + _vm._s(((_vm$canceled = _vm.canceled) === null || _vm$canceled === void 0 ? void 0 : (_vm$canceled$totalCou = _vm$canceled.totalCount) === null || _vm$canceled$totalCou === void 0 ? void 0 : (_vm$canceled$totalCou2 = _vm$canceled$totalCou.format) === null || _vm$canceled$totalCou2 === void 0 ? void 0 : _vm$canceled$totalCou2.call(_vm$canceled$totalCou)) || 0) + ")")]), _c('v-spacer'), _c('div', {
    staticClass: "d-flex mt-2 pr-6"
  }, [0 < _vm.tabIndex ? _c('v-date-month', {
    attrs: {
      "label": _vm.tabIndex == 1 ? '발행일자' : '취소일자'
    },
    model: {
      value: _vm.filter.date,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "date", $$v);
      },
      expression: "filter.date"
    }
  }) : _vm._e(), _c('v-select', {
    staticClass: "ml-2",
    attrs: {
      "label": "상품권 이름(필터)",
      "items": [].concat([{
        text: ':: 전체 ::',
        value: null
      }], _vm.options.filter(function (option) {
        return !!option.name;
      }).map(function (option) {
        return {
          text: option.name,
          value: option.name
        };
      })),
      "persistent-placeholder": "",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.name,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "name", $$v);
      },
      expression: "filter.name"
    }
  })], 1)], 1), _c('v-divider'), _c('v-tabs-items', {
    attrs: {
      "touchless": ""
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('v-tab-item', [_c('giftcard-pins', {
    attrs: {
      "giftcard": _vm.giftcard,
      "filter": {
        status: `미발행`,
        name: _vm.filter.name
      }
    },
    on: {
      "search": function (summary) {
        return _vm.idle = summary;
      }
    }
  })], 1), _c('v-tab-item', [_c('giftcard-pins', {
    attrs: {
      "giftcard": _vm.giftcard,
      "filter": {
        status: `발행완료`,
        name: _vm.filter.name,
        issuedAt: _vm.filter.date
      }
    },
    on: {
      "search": function (summary) {
        return _vm.issued = summary;
      }
    }
  })], 1), _c('v-tab-item', [_c('giftcard-pins', {
    attrs: {
      "giftcard": _vm.giftcard,
      "filter": {
        status: `취소완료`,
        name: _vm.filter.name,
        canceledAt: _vm.filter.date
      }
    },
    on: {
      "search": function (summary) {
        return _vm.canceled = summary;
      }
    }
  })], 1)], 1)] : [_c('v-layout', {
    staticClass: "py-10",
    attrs: {
      "align-center": "",
      "justify-center": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "24"
    }
  })], 1)]], 2), _c('upload-dialog', {
    ref: "uploadDialog",
    on: {
      "input": _vm.upload
    }
  }), _vm.siteInfo.nanuriGiftcardEnabled ? _c('nanuri-giftcard-dialog', {
    ref: "nanuriGiftcardDialog"
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }