<template>
    <console-content max-width="1024">
        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="py-0 headline text-start">가격별 주문명칭</v-col>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
            </v-col>
        </v-row>

        <v-data-table :items="orderNames" :headers="orderNamesHeaders" hide-default-footer disable-sort disable-pagination class="mt-4 mx-1 elevation-1">
            <template slot="item.min-max" slot-scope="{item}">
                {{ item.min.format() }}(이상) ~ {{ item.max.format() }}(이하)
            </template>
            <span slot="item.actions" slot-scope="{item, index}">
                <v-icon small @click="details(item)" class="mx-1">mdi-pencil</v-icon>
                <v-icon small @click="remove(item, index)" class="mx-1">mdi-delete</v-icon>
            </span>
        </v-data-table>

        <v-btn fab fixed bottom right color="accent" @click="create()"><v-icon>mdi-pencil</v-icon></v-btn>

        <order-name-dialog ref="orderNameDialog" :orderNames="orderNames" @save="search()"/>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import OrderNameDialog from "@/components/console/gshop/setting/OrderNameDialog.vue";

export default {
    components: {
        ConsoleContent,
        VDateField,
        OrderNameDialog
    },
    data(){
        return {
            orderNames: [],
            orderNamesHeaders: [
                { text: "금액구간", value: "min-max", align: "center", /* width: 10 */ },
                { text: "주문명", value: "name", align: "center", /* width: 10 */ },
                { text: "actions", value: "actions", align: "center", /* width: 10 */ },
            ],
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search();
        },
        async search(){
            let { setting: { orderNames } } = await api.console.gshop.setting.get();

            this.orderNames = orderNames;
        },
        async remove(orderName, index){
            if(confirm("삭제하시겠습니까?")){
                this.orderNames.splice(index, 1);
    
                await api.console.gshop.setting.put({ orderNames: this.orderNames })
            }
        },
        create(){
            this.$refs.orderNameDialog.open();
        },
        details(orderName){
            this.$refs.orderNameDialog.index = this.orderNames.indexOf(orderName);
            this.$refs.orderNameDialog.orderName = JSON.parse(JSON.stringify(orderName));
            this.$refs.orderNameDialog.open();
        }
    }
}
</script>
