<template>
    <v-dialog v-model="show" max-width="300">
        <v-card>
            <v-card-title>
                <span class="subtitle-2">포인트 수동</span>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="point.username" label="아이디 *" persistent-placeholder outlined dense hide-details />
                <v-select v-model="point.type" :items="types" item-text="name" item-value="value" label="유형 *" persistent-placeholder outlined dense hide-details class="mt-4"/>
                <v-text-field v-model="point.amount" label="포인트 *" persistent-placeholder outlined dense hide-details class="mt-4" />
                <v-text-field v-model="point.remark" label="비고" persistent-placeholder outlined dense hide-details class="mt-4" />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="grey" text @click="close()">취소</v-btn>
                <v-btn color="primary" text @click="submit()">승인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {
            show: false,

            point: {
                username: null,
                type: null,
                amount: 0
            },

            types: [
                { name: "충전", value: "charge" },
                { name: "차감", value: "use" },
            ]
        }
    },
    methods: {
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
            this.point = {
                username: null,
                type: null,
                amount: 0,
                remark: null
            };
        },
        validate(){
            try{
                if(!this.point.username) throw new Error("아이디를 입력하세요");
                if(!this.point.type) throw new Error("유형을 선택하세요");
                if(!this.point.amount) throw new Error("포인트를 입력하세요");

                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async submit(){
            if(this.validate()){
                await api.console.user.points.post(this.point);
                this.$emit("submit");
                this.close();
            }
        },
    }
}
</script>