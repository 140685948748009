var render = function render(){
  var _vm$giftcard$thumb, _vm$giftcard$thumb2, _vm$option;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-simple-table', [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("옵션")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("플랫폼")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("액면가")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("판매가")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("수수료[모바일](원)")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("actions")])]), _c('tr', [_c('td', {
    staticClass: "text-center"
  }, [_c('v-layout', {
    attrs: {
      "align-center": ""
    }
  }, [_vm.giftcard.thumb ? _c('v-img', {
    staticClass: "v-icon--left",
    attrs: {
      "src": ((_vm$giftcard$thumb = _vm.giftcard.thumb) === null || _vm$giftcard$thumb === void 0 ? void 0 : _vm$giftcard$thumb.path) || ((_vm$giftcard$thumb2 = _vm.giftcard.thumb) === null || _vm$giftcard$thumb2 === void 0 ? void 0 : _vm$giftcard$thumb2.src),
      "max-width": "72",
      "height": "auto",
      "contain": ""
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.option.name) + " ")], 1)], 1), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.option.platform))]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.option.faceamt.format()) + " ")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.option.salePrice.format()) + " ")]), _c('td', {
    staticClass: "text-center"
  }, [_c('span', [_vm._v(" " + _vm._s((Math.floor(_vm.option.salePrice * _vm.giftcard.commission.mobile / (1000 * _vm.giftcard.commission.cutUnit)) * _vm.giftcard.commission.cutUnit * 10).format()) + "원 ")])]), _c('td', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "px-1 mr-1 primary--text",
    staticStyle: {
      "min-width": "auto"
    },
    attrs: {
      "disabled": !_vm.scope.includes('root'),
      "color": "white",
      "width": "36",
      "height": "36"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('remove');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)], 1)])])]), _c('v-divider'), _c('v-card-title', [_c('v-layout', {
    attrs: {
      "align-center": ""
    }
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_c('b', [_vm._v("핀 재고 목록 (미발행, " + _vm._s(_vm.summary.totalCount.format()) + "건)")])]), _c('v-spacer'), _c('v-btn', {
    staticClass: "my-auto mr-2",
    attrs: {
      "color": "green",
      "outlined": "",
      "height": "36"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.uploadDialog.open();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(_vm._s(_vm.mdiMicrosoftExcel))]), _c('span', [_vm._v("엑셀 핀 업로드")])], 1)], 1)], 1), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "item-key": "_id",
      "show-select": "",
      "checkbox-color": "red lighten-1",
      "items": _vm.option.pins || [],
      "headers": _vm.pinsHeaders
    },
    scopedSlots: _vm._u([{
      key: "item.faceamt",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.faceamt.format()) + " ")];
      }
    }, {
      key: "item.createdAt",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")) + " ")];
      }
    }, {
      key: "footer.prepend",
      fn: function () {
        return [_c('v-btn', {
          attrs: {
            "color": "red lighten-2",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-delete-forever-outline")]), _vm._v("미발행 선택삭제")], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('upload-dialog', {
    ref: "uploadDialog",
    attrs: {
      "option": _vm.option,
      "platform": (_vm$option = _vm.option) === null || _vm$option === void 0 ? void 0 : _vm$option.platform
    },
    on: {
      "input": _vm.upload
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }