<template>
    <console-content max-width="1024">

        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="py-0 headline text-start">
                <span v-if="boilerplate._id">상세</span>
                <span v-else>등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-row class="mt-3">
            <v-col cols="12" md="8" class="py-0 pr-md-1">
                <v-card class="mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6" class="py-0">
                                <v-text-field label=" " placeholder=" " hide-details />
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                                <v-text-field label=" " placeholder=" " hide-details />
                            </v-col>
                        </v-row>
                        <v-row class="mt-4">
                            <v-col cols="12" md="6" class="py-0">
                                <v-select label=" " placeholder=" " hide-details />
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                                <v-text-field label=" " placeholder=" " hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="4" class="py-0 pl-md-1">
                <v-card class="mx-1 mt-3 mt-md-0">
                    <v-card-title>
                        <span class="subtitle-2">속성</span>
                        <v-spacer/>
                        <v-switch hide-details class="mt-0"/>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">상세정보</v-card-title>
            <v-card-text>
                <naver-smarteditor />
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            boilerplate: {
                _id: this.$route.params._boilerplate
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.boilerplate._id){
                let { boilerplate } = await api.console.boilerplates.get(this.boilerplate);
                this.boilerplate = boilerplate;
            }
        },
        async save(){
            this.boilerplate._id ? await api.console.boilerplates.put() : await api.console.boilerplates.post();
            alert("저장되었습니다");
            this.$router.go(-1);
        }
    }
}
</script>