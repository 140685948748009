<template>
    <console-content width="100%" max-width="1200">

        <v-row class="mt-md-4 px-1" align="center">
            <v-col cols="auto" class="subtitle-1">
                <strong>
                    <span v-if="reception._id">문자 / 알림톡 / 메일링 수정</span>
                    <span v-else>문자 / 알림톡 / 메일링 등록</span>
                </strong>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
            <v-card-text>
                <v-layout wrap class="mx-n4">
                    <v-flex xs12 md8 class="px-4">
                        <v-text-field v-model="reception.name" label="명칭" persistent-placeholder hide-details />
                    </v-flex>
                    <v-flex xs12 md4 class="px-4">
                        <v-text-field v-model="reception.code" label="코드" persistent-placeholder hide-details />
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>

        <v-layout wrap class="mx-n1">
            <v-flex xs12 md8 class="px-1">
                <v-card class="mt-4 mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">문자 / 알림톡</v-card-title>
                    <v-card-text>
                        <v-textarea v-model="reception.message.content" outlined hide-details rows="10" />
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 md4 class="px-1">
                <v-card class="mt-4 mx-1">
                    <v-card-title>
                        <span class="subtitle-2 font-weight-bold">고객수신</span>
                        <v-spacer/>
                        <v-switch v-model="reception.message.memberEnabled" dense hide-details class="ma-0"/>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text v-show="reception.message.memberEnabled">
                        <v-layout v-for="_, index in reception.message.memberPhones" :key="index" class="mb-4">
                            <v-flex class="pr-2">
                                <v-text-field v-model="reception.message.memberPhones[index]" :label="`사용자 연락처 ${index + 1}`" outlined dense hide-details></v-text-field>
                            </v-flex>
                            <v-flex shrink>
                                <v-btn width="40" height="40" min-width="auto" color="error" outlined  @click="reception.message.memberPhones.splice(index, 1)"><v-icon>mdi-minus</v-icon></v-btn>
                            </v-flex>
                        </v-layout>
                        <v-btn width="100%" color="primary" outlined style="min-width: auto" @click="reception.message.memberPhones.push('')"><v-icon left>mdi-plus</v-icon>사용자 연락처 추가</v-btn>
                    </v-card-text>
                </v-card>

                <v-card class="mt-2 mx-1">
                    <v-card-title>
                        <span class="subtitle-2 font-weight-bold">관리자 수신</span>
                        <v-spacer/>
                        <v-switch v-model="reception.message.adminEnabled" dense hide-details class="ma-0"/>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text v-show="reception.message.adminEnabled">
                        <v-layout v-for="_, index in reception.message.adminPhones" :key="index" class="mb-4">
                            <v-flex class="pr-2">
                                <v-text-field v-model="reception.message.adminPhones[index]" :label="`관리자 연락처 ${index + 1}`" outlined dense hide-details></v-text-field>
                            </v-flex>
                            <v-flex shrink>
                                <v-btn width="40" height="40" min-width="auto" color="error" outlined  @click="reception.message.adminPhones.splice(index, 1)"><v-icon>mdi-minus</v-icon></v-btn>
                            </v-flex>
                        </v-layout>
                        <v-btn width="100%" color="primary" outlined style="min-width: auto" @click="reception.message.adminPhones.push('')"><v-icon left>mdi-plus</v-icon>관리자 연락처 추가</v-btn>
                    </v-card-text>
                </v-card>

                <v-card class="mt-2 mx-1">
                    <v-card-title>
                        <span class="subtitle-2 font-weight-bold">카카오 알림톡</span>
                        <v-spacer/>
                        <v-switch v-model="reception.message.kakaoEnabled" dense hide-details class="ma-0"/>
                    </v-card-title>
                    <v-divider/>
                    <v-card-title>
                        <v-text-field v-model="reception.message.kakaoTemplateCode" label="카카오 템플릿 코드" outlined persistent-placeholder hide-details />
                    </v-card-title>
                </v-card>
            </v-flex>
        </v-layout>


        <v-layout wrap class="mx-n1">
            <v-flex xs12 md8 class="px-1">
                <v-card class="mt-4 mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">이메일</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="reception.email.subject" outlined placeholder="메일 제목" hide-details />

                        <naver-smarteditor v-model="reception.email.content" class="mt-4"/>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12 md4 class="px-1">
                <v-card class="mt-4 mx-1">
                    <v-card-title>
                        <span class="subtitle-2 font-weight-bold">고객수신</span>
                        <v-spacer/>
                        <v-switch v-model="reception.email.memberEnabled" dense hide-details class="ma-0"/>
                    </v-card-title>
                </v-card>

                <v-card class="mt-2 mx-1">
                    <v-card-title>
                        <span class="subtitle-2 font-weight-bold">관리자수신</span>
                        <v-spacer/>
                        <v-switch v-model="reception.email.adminEnabled" dense hide-details class="ma-0"/>
                    </v-card-title>
                    <v-divider/>
                    <v-card v-show="reception.email.adminEnabled">
                        <v-flex v-for="i, index in adminEmailCount" :key="index" class="px-1">
                            <v-text-field v-model="reception.email.adminEmails[index]" :label="`관리자${i}`" outlined dense></v-text-field>
                        </v-flex>
                        <v-flex xs2 class="px-1" v-if="adminEmailCount < 5">
                        <v-btn width="36" color="primary" class="px-1" style="min-width: auto" @click="adminEmailCount++"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-flex>
                        <v-flex xs2 class="px-1" v-if="adminEmailCount > 1">
                        <v-btn width="36" color="primary" class="px-1" style="min-width: auto" @click="adminEmailCount--"><v-icon>mdi-minus</v-icon></v-btn>
                        </v-flex>
                    </v-card>
                </v-card>
            </v-flex>
        </v-layout>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            adminPhoneCount: 1,
            adminEmailCount: 1,
            reception: {
                _id: this.$route.params._reception,

                name: null,
                code: null,

                message: {
                    memberEnabled: false,
                    adminEnabled: false,
                    adminPhones: [],
                    kakaoEnabled: false,
                    kakaoTemplateCode: null,

                    content: null
                },
                email: {
                    memberEnabled: false,
                    adminEnabled: false,
                    adminEmails: [],
                    subject: null,
                    content: null
                }
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.reception._id){
                let { reception } = await api.console.common.receptions.get(this.reception);
                this.reception = reception;
                this.adminPhoneCount = reception.message.adminPhones.length
                this.adminEmailCount = reception.email.adminEmails.length
            }
        },
        async save(){
            this.reception._id ? await api.console.common.receptions.put(this.reception) : await api.console.common.receptions.post(this.reception);
            alert("저장되었습니다");
            this.$router.go(-1);
        }
    }
}
</script>