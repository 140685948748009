var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("교환·반품·품절 안내")]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('term-desc-table', _vm._b({
    model: {
      value: _vm.setting.informations,
      callback: function ($$v) {
        _vm.$set(_vm.setting, "informations", $$v);
      },
      expression: "setting.informations"
    }
  }, 'term-desc-table', {
    initItems: _vm.initItems
  }, false))], 1), _c('v-layout', {
    staticClass: "mt-4",
    attrs: {
      "justify-end": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장하기")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }