var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "500",
      "persistent": _vm.loading
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("주문명 & 금액구간 설정")])]), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "주문명",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.orderName.name,
      callback: function ($$v) {
        _vm.$set(_vm.orderName, "name", $$v);
      },
      expression: "orderName.name"
    }
  }), _c('v-layout', {
    staticClass: "mt-6"
  }, [_c('v-text-field', {
    attrs: {
      "label": "주문금액",
      "persistent-placeholder": "",
      "dense": "",
      "messages": `${parseInt(_vm.orderName.min).format()} 원 이상`
    },
    on: {
      "change": function (value) {
        return _vm.orderName.min = String(value).replace(/[^0-9]/g, '') || 0;
      }
    },
    model: {
      value: _vm.orderName.min,
      callback: function ($$v) {
        _vm.$set(_vm.orderName, "min", $$v);
      },
      expression: "orderName.min"
    }
  }), _c('span', {
    staticClass: "shrink px-4"
  }, [_vm._v("~")]), _c('v-text-field', {
    attrs: {
      "persistent-placeholder": "",
      "dense": "",
      "messages": `${parseInt(_vm.orderName.max).format()} 원 이하`
    },
    on: {
      "change": function (value) {
        return _vm.orderName.max = String(value).replace(/[^0-9]/g, '') || 0;
      }
    },
    model: {
      value: _vm.orderName.max,
      callback: function ($$v) {
        _vm.$set(_vm.orderName, "max", $$v);
      },
      expression: "orderName.max"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "grey darken-2",
      "disabled": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }