<template>
    <console-content max-width="1024">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="$route.params._admin">관리자 상세</span>
                <span v-else>관리자 생성</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mx-1 mt-3">
            <v-card-title class="subtitle-2">기본정보</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field v-model="admin.username" label="아이디" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-password-field v-model="admin.password" label="비밀번호" persistent-placeholder hide-details></v-password-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="admin.name" label="담당자(이름)" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="admin.phone" label="담당자(연락처)" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="admin.email" label="담당자(이메일)" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="admin.postcode" label="우편번호" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="admin.address1" label="기본주소" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="admin.address2" label="상세주소" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions />
        </v-card>
        
        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent,
        VPasswordField
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            admin: {
                username: null,
                password: null,
                name: null,
                phone: null,
                email: null,
                postcode: null,
                address1: null,
                address2: null,
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.$route.params._admin){
                let { admin } = await api.console.admins.get({ _id: this.$route.params._admin });
                this.admin = {
                    ...this.admin,
                    ...admin
                };
            }
        },

        async save(){
            this.admin._id 
            ? await api.console.admins.put({
                ...this.admin,
                password: this.admin.password ? CryptoAES.encrypt(this.admin.password) : undefined
            })
            : await api.console.admins.post({
                ...this.admin,
                password: this.admin.password ? CryptoAES.encrypt(this.admin.password) : undefined
            });

            alert("저장되었습니다");
            this.$router.push(`/console/admins`);
        }
    }
}
</script>