var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "600"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("상품권 추가")])]), _c('v-card-text', [_c('v-combobox', {
    attrs: {
      "label": "상품권",
      "outlined": "",
      "multiple": "",
      "persistent-placeholder": "",
      "hide-details": "",
      "items": _vm.giftcards,
      "item-text": "name",
      "height": "auto"
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("확인")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }