<template>
    <console-content max-width="1280">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline text-start">상품권 관리</v-col>
            <v-spacer/>
            <v-col cols="auto">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>
        
        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mt-2 mx-1">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색조건" placeholder=" " hide-details @input="filter.searchValue = null;" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field v-model="filter.searchValue" label="검색어" placeholder=" " hide-details :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true)" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text @click="$router.push({ query: {} }); $router.go(0)">
                        <v-icon small class="mt-1 mr-2">mdi-refresh</v-icon>초기화
                    </v-btn>
                    <v-btn color="primary" rounded text @click="page = 1; search(true)">
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>
        
        <v-row class="mt-4 px-1" align="end">
            <v-col cols="auto" class=" subtitle-2">검색수 : {{ summary.totalCount.format() }}</v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table :items="giftcards" :headers="giftcardsHeaders" hide-default-footer disable-sort disable-pagination class="mt-2 mx-1 elevation-1">
            <v-img slot="item.thumb" slot-scope="{item}" :src="item?.thumb?.path || item?.thumb?.src" max-width="96" height="96" contain class="ma-auto"/>
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <span slot="item.stock" slot-scope="{item}">
                <v-icon small color="green" @click="$router.push(`/console/gshop/giftcards/${item._id}/stock`)">{{ mdiMicrosoftExcel }}</v-icon>
            </span>
            <span slot="item.actions" slot-scope="{item}">
                <v-icon small class="mx-1" @click="edit(item)">mdi-pencil</v-icon>
                <v-icon small class="mx-1" @click="remove(item)">mdi-delete</v-icon>
            </span>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

        <v-btn fab fixed bottom right color="accent" @click="create"><v-icon>mdi-pencil</v-icon></v-btn>

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,

                type: "giftcard"
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            giftcards: [],
            giftcardsHeaders: [
                { text: "진열순서", value: "no", align: "center", width: 100  },
                { text: "상품코드", value: "code", align: "center", width: 150 },
                { text: "썸네일", value: "thumb", align: "center", width: 150 },
                { text: "상품권 이름", value: "name", align: "center", /* width: 10 */ },
                { text: "등록일자", value: "createdAt", align: "center", width: 200 },
                { text: "재고관리", value: "stock", align: "center", width: 80 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "상품권 이름", value: "name" },
                { text: "상품코드", value: "code" },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, giftcards } = await api.console.gshop.giftcards.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.giftcards = giftcards;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        create(){
            this.$router.push(`/console/gshop/giftcards/create`);
        },
        edit(giftcard){
            this.$router.push(`/console/gshop/giftcards/${giftcard._id}`);
        },
        async remove(giftcard){
            if(confirm("삭제하시겠습니까?")){
                await api.console.gshop.giftcards.delete(giftcard);
                alert("삭제되었습니다");
                this.search(false);
            }
        },
        async excel(){
            let { giftcards } = await api.console.gshop.giftcards.gets({ headers: { limit: 0 }, params: this.filter });

            let rows = [];
            giftcards.forEach(giftcard => {
                rows.push({
                    "진열순서": giftcard.sequence,
                    "상품코드": giftcard.code,
                    "상품이름": giftcard.name,
                    "등록일자": this.$dayjs(giftcard.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "상품목록");
            XLSX.writeFile(workbook, "상품목록.xlsx");
        }
    }
}
</script>