var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1140"
    }
  }, [_c('v-layout', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-flex', {
    staticClass: "headline",
    attrs: {
      "shrink": ""
    }
  }, [_vm._v("테마 설정")])], 1), _c('v-list-item-group', {
    staticClass: "mt-4"
  }, [_c('v-layout', {
    staticClass: "mx-n2"
  }, _vm._l(_vm.themes, function (theme, index) {
    var _theme$images, _theme$description, _theme$description$re;
    return _c('v-flex', {
      key: index,
      staticClass: "px-2 py-2 py-md-0",
      attrs: {
        "xs12": "",
        "md6": ""
      }
    }, [_c('v-list-item', {
      staticClass: "flex",
      attrs: {
        "ripple": false
      }
    }, [_c('v-list-item-icon', {
      staticClass: "elevation-3 radius-3 white"
    }, [theme !== null && theme !== void 0 && (_theme$images = theme.images) !== null && _theme$images !== void 0 && _theme$images[0] ? _c('v-img', {
      attrs: {
        "src": theme.images[0],
        "width": "256",
        "height": "192"
      }
    }) : _c('v-sheet', {
      attrs: {
        "width": "256",
        "height": "192"
      }
    })], 1), _c('v-list-item-content', {
      staticClass: "fill-height align-self-stretch px-2 py-4"
    }, [_c('v-responsive', {
      staticClass: "mb-auto"
    }, [_c('v-list-item-title', {
      staticClass: "d-flex",
      on: {
        "click": function ($event) {
          return _vm.details(theme);
        }
      }
    }, [_c('b', [_vm._v(_vm._s(theme.name))]), _c('v-spacer'), _c('v-icon', {
      staticClass: "mr-2",
      on: {
        "click": function ($event) {
          return _vm.details(theme);
        }
      }
    }, [_vm._v(_vm._s(_vm.mdiCog))]), _c('v-icon', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.remove(theme);
        }
      }
    }, [_vm._v("mdi-delete")])], 1), _c('v-list-item-subtitle', [_c('p', {
      staticClass: "body-2 grey--text mt-1",
      domProps: {
        "innerHTML": _vm._s(theme === null || theme === void 0 ? void 0 : (_theme$description = theme.description) === null || _theme$description === void 0 ? void 0 : (_theme$description$re = _theme$description.replace) === null || _theme$description$re === void 0 ? void 0 : _theme$description$re.call(_theme$description, /\n/g, '<br>'))
      }
    })])], 1)], 1)], 1)], 1);
  }), 1)], 1), _c('v-pagination', {
    staticClass: "mt-3",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-btn', {
    attrs: {
      "fab": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "color": "accent"
    },
    on: {
      "click": _vm.create
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }