<template>
    <console-content max-width="1280">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span>재고관리</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <template v-for="option, index in giftcard.options">
            <giftcard-stock v-if="ready" :key="index" :giftcard="giftcard" :option="option" @remove="remove(option)" />
        </template>

        <option-dialog @confirm="option => insert(option)" />

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import GiftcardStock from "@/components/console/gshop/giftcards/giftcard-stock.vue";
import OptionDialog from "@/components/console/gshop/giftcards/option-dialog.vue";

export default {
    components: {
        ConsoleContent,
        GiftcardStock,
        OptionDialog
    },
    data(){
        return {
            ready: false,

            giftcard: {
                _id: this.$route.params._giftcard,

                no: 0,
                name: null,
                code: null,
                type: "giftcard",

                options: [],
                pins: [],
            },
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { giftcard } = await api.console.gshop.giftcards.get({ _id: this.$route.params._giftcard });
            
            this.giftcard = giftcard;
            this.ready = true;
        },

        validate(){
            let giftcard = this.giftcard;
            try{
                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },

        async insert(option){
            let giftcard = this.giftcard;

            // 상품권 옵션 등록
            option._parent = giftcard._id;
            option.soldout = giftcard.soldout;

            await api.console.gshop.giftcards.post({ 
                type: "option",
                platform: "excel",
                stock: 0,
                ...option, 
                thumb: giftcard.thumb
            });

            alert("옵션이 등록되었습니다");
            
            this.init();
        },

        async remove(option){
            if(confirm("옵션을 삭제하시겠습니까?")){
                await api.console.gshop.giftcards.delete({ _id: option._id });
            }
        }
    },
}
</script>