<template>
    <v-card class="mt-4">
        <v-simple-table>
            <tbody>
                <tr>
                    <th class="text-center">옵션</th>
                    <th class="text-center">플랫폼</th>
                    <th class="text-center">액면가</th>
                    <th class="text-center">판매가</th>
                    <th class="text-center">수수료[모바일](원)</th>
                    <th class="text-center">actions</th>
                </tr>
                <tr>
                    <td class="text-center">
                        <v-layout align-center>
                            <v-img v-if="giftcard.thumb" :src="giftcard.thumb?.path || giftcard.thumb?.src" max-width="72" height="auto" contain class="v-icon--left"></v-img>
                            {{ option.name }}
                        </v-layout>
                    </td>
                    <td class="text-center">{{ option.platform }}</td>
                    <td class="text-center">
                        {{ option.faceamt.format() }}
                    </td>
                    <td class="text-center">
                        {{ option.salePrice.format() }}
                    </td>
                    <td class="text-center">
                        <span>
                            {{ (Math.floor(option.salePrice * giftcard.commission.mobile / (1000 * giftcard.commission.cutUnit)) * giftcard.commission.cutUnit * 10).format() }}원
                        </span>
                    </td>
                    <td class="text-center">
                        <v-btn :disabled="!scope.includes('root')" color="white" class="px-1 mr-1 primary--text" width="36" height="36" style="min-width: auto" @click="$emit('remove')"><v-icon>mdi-minus</v-icon></v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <v-divider />

        <v-card-title>
            <v-layout align-center>
                <span class="subtitle-2"><b>핀 재고 목록 (미발행, {{ summary.totalCount.format() }}건)</b></span>
                <v-spacer/>
                <v-btn color="green" outlined height="36" class="my-auto mr-2" @click="$refs.uploadDialog.open()">
                    <v-icon left>{{ mdiMicrosoftExcel }}</v-icon>
                    <span>엑셀 핀 업로드</span>
                </v-btn>
            </v-layout>
        </v-card-title>

        <v-divider/>

        <v-data-table v-model="selected" item-key="_id" show-select checkbox-color="red lighten-1" :items="option.pins || []" :headers="pinsHeaders">
            <template v-slot:item.faceamt="{ item }">
                {{ item.faceamt.format() }}
            </template>
            <template v-slot:item.createdAt="{ item }">
                {{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:footer.prepend>
                <v-btn color="red lighten-2" outlined @click="remove()"><v-icon left>mdi-delete-forever-outline</v-icon>미발행 선택삭제</v-btn>
            </template>
        </v-data-table>

        <upload-dialog ref="uploadDialog" :option="option" :platform="option?.platform" @input="upload" />
    </v-card>
</template>

<script>
import api from "@/api";
import UploadDialog from "@/components/console/gshop/giftcards/upload-dialog.vue";

import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        UploadDialog
    },
    props: {
        giftcard: { type: Object },
        option: { type: Object }
    },
    data(){
        return {
            mdiMicrosoftExcel,

            selected: [],

            summary: { totalCount: 0 },
            pinsHeaders: [
                { text: "상품권 이름", value: "name", align: "center", width: 200 },
                { text: "핀번호", value: "pno", align: "center", width: 200 },
                { text: "액면가", value: "faceamt", align: "center", width: 150 },
                { text: "상태", value: "status", align: "center", width: 150 },
                { text: "등록일자", value: "createdAt", align: "center", width: 200 },
            ],
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { summary, pins } = await api.console.gshop.giftcards.pins.gets(this.$route.params._giftcard, {
                params: {
                    _option: this.option._id,
                    status: "미발행"
                }
            });

            this.summary = summary;
            this.$set(this.option, "pins", pins);
        },
        async upload(pins){
            for(let pin of pins){
                if(+pin.faceamt == +this.option.faceamt) await api.console.gshop.pins.post({ ...pin, _giftcard: this.$route.params._giftcard, _option: this.option?._id });
            }

            if(pins?.length) alert("엑셀이 업로드 되었습니다");

            this.init();
            this.$emit("pins");
        },

        async remove(){
            if(confirm("삭제하시겠습니까?")){
                for(let pin of this.selected){
                    await api.console.gshop.pins.delete(pin);
                }
                
                this.selected = [];
                this.init();
                this.$emit("pins");
            }
        }
    },
    computed: {
        scope(){
            return this.$store.state.scope || [];
        },
    }
}
</script>