<template>
    <console-content max-width="1720">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline text-start">발송번호 수집동의 목록</v-col>
            <v-spacer/>
        </v-row>
        
        <v-row class="mt-4 px-1" align="end">
            <!-- <v-col cols="auto" class="subtitle-2">검색수 : {{ summary.totalCount.format() }}</v-col> -->
            <v-spacer />
            <v-col cols="auto">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table v-model="selected" :items="collection" item-key="_id" :headers="collectionHeaders" hide-default-footer disable-pagination disable-sort class="mt-2 mx-1 elevation-1">
            <!-- <span slot="item.quantity" slot-scope="{item}">{{ item.quantity.format() }}개</span> -->
            <!-- <v-layout slot="item.giftcard" slot-scope="{item}" align-center>
                <v-img :src="item.thumb?.path" max-width="96" max-height="96" contain/>
                <div class="pl-4">
                    <div><b>{{ item.name }}</b></div>
                    <div>{{ item.salePrice.format() }}원</div>
                </div>
            </v-layout>
            <div slot="item.order.buyer" slot-scope="{item}">
                <div>{{ item.order.buyer.name }} ({{ item.order.buyer.username || "비회원" }})</div>
                <div>{{ item.order.buyer.phone }}</div>
                <div>{{ item.order.buyer.email }}</div>
            </div>
            <div slot="item.order.receiver" slot-scope="{item}">
                <div>{{ item.order.receiver.name }}</div>
                <div>{{ item.order.receiver.phone }}</div>
                <div>{{ item.order.receiver.email }}</div>
            </div>
            <div slot="item.order.shippingAddress" slot-scope="{item}">
                <div>[{{ item.order.shippingAddress.postcode }}]</div>
                <div>{{ item.order.shippingAddress.address1 }}</div>
                <div>{{ item.order.shippingAddress.address2 }}</div>
            </div>
            <div slot="item.shippingInfo" slot-scope="{item}">
                <div>{{ item.shippingInfo.method }}</div>
                <div>{{ item.shippingInfo.invoiceNumber }}</div>
                <div>{{ couriers.find(courier => courier.value == item.shippingInfo.courier)?.text }}</div>
            </div> -->
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <template slot="item.pins" slot-scope="{item}">
                <v-icon small @click="modal(item)">{{ mdiTextBoxSearch }}</v-icon>
            </template>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import couriers from "@/assets/data/couriers.json"
import { mdiMicrosoftExcel, mdiTextBoxSearch } from "@mdi/js";
import { mapState } from "vuex";

import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import PinDialog from "@/components/console/gshop/purchase/pin-dialog.vue";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";

export default {
    components: {
        VDateField,
        PinDialog,
        ConsoleContent,
    },
    data(){
        return {
            mdiMicrosoftExcel,
            mdiTextBoxSearch,

            showSearch: true,

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            selected: [],
            summary: { totalCount: 0 },
            collection: [],
            collectionHeaders: [
                { text: "통신사", value: "telecom", align: "center", width: 160 },
                { text: "휴대폰번호", value: "phone", align: "center", width: 160 },
                { text: "주민번호 앞 6자리", value: "authNumber", align: "center", width: 160 },
                { text: "동의날짜", value: "createdAt", align: "center", width: 90 },
            ],
        }
    },
    mounted(){
        this.init();
    },
    computed: {
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, collection } = await api.console.gshop.collection.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.collection = collection;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        
        async excel(){
            let { collection } = await api.console.gshop.collection.gets({ headers: { limit: 0 }, params: this.filter });

            let rows = [];

                collection.forEach(collect => {
                    rows.push({
                        "통신사": collect?.telecom,
                        "휴대폰 번호": collect?.phone,
                        "주민번호 앞 6자리": collect?.authNumber,
                        "동의날짜": this.$dayjs(collect?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                    });
                });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "발송번호 수집동의 내역");
            XLSX.writeFile(workbook, "발송번호 수집동의 내역.xlsx");
        }
    }
}
</script>