<template>
    <v-btn-toggle v-model="date" dense color="primary" @change="emit">
        <v-btn :value="thisDay">오늘</v-btn>
        <v-btn :value="lastDay">어제</v-btn>
        <v-btn :value="thisWeek">이번주</v-btn>
        <v-btn :value="lastWeek">저번주</v-btn>
        <v-btn :value="thisMonth">이번달</v-btn>
        <v-btn :value="lastMonth">지난달</v-btn>
    </v-btn-toggle>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        value: { type: Array, default: () => [null, null] },
    },
    data: () => ({
        date: [null, null],
    }),
    computed: {
        thisDay() {
            let from = dayjs().format("YYYY-MM-DD");
            let to = from;
            return [from, to];
        },
        lastDay() {
            let from = dayjs().subtract(1, "day").format("YYYY-MM-DD");
            let to = from;
            return [from, to];
        },
        thisWeek() {
            let from = dayjs().startOf("week").format("YYYY-MM-DD");
            let to = dayjs(from).endOf("week").format("YYYY-MM-DD");
            return [from, to];
        },
        lastWeek() {
            let from = dayjs().subtract(1, "week").startOf("week").format("YYYY-MM-DD");
            let to = dayjs(from).endOf("week").format("YYYY-MM-DD");
            return [from, to];
        },
        thisMonth() {
            let from = dayjs().startOf("month").format("YYYY-MM-DD");
            let to = dayjs(from).endOf("month").format("YYYY-MM-DD");
            return [from, to];
        },
        lastMonth() {
            let from = dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
            let to = dayjs(from).endOf("month").format("YYYY-MM-DD");
            return [from, to];
        },
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.date = [...this.value];
        },
        emit() {
            this.$emit("input", [...(this.date || [])]);
        },
    },
};
</script>

<style>
</style>