<template>
    <v-card class="mt-4 mx-1 pb-2">
        <v-card-title class="subtitle-2 font-weight-bold">
            <span>주문내역</span>
            <v-spacer/>
            <span>총 주문금액 <b>{{ totalAmount?.format?.() }}</b>원</span>
        </v-card-title>
        <v-divider/>
        <v-data-table :items="purchases" item-key="_id" :headers="purchasesHeaders" :page.sync="page" @page-count="pageCount = $event" :items-per-page="limit" hide-default-footer class="mt-2">
            <span slot="item.quantity" slot-scope="{item}">{{ item.quantity.format() }}개</span>
            <v-layout slot="item.giftcard" slot-scope="{item}" align-center>
                <v-img :src="item.thumb?.path || item.thumb?.src || item.thumb" max-width="96" max-height="96" contain/>
                <div class="pl-4 text-start">
                    <div><b>{{ item.name }}</b></div>
                    <div>{{ item.salePrice.format() }}원</div>
                </div>
            </v-layout>
            <div slot="item.order.buyer" slot-scope="{item}">
                <div>{{ item.order.buyer.name }} ({{ item.order.buyer.username || "비회원" }})</div>
                <div>{{ item.order.buyer.phone }}</div>
                <div>{{ item.order.buyer.email }}</div>
            </div>
            <div slot="item.order.receiver" slot-scope="{item}">
                <div>{{ item.order.receiver.name }}</div>
                <div>{{ item.order.receiver.phone }}</div>
                <div>{{ item.order.receiver.email }}</div>
            </div>
            <div slot="item.order.shippingAddress" slot-scope="{item}">
                <div>[{{ item.order.shippingAddress.postcode }}]</div>
                <div>{{ item.order.shippingAddress.address1 }}</div>
                <div>{{ item.order.shippingAddress.address2 }}</div>
            </div>
            <div slot="item.shippingInfo" slot-scope="{item}">
                <div>{{ item.shippingInfo.method }}</div>
                <div>{{ item.shippingInfo.invoiceNumber }}</div>
                <div>{{ couriers.find(courier => courier.value == item.shippingInfo.courier)?.text }}</div>
            </div>
            <span slot="item.order.totalAmount" slot-scope="{item}">{{ item.order.totalAmount.format() }}원</span>
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <template slot="item.pins" slot-scope="{item}">
                <v-icon small @click="modal(item)">{{ mdiTextBoxSearch }}</v-icon>
            </template>
        </v-data-table>

        <v-divider/>
        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-4"/>

        <pin-dialog ref="pinDialog" />
    </v-card>
</template>

<script>
import api from "@/api";
import { mdiTextBoxSearch } from "@mdi/js";

import PinDialog from "@/components/console/gshop/purchase/pin-dialog.vue";

export default {
    components: {
        PinDialog
    },
    data(){
        return {
            mdiTextBoxSearch,

            filter: {
                searchKey: "order.buyer._id",
                searchValue: this.$route.params._member,
                paymentStatus: "결제완료"
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            selected: [],

            summary: { totalCount: 0 },
            purchases: [],
            purchasesHeaders: [
                { text: "주문일자", value: "createdAt", align: "center", width: 120 },
                { text: "주문번호", value: "order.orderNo", align: "center", width: 100 },
                { text: "결제방법", value: "order.paymentMethod", align: "center", width: 100 },
                { text: "결제금액", value: "order.totalAmount", align: "center", width: 100 },
                { text: "구매자", value: "order.buyer", align: "center", width: 160 },
                { text: "수취인", value: "order.receiver", align: "center", width: 100 },
                { text: "구매번호", value: "purchaseNo", align: "center", width: 100 },
                { text: "구매상품", value: "giftcard", align: "center", width: 300 },
                { text: "구매수량", value: "quantity", align: "center", width: 80 },
                { text: "발행상태", value: "status", align: "center", width: 90 },
                { text: "결제상태", value: "paymentStatus", align: "center", width: 90 },
                { text: "핀 발행 내역", value: "pins", align: "center", width: 120 },
            ],
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();

            this.ready = true;
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, purchases } = await api.console.gshop.purchases.gets({
                headers: {
                    skip: 0,
                    limit: 0
                },
                params: this.filter
            });

            this.purchases = purchases;
            this.summary = summary;
        },
        modal(purchase){
            console.log(this.$refs.pinDialog);
            this.$refs.pinDialog.pins = purchase.pins;
            this.$refs.pinDialog.open();
        },
    },
    computed: {
        totalAmount(){
            let orders = [];
            for(let purchase of this.purchases){
                if(!orders.find(order => order._id == purchase.order._id)) orders.push(purchase.order);
            }
            return orders.reduce((totalAmount, order) => +order.totalAmount + totalAmount, 0);
        }
    }
}
</script>