<template>
    <v-dialog v-model="show" max-width="800px">
        <v-card v-if="show">
            <v-card-title>
                <span v-if="popup?._id" class="subtitle-1 font-weight-bold">팝업수정</span>
                <span v-else class="subtitle-1 font-weight-bold">팝업추가</span>
            </v-card-title>
            <v-card-text>
                <v-layout class="mt-4">
                    <v-flex>
                        <v-text-field v-model="popup.name" label="명칭" persistent-placeholder dense hide-details></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout class="mt-8">
                    <v-flex>
                        <v-text-field v-model="popup.no" label="순서" persistent-placeholder dense hide-details></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout class="mt-8">
                    <v-flex>
                        <v-text-field v-model="popup.url" label="URL" persistent-placeholder dense hide-details></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout class="mt-8 mx-n2">
                    <v-flex xs6 class="px-2"><v-text-field v-model="popup.left" label="위치(x)" persistent-placeholder dense hide-details></v-text-field></v-flex>
                    <v-flex xs6 class="px-2"><v-text-field v-model="popup.top" label="위치(y)" persistent-placeholder dense hide-details></v-text-field></v-flex>
                </v-layout>
                <v-layout class="mt-8 mx-n2">
                    <v-flex xs6 class="px-2"><v-text-field v-model="popup.width" label="크기(가로)" persistent-placeholder dense hide-details></v-text-field></v-flex>
                    <v-flex xs6 class="px-2"><v-text-field v-model="popup.height" label="크기(세로)" persistent-placeholder dense hide-details></v-text-field></v-flex>
                </v-layout>
                <v-layout class="mt-8 mx-n2">
                    <v-flex class="px-2">전시여부 (숨김/보임)</v-flex>
                    <v-spacer></v-spacer>
                    <v-flex shrink class="px-2">
                        <v-switch v-model="popup.display.enabled" dense hide-details class="ma-0 px-2"></v-switch>
                    </v-flex>
                </v-layout>
                <v-layout class="mt-4 mx-n2">
                    <v-flex class="px-2">전시기간</v-flex>
                    <v-spacer></v-spacer>
                    <v-flex shrink class="px-2">
                        <v-switch v-model="popup.display.period" dense hide-details class="ma-0 px-2"></v-switch>
                    </v-flex>
                </v-layout>
                <v-layout v-if="popup.display.period" class="mt-8 mx-n2">
                    <v-flex xs6 class="px-2">
                        <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="popup.display.startDate" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="popup.display.startDate" label="전시일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="popup.display.startDate" no-title scrollable @input="$refs.startDateMenu.save(popup.display.startDate)"></v-date-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex xs6 class="px-2">
                        <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="popup.display.endDate" transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="popup.display.endDate" label="전시일자" persistent-placeholder dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="popup.display.endDate" no-title scrollable @input="$refs.endDateMenu.save(popup.display.endDate)"></v-date-picker>
                        </v-menu>
                    </v-flex>
                </v-layout>
                <v-layout class="mt-6">
                    <v-flex>
                        <naver-smarteditor v-model="popup.content"></naver-smarteditor>
                    </v-flex>
                </v-layout>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">취소</v-btn>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        NaverSmarteditor
    },
    data: () => {
        return {
            showSearch: true,

            page: 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            popups: [],
            popupsHeaders: [
                {
                    text: '순서',
                    width: 100,
                    align: 'center',
                    value: 'sequence'
                },
                {
                    text: '명칭',
                    width: 200,
                    align: 'center',
                    value: 'name'
                },
                {
                    text: 'URL',
                    align: 'center',
                    value: 'url'
                },
                {
                    text: '전시여부',
                    align: 'center',
                    width: 140,
                    value: 'display.enabled'
                },
                {
                    text: '등록일자',
                    align: 'center',
                    width: 140,
                    value: 'createdAt'
                },
                {
                    text: 'actions',
                    align: 'center',
                    width: 100,
                    value: 'actions'
                }
            ],

            show: false,
            popup: null,
            defaultItem: {
                _id: null,
                code: null,
                subject: null,
                content: null,
                url: null,

                left: 0,
                top: 0,
                width: '',
                height: '',

                display: {
                    enabled: false,
                    period: false,
                    startDate: null,
                    endDate: null
                }
            },

            image: null
        };
    },
    created(){
        this.popup = JSON.parse(JSON.stringify(this.defaultItem))
    },
    methods: {
        close() {
            this.show = false;
        },

        async open(){
            this.show = true;
        },

        async save() {
            this.popup._id ? await api.console.common.popups.put(this.popup) : await api.console.common.popups.post(this.popup);
            this.close();
        },
    },
    watch: {
        show(){
            if(!this.show){
                this.popup = JSON.parse(JSON.stringify(this.defaultItem))
                this.$emit("close");
            }
        }
    }
}
</script>