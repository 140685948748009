<template>
    <console-content max-width="800">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">블랙리스트</v-col>
            <v-spacer/>
            <v-col cols="auto"></v-col>
        </v-row>

        <v-divider class="my-2"/>

        <div>
            <div class="caption"><b>* 회원, 비회원 상관없이 주문을 차단 할 수 있습니다.</b></div>
            <div class="caption"><b>* 회원가입을 운영하는 경우 회원가입을 차단합니다.</b></div>
        </div>

        <v-divider class="my-2"/>
        
        <v-row class="mt-2 px-1" align="end">
            <v-col cols="auto" class="subtitle-2">검색수 : {{ summary.totalCount.format() }}</v-col>
            <v-spacer />
            <v-col cols="auto"></v-col>
        </v-row>

        <v-data-table :items="blacklists" :headers="blacklistsHeaders" hide-default-footer disable-sort disable-pagination class="mt-3 mx-1 elevation-1">
            <template #[`item.content`]="{item}">
                <template v-if="item.type == `phone`">
                    {{ item.phone.phoneNumberFormat() }}
                </template>
            </template>
            <template #[`item.actions`]="{item}">
                <v-icon small class="mx-1" @click="remove(item)">mdi-delete</v-icon>
            </template>
            <template #[`body.append`]>
                <tr>
                    <td><v-select v-model="editItem.type" :items="types" item-text="text" item-value="value" outlined dense hide-details /></td>
                    <td>
                        <template v-if="editItem.type == `phone`">
                            <v-text-field v-model="editItem.phone" outlined dense hide-details />
                        </template>
                    </td>
                    <td>    
                        <v-btn color="primary" @click="save()"><v-icon left>mdi-plus</v-icon>등록</v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent,
    },
    data(){
        return {
            mdiMicrosoftExcel,

            summary: { totalCount: 0 },
            blacklists: [],
            blacklistsHeaders: [
                { text: "type", value: "type", align: "center", width: 150 },
                { text: "content", value: "content", align: "center", /* width: 10 */ },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],

            editItem: {},

            types: [
                { text: "phone", value: "phone" },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(){

            let { summary, blacklists } = await api.console.common.blacklists.gets({
                headers: {
                    skip: 0,
                    limit: 0
                },
            });

            this.blacklists = blacklists;
            this.summary = summary;
            this.clear();
        },
        clear(){
            this.editItem = {
                type: "phone",
                phone: null,
            };
        },

        async save(){
            await api.console.common.blacklists.post(this.editItem);

            alert("등록되었습니다");
            await this.search();
        },
        
        async remove(blacklist){
            if(confirm("삭제하시겠습니까?")) {
                await api.console.common.blacklists.delete(blacklist);
                await this.search();
            }
        },

        async excel(){
            let { blacklists } = await api.console.common.blacklists.gets({ headers: { limit: 0 } });

            let rows = [];
            blacklists.forEach(blacklists => {
                rows.push({
                    "no": blacklists.no,
                    "이름": blacklists.name,
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "엑셀시트이름");
            XLSX.writeFile(workbook, "엑셀파일이름.xlsx");
        }
    }
}
</script>