<template>
    <console-content max-width="1280">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="giftcard._id">상품상세</span>
                <span v-else>상품등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mx-1 mt-4">
            <v-card-title class="pb-2">
                <span class="subtitle-2"><b>카테고리</b></span>
                <v-spacer/>
                <v-btn color="primary" small @click="giftcard._categories.push(null)"><v-icon small class="mr-2">mdi-plus</v-icon> 카테고리 추가</v-btn>
            </v-card-title>
            <v-card-text>
                <v-layout v-for="_category, index in giftcard._categories" :key="index" align-end :class="{ 'mt-4': index ? true : false }">
                    <category-select v-model="giftcard._categories[index]" :categories="categories" hide-details :allowDepth="[1, 2]"/>
                    <v-btn v-if="1 < giftcard._categories.length" small text icon class="ml-2" @click="giftcard._categories.splice(index, 1)"><v-icon size="20">mdi-delete</v-icon></v-btn>
                </v-layout>
            </v-card-text>
        </v-card>

        <v-row class="mt-4">
            <v-col cols="12" md="7" class="pr-md-1">
                <v-card class="mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">썸네일</v-card-title>
                    <v-card-text>
                        <v-layout no-gutters align-center>
                            <v-img v-if="thumb || giftcard.thumb" :src="createObjectURL(thumb) || giftcard.thumb?.path || giftcard.thumb?.src" max-width="96" height="auto" contain></v-img>
                            <v-sheet v-else width="96" height="96" color="d-flex justify-center align-center grey lighten-2"><v-icon>mdi-image</v-icon></v-sheet>
                            <v-file-input v-model="thumb" :placeholder="giftcard.thumb?.name" @change="file => uploadThumb(file)" />
                        </v-layout>
                    </v-card-text>
                </v-card>

                <v-card class="mx-1 mt-3">
                    <v-card-title>
                        <span class="subtitle-2"><b>이미지</b></span>
                        <v-spacer/>
                        <v-btn color="primary" small @click="giftcard.images.push(null)"><v-icon small class="mr-2">mdi-plus</v-icon>이미지추가</v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-draggable v-model="giftcard.images">
                            <v-layout v-for="(image, index) in giftcard.images" :key="index" no-gutters align-center class="mb-4">
                                <v-img v-if="images[index] || giftcard.images[index]" :src="createObjectURL(images[index]) || giftcard.images[index].path || giftcard.images[index].src" max-width="96" height="auto" contain></v-img>
                                <v-sheet v-else width="96" height="96" color="d-flex justify-center align-center grey lighten-2"><v-icon>mdi-image</v-icon></v-sheet>
                                <v-file-input v-model="images[index]" :placeholder="giftcard.images[index]?.name" @change="file => uploadImage(file, index)" />
                            </v-layout>
                        </v-draggable>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="5" class="pl-md-1">
                <v-card class="mx-1 mt-3 mt-md-0">
                    <v-card-title>
                        <span class="subtitle-2"><b>기본정보</b></span>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="giftcard.name" label="상품권이름" persistent-placeholder hide-details="" />
                        <v-text-field v-if="siteInfo.giftcardAliasEnabled" v-model="giftcard.alias" label="상품권별칭" persistent-placeholder hide-details="" />
                        <v-text-field v-model="giftcard.code" label="상품권코드" persistent-placeholder hide-details="" class="mt-4" />
                        <v-text-field v-model="giftcard.no" label="진열순서" persistent-placeholder hide-details="" class="mt-4" />
                        <v-text-field v-show="false" v-model="giftcard.faceamt" label="액면가" persistent-placeholder hide-details="" class="mt-4" />
                        <v-text-field v-show="false" v-model="giftcard.salePrice" label="판매가" persistent-placeholder hide-details="" class="mt-4" />
                        <v-text-field v-show="false" v-model="giftcard.stock" label="재고" persistent-placeholder hide-details="" class="mt-4" />
                    </v-card-text>
                </v-card>
                
                <!-- <v-card class="mx-1 mt-4">
                    <v-card-title class="align-center">
                        <span class="subtitle-2"><b>베스트</b></span>
                        <v-spacer/>
                        <v-switch v-model="giftcard.isBest" hide-details class="mt-0"/>
                    </v-card-title>
                </v-card> -->

                <v-card class="mx-1 mt-4">
                    <v-card-title class="align-center">
                        <span class="subtitle-2"><b>신용카드 결제 수수료</b></span>
                        <v-spacer/>
                        <v-text-field v-model="giftcard.commission.creditcard" dense hide-details outlined suffix="%" class="shrink" style="width: 120px" />
                    </v-card-title>
                    <v-divider/>
                    <v-card-title class="align-center">
                        <span class="subtitle-2"><b>모바일 결제 수수료</b></span>
                        <v-spacer/>
                        <v-text-field v-model="giftcard.commission.mobile" dense hide-details outlined suffix="%" class="shrink" style="width: 120px" />
                    </v-card-title>
                    <v-divider/>
                    <v-card-title class="align-center">
                        <span class="subtitle-2"><b>절사(절삭) 단위</b></span>
                        <v-spacer/>
                        <v-select v-model="giftcard.commission.cutUnit" :items="[1, 10, 100, 1000]" dense hide-details outlined suffix="원" class="shrink" style="width: 120px" />
                    </v-card-title>
                </v-card>

                <v-card class="mx-1 mt-4">
                    <v-card-title class="align-center">
                        <span class="subtitle-2"><b>신용카드결제</b></span>
                        <v-spacer/>
                        <v-checkbox v-model="giftcard.paymentMethods" value="creditcard" hide-details class="mt-0"/>
                    </v-card-title>
                    <v-divider/>
                    <v-card-title class="align-center">
                        <span class="subtitle-2"><b>모바일결제</b></span>
                        <v-spacer/>
                        <v-checkbox v-model="giftcard.paymentMethods" value="mobile" hide-details class="mt-0"/>
                    </v-card-title>
                    <v-divider/>
                    <v-card-title v-if="siteInfo.pointEnabled" class="align-center">
                        <span class="subtitle-2"><b>포인트결제</b></span>
                        <v-spacer/>
                        <v-checkbox v-model="giftcard.paymentMethods" value="point" hide-details class="mt-0"/>
                    </v-card-title>
                </v-card>
                
                <v-card v-if="siteInfo.pointEnabled" class="mx-1 mt-4">
                    <v-card-title class="d-flex align-center">
                        <span class="subtitle-2"><b>구매가능 등급</b></span>
                        <v-spacer/>
                        <span><v-select v-model="giftcard.levelCode" :items="levels" item-text="name" item-value="value" outlined dense hide-details class="mt-0" style="width: 200px;"/></span>
                    </v-card-title>
                </v-card>

                <v-card class="mx-1 mt-4">
                    <v-card-title class="align-center">
                        <span class="subtitle-2"><b>품절여부 (판매 / 품절)</b></span>
                        <v-spacer/>
                        <v-switch v-model="giftcard.soldout" hide-details class="mt-0"/>
                    </v-card-title>
                    <v-divider/>
                    <v-card-title class="align-center">
                        <span class="subtitle-2"><b>진열여부 (진열안함 / 진열)</b></span>
                        <v-spacer/>
                        <v-switch v-model="giftcard.onDisplay" hide-details class="mt-0"/>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <giftcard-options v-if="ready" v-model="giftcard.options" :giftcard="giftcard" :thumb.sync="thumb" :images.sync="images" class="mt-4" @update:thumb="file => uploadThumb(file)" @update:images="file => uploadImage(file, 0)" />

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">상세정보</v-card-title>
            <v-card-text>
                <naver-smarteditor v-model="giftcard.content" />
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" :disabled="!ready" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";

import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import CategorySelect from "@/components/console/gshop/categories/category-select.vue";
import NanuriGiftcards from "@/components/console/gshop/giftcards/nanuri-giftcards.vue";
import GiftcardOptions from "@/components/console/gshop/giftcards/giftcard-options.vue";
import ExcelGiftcards from "@/components/console/gshop/giftcards/excel-giftcards.vue";
import VDraggable from "vuedraggable";

export default {
    components: {
        CategorySelect,
        ConsoleContent,
        NaverSmarteditor,
        NanuriGiftcards,
        ExcelGiftcards,
        GiftcardOptions,
        VDraggable
    },
    data(){
        return {
            ready: false,

            giftcard: {
                _id: this.$route.params._giftcard,
                _categories: [null],

                no: 0,
                name: null,
                code: null,
                type: "giftcard",
                level: null,

                costPrice: 0,
                salePrice: 0,
                stock: 0,
                soldout: false,

                commission: {
                    creditcard: 0,
                    mobile: 0,
                },

                paymentMethods: ["creditcard", "mobile"],

                thumb: null,
                images: new Array(1),
                
                onSale: true,
                onDisplay: true,

                options: [],
                pins: [],
            },

            thumb: null,
            images: new Array(1).fill(null),

            optionalEnabled: false,
            additionalEnabled: false,

            categories: [],
            levels: [
                { name: ":: 전체 ::", value: "" },
                { name: "기업회원(vip)", value: "vip" },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            // 카테고리 조회
            let { categories } = await api.console.gshop.categories.gets({ params: { depth: 1 }});
            this.categories = categories;

            let { summary } = await api.console.gshop.giftcards.gets({ params: { type: "giftcard" } });
            this.giftcard.no = summary.totalCount + 1;

            if(this.$route.params._giftcard){
                let { giftcard } = await api.console.gshop.giftcards.get({ _id: this.$route.params._giftcard });
                
                this.giftcard = giftcard;
                this.giftcard.pins = [];
                this.images = new Array(giftcard.images.length);
            }

            this.ready = true;
        },

        async uploadThumb(file){
            let { src } = await api.resource.post(file, `/res/giftcards/${this.giftcard?._id || 'temp'}`);
            this.$set(this.giftcard, "thumb", { src });
        },
        
        async uploadImage(file, index){
            let { src } = await api.resource.post(file, `/res/giftcards/${this.giftcard?._id || 'temp'}`);
            this.$set(this.giftcard.images, index, { src });
        },

        validate(){
            let giftcard = this.giftcard;
            try{
                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },

        async save(){
            let { giftcard } = !this.giftcard._id
            ? await api.console.gshop.giftcards.post(this.giftcard)
            : await api.console.gshop.giftcards.put(this.giftcard);
            
            // 상품권 옵션 등록
            for(let option of this.giftcard.options?.filter?.(({ name, isDeleted }) => !!name && !isDeleted) || []){
                option._parent = giftcard._id;
                option.soldout = giftcard.soldout;

                let response = option._id
                ? await api.console.gshop.giftcards.put({ ...option, thumb: giftcard.thumb }) 
                : await api.console.gshop.giftcards.post({ ...option, thumb: giftcard.thumb });

                option._id = response.giftcard._id;
                option.faceamt = response.giftcard.faceamt;
            }

            // // 엑셀 핀 업로드
            // for(let pin of this.giftcard.pins.filter(({ status, isDeleted }) => status == "미발행" && !isDeleted)){
            //     let option = this.giftcard.options.find(({ name, faceamt, isDeleted }) => (name == pin.name && faceamt == pin.faceamt) && !isDeleted);

            //     pin._id
            //     ? await api.console.gshop.pins.put({ ...pin, _giftcard: giftcard?._id, _option: option?._id })
            //     : await api.console.gshop.pins.post({ ...pin, _giftcard: giftcard?._id, _option: option?._id });
            // }

            // // 엑셀 핀 삭제
            // for(let pin of this.giftcard.pins.filter(({ isDeleted }) => !!isDeleted)){
            //     await api.console.gshop.pins.delete(pin);
            // }

            // 상품권 옵션 삭제
            for(let option of this.giftcard.options?.filter?.(({ isDeleted }) => !!isDeleted) || []){
                await api.console.gshop.giftcards.delete({ _id: option._id });
            }

            alert("저장되었습니다");
            this.$router.go(-1);
        },

        createObjectURL(url){
            try{
                return URL.createObjectURL(url)
            }
            catch(error){}
            return null;
        }
    },
    computed: {
        siteInfo() {
            return this.$store?.state?.siteInfo;
        },
    },
    watch: {
        "giftcard.paymentMethods"(){
            if(this.giftcard.paymentMethods.includes("point")){
                this.giftcard.level = "vip";
            }
        }
    }
}
</script>