<template>
    <console-content max-width="1280">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">이용약관</v-col>
            <v-spacer/>
        </v-row>

        <v-data-table :items="termsList" :headers="termsHeaders" hide-default-footer disable-pagination disable-sort class="mt-3 mx-1 elevation-1">
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <v-layout slot="item.actions" slot-scope="{item}" justify-center>
                <v-icon small class="mx-1" @click="edit(item)">mdi-pencil</v-icon>
                <v-icon small class="mx-1" @click="remove(item)">mdi-delete</v-icon>
            </v-layout>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

        <v-btn fab fixed bottom right color="accent" @click="create"><v-icon>mdi-pencil</v-icon></v-btn>

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            termsList: [],
            termsHeaders: [
                { text: "약관코드", align: "center", width: 150, value: "code", },
                { text: "이용약관", align: "center", value: "subject" },
                { text: "필수여부", align: "center", width: 100, value: "required" },
                { text: "작성일자", align: "center", width: 180, value: "createdAt" },
                { text: "Actions", align: "center", width: 100, value: "actions" }
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, termsList } = await api.console.terms.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.termsList = termsList;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        create(){
            this.$router.push(`/console/terms/create`);
        },
        edit(terms){
            this.$router.push(`/console/terms/${terms._id}`);
        },
        async remove(terms){
            if(confirm("삭제하시겠습니까?")){
                await api.console.terms.delete(terms);
                alert("삭제되었습니다");
                this.search(false);
            }
        }
    }
}
</script>