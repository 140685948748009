var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 mt-md-8 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.boilerplate._id ? _c('span', [_vm._v("상세")]) : _c('span', [_vm._v("등록")])]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0 pr-md-1",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": " ",
      "placeholder": " ",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": " ",
      "placeholder": " ",
      "hide-details": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "label": " ",
      "placeholder": " ",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": " ",
      "placeholder": " ",
      "hide-details": ""
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0 pl-md-1",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "mx-1 mt-3 mt-md-0"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("속성")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("상세정보")]), _c('v-card-text', [_c('naver-smarteditor')], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }