<template>
<v-card tile elevation="0">

    <v-card-text v-show="false" class="pb-0">
        <v-layout>
            <v-flex xs12 sm2 class="pt-2">옵션입력</v-flex>
            <v-flex xs12 sm10>
                <v-layout align-center class="mx-n1">
                    <v-flex grow class="px-1 text-center">권종이름</v-flex>
                    <v-flex grow class="px-1 text-center">액면가</v-flex>
                    <v-flex grow class="px-1 text-center">판매가</v-flex>
                    <v-flex shrink class="px-1">
                        <v-sheet width="36" height="36">
                            <v-btn width="36" color="primary" class="px-1" style="min-width: auto" @click="insert()"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-sheet>
                    </v-flex>
                </v-layout>
                <v-divider class="mt-2"/>
                <v-layout v-for="(excelGiftcard, index) in excelGiftcards" :key="index" class="mt-2 mx-n1">
                    <v-flex grow class="px-1">
                        <v-text-field v-model="excelGiftcard.name" dense hide-details outlined :placeholder="placeholders[index]?.name || ''"></v-text-field>
                    </v-flex>
                    <v-flex grow class="px-1">
                        <v-text-field v-model="excelGiftcard.faceamt" dense hide-details outlined :placeholder="placeholders[index]?.faceamt || ''"></v-text-field>
                    </v-flex>
                    <v-flex grow class="px-1">
                        <v-text-field v-model="excelGiftcard.salePrice" dense hide-details outlined :placeholder="placeholders[index]?.salePrice || ''"></v-text-field>
                    </v-flex>
                    <v-flex shrink class="px-1">
                        <v-sheet width="36" height="36">
                            <v-btn color="white" class="px-1 mr-1 primary--text" width="36" height="36" style="min-width: auto" :disabled="excelGiftcards.length <= 1" @click="remove(excelGiftcard, index)"><v-icon>mdi-minus</v-icon></v-btn>
                        </v-sheet>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-card-text>

    <v-divider v-show="false" class="mt-4"></v-divider>

    <template>
        <v-tabs v-model="tabIndex">
            <v-tab>전체</v-tab>
            <v-tab>미발행</v-tab>
            <v-tab>발행</v-tab>
            <v-tab>취소</v-tab>
            <v-spacer/>
            <v-btn color="green" outlined height="36" class="my-auto mr-2" @click="$refs.uploadDialog.open()">
                <v-icon class="mr-3">{{ mdiMicrosoftExcel }}</v-icon>
                <span>엑셀 업로드</span>
            </v-btn>
        </v-tabs>

        <v-divider/>

        <v-tabs-items v-model="tabIndex" touchless>
            <v-tab-item>
                <v-data-table :items="pins.filter(pin => !pin.isDeleted)" :headers="pinsHeaders">
                    <template slot="item.faceamt" slot-scope="{item}">{{ item.faceamt?.format?.() }}</template>
                    <template slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                    <template v-if="item.issuedAt" slot="item.issuedAt" slot-scope="{item}">{{ $dayjs(item.issuedAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item>
                <v-data-table :items="pins.filter(pin => !pin.isDeleted && pin.status == '미발행')" :headers="pinsHeaders">
                    <template slot="item.faceamt" slot-scope="{item}">{{ item.faceamt?.format?.() }}</template>
                    <template slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                    <template v-if="item.issuedAt" slot="item.issuedAt" slot-scope="{item}">{{ $dayjs(item.issuedAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item>
                <v-data-table :items="pins.filter(pin => !pin.isDeleted && pin.status == '발행완료')" :headers="pinsHeaders">
                    <template slot="item.faceamt" slot-scope="{item}">{{ item.faceamt?.format?.() }}</template>
                    <template slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                    <template v-if="item.issuedAt" slot="item.issuedAt" slot-scope="{item}">{{ $dayjs(item.issuedAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item>
                <v-data-table :items="pins.filter(pin => !pin.isDeleted && pin.status == '취소완료')" :headers="pinsHeaders">
                    <template slot="item.faceamt" slot-scope="{item}">{{ item.faceamt?.format?.() }}</template>
                    <template slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                    <template v-if="item.issuedAt" slot="item.issuedAt" slot-scope="{item}">{{ $dayjs(item.issuedAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>
    </template>

    <upload-dialog ref="uploadDialog" @input="upload" />
</v-card>
</template>

<script>
import { mdiMicrosoftExcel } from "@mdi/js";
import UploadDialog from "@/components/console/gshop/giftcards/upload-dialog.vue";
export default {
    components: {
        UploadDialog
    },
    props: {
        value: { type: [Object, Array], default(){ return [] } },
        giftcard: { type: Object }
    },
    data() {
        return {
            mdiMicrosoftExcel,

            selected: [],
            excelGiftcards: this.$props.value || [],
            excelGiftcardsHeaders: [
                { text: "옵션값", align: "center", sortable: false, value: "optionalValues" },
                { text: "판매가", align: "center", width: 140, value: "salePrice" },
                { text: "재고수량", align: "center", width: 140, value: "stock" },
                { text: "actions", align: "center", width: 100, sortable: false, value: "actions" }
            ],

            placeholders: [
                { name: "ex)○○○ 상품권 1만원권", faceamt: "ex) 10000" },
                { name: "ex)○○○ 상품권 5만원권", faceamt: "ex) 50000" },
                { name: "ex)○○○ 상품권 10만원권", faceamt: "ex) 100000" }
            ],

            pins: this.$props.giftcard.pins,
            pinsHeaders: [
                { text: "상품권 이름", value: "name", align: "center", width: 200 },
                { text: "핀번호", value: "pno", align: "center", width: 200 },
                { text: "액면가", value: "faceamt", align: "center", width: 150 },
                { text: "상태", value: "status", align: "center", width: 150 },
                { text: "등록일자", value: "createdAt", align: "center", width: 200 },
                { text: "발행일자", value: "issuedAt", align: "center", width: 200 },
            ],

            tabIndex: 0,
        };
    },
    methods: {
        remove(excelGiftcard, index){
            excelGiftcard._id ? this.$set(excelGiftcard, "isDeleted", true) : this.excelGiftcards.splice(index, 1);
        },
        insert({ name = "", salePrice = 0, faceamt = 0}){
            this.excelGiftcards.push({
                name,
                type: "excel",
                platform: "excel",
                salePrice,
                faceamt,
            });
        },
        upload(pins = []){
            for(let { name, pno, faceamt, status = "미발행" } of pins){
                if(!this.excelGiftcards.find(excelGiftcard => excelGiftcard.name == name)){
                    this.insert({ name, faceamt, salePrice: faceamt });
                }
                
                this.pins.unshift({
                    name,
                    pno,
                    faceamt,
                    status,
                    createdAt: Date.now()
                });
            }
        }
    },
    watch: {
        value(){
            this.excelGiftcards = this.value || [];
        },
        excelGiftcards: {
            deep: true,
            handler(){ this.$emit("input", this.excelGiftcards) }
        },
        pins: {
            deep: true,
            handler(){ this.giftcard.pins = this.pins; this.$forceUpdate(); }
        },
        giftcard(){
            this.pins = this.giftcard.pins;
        }
    }
}
</script>

<style>
.shop-excelGiftcards .v-input.text-center input { text-align: center; }
</style>
