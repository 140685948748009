var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "300"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("포인트 수동")])]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "아이디 *",
      "persistent-placeholder": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.point.username,
      callback: function ($$v) {
        _vm.$set(_vm.point, "username", $$v);
      },
      expression: "point.username"
    }
  }), _c('v-select', {
    staticClass: "mt-4",
    attrs: {
      "items": _vm.types,
      "item-text": "name",
      "item-value": "value",
      "label": "유형 *",
      "persistent-placeholder": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.point.type,
      callback: function ($$v) {
        _vm.$set(_vm.point, "type", $$v);
      },
      expression: "point.type"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "포인트 *",
      "persistent-placeholder": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.point.amount,
      callback: function ($$v) {
        _vm.$set(_vm.point, "amount", $$v);
      },
      expression: "point.amount"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "비고",
      "persistent-placeholder": "",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.point.remark,
      callback: function ($$v) {
        _vm.$set(_vm.point, "remark", $$v);
      },
      expression: "point.remark"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v("승인")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }