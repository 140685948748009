<template>
    <v-sheet v-bind="$attrs" @click="$refs.input.$refs.input.click()" color="transparent" class="v-image-field">
        <v-img v-if="image" :src="createObjectURL(image)" v-bind="$attrs" contain></v-img>
        <v-file-input v-model="file" ref="input" accept="image/*" class="d-none" @change="input"/>
    </v-sheet>
</template>

<script>
export default {
    props: ["value"],
    data(){
        return {
            image: this.$props.value,
            file: null
        }
    },
    methods: {
        input(file){
            this.$emit("input", file);
            this.image = file;
            this.file = null;
        },
        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){}
        }
    },
    watch: {
        value(){ this.image = this.value; }
    }
}
</script>
