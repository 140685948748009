var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 mt-md-8 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.terms._id ? _c('span', [_vm._v("약관 상세")]) : _c('span', [_vm._v("약관 등록")])]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "pr-md-1",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.terms.subject,
      callback: function ($$v) {
        _vm.$set(_vm.terms, "subject", $$v);
      },
      expression: "terms.subject"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "코드",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.terms.code,
      callback: function ($$v) {
        _vm.$set(_vm.terms, "code", $$v);
      },
      expression: "terms.code"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pl-md-1",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "mx-1 mt-3 mt-md-0"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("필수여부 (미필수/필수)")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.terms.required,
      callback: function ($$v) {
        _vm.$set(_vm.terms, "required", $$v);
      },
      expression: "terms.required"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("상세정보")]), _c('v-card-text', [_c('naver-smarteditor', {
    model: {
      value: _vm.terms.content,
      callback: function ($$v) {
        _vm.$set(_vm.terms, "content", $$v);
      },
      expression: "terms.content"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }