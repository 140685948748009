var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.board._id ? _c('span', [_vm._v("게시글 상세")]) : _c('span', [_vm._v("게시글 등록")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mt-2 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-layout', {
    staticClass: "mx-n2"
  }, [_c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": _vm.$dayjs(_vm.board.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      "label": "작성일자",
      "persistent-placeholder": "",
      "hide-details": "",
      "disabled": ""
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "mt-4 mx-n2"
  }, [_c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "작성자 이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.writer.name,
      callback: function ($$v) {
        _vm.$set(_vm.board.writer, "name", $$v);
      },
      expression: "board.writer.name"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "작성자 이메일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.writer.email,
      callback: function ($$v) {
        _vm.$set(_vm.board.writer, "email", $$v);
      },
      expression: "board.writer.email"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("문의내용")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1), _c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("첨부파일")]), _c('v-card-text', [!_vm.board.images.some(function (image) {
    return !!image;
  }) ? _c('span', [_vm._v("없음")]) : _vm._l(_vm.board.images, function (image, index) {
    return [_c('v-btn', {
      key: index,
      staticClass: "mr-2",
      attrs: {
        "color": "primary",
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.download(image, `첨부이미지_${index + 1}`);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "size": "20"
      }
    }, [_vm._v(_vm._s(_vm.mdiDownload))]), _vm._v("이미지 다운로드 " + _vm._s(index + 1))], 1)];
  })], 2)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("답변내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "outlined": ""
    },
    model: {
      value: _vm.board.reply,
      callback: function ($$v) {
        _vm.$set(_vm.board, "reply", $$v);
      },
      expression: "board.reply"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-4"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }