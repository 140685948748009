var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "800"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("메타 정보")]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mx-n2 my-2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', [_c('div', {
    staticClass: "pa-3 subtitle-2 font-weight-bold grey lighten-5"
  }, [_vm._v("메타 이미지")]), _c('v-divider'), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-image-field', {
    staticClass: "fill-height grey lighten-2",
    attrs: {
      "aspect-ratio": 1 / 1
    },
    model: {
      value: _vm.setting.metaImage,
      callback: function ($$v) {
        _vm.$set(_vm.setting, "metaImage", $$v);
      },
      expression: "setting.metaImage"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', {
    staticClass: "fill-height"
  }, [_c('div', {
    staticClass: "pa-3 subtitle-2 font-weight-bold grey lighten-5"
  }, [_vm._v("메타 데이터")]), _c('v-divider'), _c('term-desc-table', _vm._b({
    model: {
      value: _vm.setting.metaInfo,
      callback: function ($$v) {
        _vm.$set(_vm.setting, "metaInfo", $$v);
      },
      expression: "setting.metaInfo"
    }
  }, 'term-desc-table', {
    initItems: _vm.initItems,
    terms: _vm.terms
  }, false))], 1)], 1)], 1), _c('v-layout', {
    staticClass: "mt-4",
    attrs: {
      "justify-end": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장하기")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }