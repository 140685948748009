<template>
    <console-content max-width="1600">

        <v-row class="mt-0 mt-md-4 px-1" align="center">
            <v-col cols="auto" class="headline">회원목록</v-col>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>
        
        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mt-3 mx-1">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text class="pb-0">
                    <v-row align="center">
                        <v-col cols="12" sm="6">
                            <v-date-field v-model="filter.createdAt[0]" label="가입일자" persistent-placeholder hide-details />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-date-field v-model="filter.createdAt[1]" label="가입일자" persistent-placeholder hide-details />
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter['riskManagement.giftcard.level']" label="과금등급" persistent-placeholder hide-details :items="giftcardLevels" item-text="text" item-value="value"/>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.isBlock" label="차단여부" persistent-placeholder hide-details :items="isBlocks" item-text="text" item-value="value"/>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.isDormancy" label="휴면여부" persistent-placeholder hide-details :items="isDormancies" item-text="text" item-value="value"/>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.searchKey" label="검색조건" persistent-placeholder hide-details :items="searchKeys" item-text="text" item-value="value" @input="filter.searchValue = null;"/>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details clearable :disabled="!filter.searchKey" @keydown.enter="page=1; search(true)" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text @click="page = 1; search(true)">
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>
        
        <v-row class="mt-6 px-1" align="end">
            <v-col cols="auto">
                <span class="ception">검색된 회원수: {{ summary.totalCount.format() }}</span>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table :loading="loading" :items="members" :headers="membersHeaders" hide-default-footer disable-sort class="mt-2 mx-1 elevation-1">
            <template slot="header.loginFailedCount">
                <v-layout align-center>
                    <div>로그인<br/>실패횟수</div>
                    <v-tooltip bottom>
                        <v-icon slot="activator" slot-scope="{ on, attrs }" small class="d-inline-block" v-on="on" v-bind="attrs">{{ mdiAlertCircleOutline }}</v-icon>
                        <div>
                            <div class="caption">* 로그인 실패 횟수는 다음날 자동으로 초기화 됩니다.</div>
                            <div class="caption">* 초기화 버튼을 누르면, 오늘 로그인 실패횟수가 리셋됩니다.</div>
                        </div>
                    </v-tooltip>
                </v-layout>
            </template>

            <template v-slot:[`item.phone`]="{ item }">
                <span v-if="item.phone">{{ item.phone.phoneNumberFormat() }}</span>
                <span v-else>-</span>
            </template>
            <template v-slot:[`item.riskManagement.disabled`]="{ item }">
                <v-switch v-model="item.riskManagement.disabled" hide-details class="pa-0 ma-0 d-inline-block" color="red lighten-2" @change="update({ _id: item._id, riskManagement: { ...(item.riskManagement || {}), disabled: item.riskManagement.disabled } })" />
            </template>
            <template v-slot:[`item.isBlock`]="{ item }">
                <v-switch v-model="item.isBlock" hide-details class="pa-0 ma-0 d-inline-block" color="red lighten-2" @change="update({ _id: item._id, isBlock: item.isBlock })"/>
            </template>
            <template v-slot:[`item.isDormancy`]="{ item }">
                <v-switch v-model="item.isDormancy" hide-details class="pa-0 ma-0 d-inline-block" color="red lighten-2" @change="update({ _id: item._id, isDormancy: item.isDormancy })"/>
            </template>
            <template v-slot:[`item.isDisapproval`]="{ item }">
                <v-switch v-model="item.isDisapproval" hide-details class="pa-0 ma-0 d-inline-block" color="red lighten-2" @change="update({ _id: item._id, isDisapproval: item.isDisapproval })"/>
            </template>
            <template v-slot:[`item.recertEnabled`]="{ item }">
                <v-switch v-model="item.recertEnabled" hide-details class="pa-0 ma-0 d-inline-block" color="blue lighten-2" @change="update({ _id: item._id, recertEnabled: item.recertEnabled })"/>
            </template>
            <template v-slot:[`item.orderCertType`]="{ item }">
                <span>
                    <v-select v-model="item.orderCertType" :items="orderCertTypes" outlined dense hide-details class="d-inline-block" color="blue lighten-2" @change="update({ _id: item._id, orderCertType: item.orderCertType })"/>
                </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                <v-icon small class="ml-3" @click="withdrawal(item)">mdi-delete</v-icon>
            </template>
            <template slot="item.cash" slot-scope="{ item }">{{ item.cash.format() }}</template>
            <template slot="item.point" slot-scope="{ item }">{{ item.point.format() }}</template>
            <template slot="item.loginFailedCount" slot-scope="{ item }">
                {{ item.loginFailedCount[$dayjs().format("YYYY-MM-DD")] || 0 }} / 5
                <v-icon small @click="update({ _id: item._id, loginFailedCount: new Map() }); item.loginFailedCount = {};">{{ mdiRefresh }}</v-icon>
            </template>
            <template slot="item.createdAt" slot-scope="{ item }">{{ $dayjs(item.createdAt).format("YYYY-MM-DD") }}</template>
            <template slot="item.visitedAt" slot-scope="{ item }">{{ $dayjs(item.visitedAt).format("YYYY-MM-DD") }}</template>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-4" @input="search(true)" />

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import { mdiMicrosoftExcel, mdiPlusCircleMultipleOutline, mdiRefresh, mdiAlertCircleOutline } from "@mdi/js";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import { mapActions, mapState } from "vuex";


export default {
    components: {
        ConsoleContent,
        VDateField
    },
    data(){
        return {
            mdiRefresh,
            mdiMicrosoftExcel,
            mdiPlusCircleMultipleOutline,
            mdiAlertCircleOutline,

            showSearch: true,

            filter: {
                createdAt: [ this.$route.query.createdAt?.[0] || '', this.$route.query.createdAt?.[1] || '' ],
                "riskManagement.giftcard.level": this.$route.query["riskManagement.giftcard.level"] || null,
                isBlock: this.$route.query.isBlock ? this.$route.query.isBlock === "true" : null,
                isDormancy: this.$route.query.isDormancy ? this.$route.query.isDormancy === "true" : null,
                isWithdrawal: false,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "이름", value: "name" },
                { text: "아이디", value: "username" },
                { text: "연락처", value: "phone" },
                { text: "이메일", value: "email" },
                { text: "과금등급", value: "riskManagement.giftcard.level" },
            ],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,
            loading: true,

            members: [],
            summary: { totalCount: 0 },
            membersHeaders: [
                { text: "아이디", value: "username", align: "center", width: 100 },
                { text: "회원이름", value: "name", align: "center", width: 100 },
                { text: "연락처", value: "phone", align: "center", width: 150 },
                { text: "포인트", value: "point", align: "center", width: 100 },
                { text: "캐시", value: "cash", align: "center", width: 100 },
                { text: "로그인 실패횟수", value: "loginFailedCount", align: "center", width: 100 },
                { text: "회원등급", value: "levelCode", align: "center", width: 100 },
                { text: "과금등급", value: "riskManagement.giftcard.level", align: "center", width: 100 },
                { text: "주문제한 / 해제", value: "riskManagement.disabled", align: "center", width: 100 },
                { text: "차단안함 / 차단", value: "isBlock", align: "center", width: 100 },
                { text: "휴면안함 / 휴면", value: "isDormancy", align: "center", width: 100 },
                { text: "방문수", value: "visitedCount", align: "center", width: 80 },
                { text: "가입일자", value: "createdAt", align: "center", width: 120 },
                { text: "최근접속일자", value: "visitedAt", align: "center", width: 120 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],

            giftcardLevels: [
                { text: ":: 전체 ::", value: null },
                { text: "normal", value: "normal" },
                { text: "vip", value: "vip" },
                { text: "vvip", value: "vvip" },
            ],

            isBlocks: [
                { text: ":: 전체 ::", value: null },
                { text: "차단안함", value: false },
                { text: "차단", value: true }
            ],

            isDormancies: [
                { text: ":: 전체 ::", value: null },
                { text: "휴면안함", value: false },
                { text: "휴면", value: true }
            ],

            orderCertTypes: ["고정", "인증", "비인증"],
        }
    },
    mounted(){
        this.getSiteInfo();

        if(this.siteInfo.recertEnabled){
            this.membersHeaders.splice(11, 0, { text: "재인증", value: "recertEnabled", align: "center", width: 100 },)
        }
        if(this.siteInfo.orderCertEnabled){
            this.membersHeaders.splice(11, 0, { text: "주문인증방식", value: "orderCertType", align: "center", width: 140 },)
        }
        if(this.siteInfo.isDisapproval === '1') {
            this.membersHeaders.unshift({ text: "승인 / 미승인", value: "isDisapproval", align: "center", width: 100 },)
        }

        this.init();
    },
    computed: {
        ...mapState(["siteInfo"]),
    },
    methods: {
        ...mapActions("siteInfo", ["getSiteInfo"]),
        async init(){
            await this.search();
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            this.loading = true;
            this.members = [];
            let { summary, members } = await api.console.members.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.loading = false;
            this.members = members;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },

        edit(member){
            this.$router.push(`/console/members/${member._id}`);
        },

        async update(member){
            await api.console.members.put(member);
        },

        async withdrawal(member){
            if(confirm("탈퇴처리 하시겠습니까?")){
                await api.console.members.withdrawal(member);
                this.search();
            }
        },

        async excel(){

            var { members } = await api.console.members.gets({
                headers: {
                    limit: 0
                },
                params: this.filter
            });

            var rows = [];
            members.forEach(member => {
                rows.push({
                    "아이디": member.username,
                    "회원이름": member.name,
                    "연락처": member.phone,
                    "이메일": member.email,
                    "우편번호": member.postcode,
                    "기본주소": member.address1,
                    "상세주소": member.address2,
                    "포인트": member.point,
                    "캐시": member.cash,
                    "로그인 실패횟수": `${member.loginFailedCount[this.$dayjs().format("YYYY-MM-DD")] || 0} / 5`,
                    "과금등급": member.riskManagement.giftcard.level,
                    "차단안함 / 차단": member.isBlock ? "차단" : "-",
                    "휴면안함 / 휴면": member.isDormancy ? "휴면" : "-",
                    "방문수": member.visitedCount || 0,
                    "가입일자": this.$dayjs(member.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                    "최근접속일자": this.$dayjs(member.visitedAt).format("YYYY-MM-DD HH:mm:ss"),
                    "SMS 수신여부": member.smsEnabled ? "수신" : "-",
                    "Email 수신여부": member.emailEnabled ? "수신" : "-",
                });
            });

            var workbook = new XLSX.utils.book_new();
            var worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "new");
            XLSX.writeFile(workbook, "회원 목록.xlsx");
        },
    }
}
</script>