<template>
    <console-content max-width="1024">

        <v-row class="mt-0 mt-md-4 px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="level._id">회원등급 상세</span>
                <span v-else>회원등급 등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
            <v-card-text>
                <v-layout class="mx-n2">
                    <v-flex class="px-2">
                        <v-text-field v-model="level.name" label="회원등급명칭" persistent-placeholder hide-details/>
                    </v-flex>
                    <v-flex class="px-2">
                        <v-text-field v-model="level.code" label="회원등급코드" persistent-placeholder hide-details/>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>

        <v-card class="mt-4 mx-1">
            <v-card-title>
                <span class="subtitle-2 font-weight-bold">포인트 적립</span>
                
            </v-card-title>
            <v-card-text>
                <v-layout class="mx-n2">
                    <v-flex class="px-2">
                        <v-text-field v-model="level.pointPrice.purchase" label="포인트 적립금" persistent-placeholder hide-details suffix="원"/>
                    </v-flex>
                    <v-flex class="px-2">
                        <v-text-field v-model="level.pointPercent.purchase" label="포인트 적립률" persistent-placeholder hide-details suffix="%"/>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-divider/>
            <v-card-text>
                <div class="ml-2 caption primary--text">* 상품구매시 포인트를 적립을 설정합니다.</div>
            </v-card-text>
        </v-card>

        <v-card v-if="$route.params._level" class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">회원목록</v-card-title>
            <v-divider/>
            <v-data-table :items="members" :headers="membersHeaders" hide-default-footer>
                <template slot="header.loginFailedCount" slot-scope="{header}">
                    <v-layout align-center>
                        <div>로그인<br/>실패횟수</div>
                    </v-layout>
                </template>

                <template v-slot:item.phone="{item}">
                    <span v-if="item.phone">{{ item.phone.phoneNumberFormat() }}</span>
                    <span v-else>-</span>
                </template>
                <template v-slot:item.isBlock="{item}">
                    <v-switch v-model="item.isBlock" hide-details class="pa-0 ma-0 d-inline-block" color="red lighten-2" disabled/>
                </template>
                <template v-slot:item.isDormancy="{item}">
                    <v-switch v-model="item.isDormancy" hide-details class="pa-0 ma-0 d-inline-block" color="red lighten-2" disabled/>
                </template>
                <template v-slot:item.actions="{item}">
                    <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                    <v-icon small class="ml-3" @click="withdrawal(item)">mdi-delete</v-icon>
                </template>
                <template slot="item.cash" slot-scope="{item}">{{ item.cash.format() }}</template>
                <template slot="item.point" slot-scope="{item}">{{ item.point.format() }}</template>
                <template slot="item.loginFailedCount" slot-scope="{item}">
                    {{ item.loginFailedCount[$dayjs().format("YYYY-MM-DD")] || 0 }} / 5
                </template>
                <template slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD") }}</template>
                <template slot="item.visitedAt" slot-scope="{item}">{{ $dayjs(item.visitedAt).format("YYYY-MM-DD") }}</template>
            </v-data-table>
            <v-divider/>
            <v-card-actions>
                <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mx-auto" @input="search" />
            </v-card-actions>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            level: {
                _id: this.$route.params._level,

                name: null,
                code: null,

                pointPercent: {
                    purchase: 0,
                },

                pointPrice: {
                    purchase: 0
                }
            },


            page: 1,
            pageCount: 0,
            limit: 10,

            members: [],
            membersHeaders: [
                { text: "아이디", value: "username", align: "center", width: 100 },
                { text: "회원이름", value: "name", align: "center", width: 100 },
                { text: "연락처", value: "phone", align: "center", width: 150 },
                { text: "이메일", value: "email", align: "center", width: 100 },
                { text: "포인트", value: "point", align: "center", width: 100 },
                { text: "캐시", value: "cash", align: "center", width: 100 },
                { text: "로그인 실패횟수", value: "loginFailedCount", align: "center", width: 100 },
                { text: "과금등급", value: "riskManagement.giftcard.level", align: "center", width: 100 },
                { text: "차단안함 / 차단", value: "isBlock", align: "center", width: 100 },
                { text: "휴면안함 / 휴면", value: "isDormancy", align: "center", width: 100 },
                { text: "방문수", value: "visitedCount", align: "center", width: 80 },
                { text: "가입일자", value: "createdAt", align: "center", width: 120 },
                { text: "최근접속일자", value: "visitedAt", align: "center", width: 120 },
            ],
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.level._id){
                let { level } = await api.console.member.levels.get(this.level);
                this.level = level;

                this.search();
            }
        },

        async search(){
            let { summary, members } = await api.console.members.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: { levelCode: this.level.code }
            });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.members = members;
        },
        async save(){
            this.level._id ? await api.console.member.levels.put(this.level) : await api.console.member.levels.post(this.level);
            alert("저장되었습니다");
            this.$router.go(-1);
        }
    }
}
</script>