var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component",
    on: {
      "edit": _vm.edit,
      "details": _vm.details
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }