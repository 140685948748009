<template>
    <v-dialog v-model="show" max-width="1200">
        <v-card>
            <v-card-title class="subtitle-1">핀 발행 내역</v-card-title>
            <v-divider/>
            <v-data-table :items="pins" :headers="pinsHeaders">
                <template slot="item.isAutoCharged" slot-scope="{item}">{{ item.isAutoCharged ? "자동충전" : "-" }}</template>
                <template slot="item.faceamt" slot-scope="{item}">{{ item.faceamt?.format?.() }}</template>
                <template v-if="item.createdAt" slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                <template v-if="item.issuedAt" slot="item.issuedAt" slot-scope="{item}">{{ $dayjs(item.issuedAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                <template v-if="item.canceledAt" slot="item.canceledAt" slot-scope="{item}">{{ $dayjs(item.canceledAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
            </v-data-table>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" text @click="close">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            show: false,

            pins: [],
            pinsHeaders: [
                { text: "상품권 이름", value: "name", width: 200 },
                { text: "핀 번호", value: "pno", width: "200" },
                { text: "액면가", value: "faceamt", width: "140" },
                { text: "자동충전여부", value: "isAutoCharged", width: "140" },
                { text: "등록일자", value: "createdAt", width: 180 },
                { text: "발행일자", value: "issuedAt", width: 180 },
                { text: "취소일자", value: "canceledAt", width: 180 },
            ]
        }
    },
    methods: {
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        }
    }
}
</script>