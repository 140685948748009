<template>
    <v-data-table :items="pins" :headers="pinsHeaders.filter(header => !header.status || header.status == filter.status )" :loading="loading" hide-default-footer disable-pagination disable-sort >
        <template slot="item.faceamt" slot-scope="{item}">{{ item.faceamt?.format?.() }}</template>
        <template slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
        <template slot="item.issuedAt" slot-scope="{item}">{{ $dayjs(item.issuedAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
        <template slot="item.canceledAt" slot-scope="{item}">{{ $dayjs(item.canceledAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
        <template slot="footer">
            <v-divider />
            <v-layout justify-center align-center class="my-2">
                <v-spacer />
                <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search()" />
                <div style="flex: 1;" class="d-flex justify-end align-center">
                    <span class="caption mr-4">Rows per page: </span>
                    <v-select v-model="limit" :items="[10, 15, 20, 50, 100]" dense hide-details class="shrink mr-2" style="width: 100px;" @input="search()"/>
                </div>
            </v-layout>
        </template>
    </v-data-table>
</template>

<script>
import api from "@/api";
export default {
    props: {
        giftcard: { type: Object },
        filter: { type: Object, default: () => { return {}; } }
    },
    data(){
        return {
            loading: false,

            page: 1,
            pageCount: 0,
            limit: 10,

            pins: [],
            pinsHeaders: [
                { text: "상품권 이름", value: "name", align: "center", width: 200 },
                { text: "핀번호", value: "pno", align: "center", width: 250 },
                { text: "액면가", value: "faceamt", align: "center", width: 150 },
                { text: "상태", value: "status", align: "center", width: 150 },
                { text: "등록일자", value: "createdAt", align: "center", width: 200 },
                { text: "발행일자", value: "issuedAt", align: "center", width: 200, status: "발행완료" },
                { text: "취소일자", value: "canceledAt", align: "center", width: 200, status: "취소완료" },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search();
        },

        async search(){
            if(!this.giftcard._id) return;
            
            this.loading = true;
            this.pins = [];

            let { summary, pins } = await api.console.gshop.giftcards.pins.gets(this.giftcard._id, {
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter,
            });

            this.pins = pins;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.loading = false;

            this.$emit("search", summary);
        }
    },
    watch: {
        filter: {
            deep: true,
            handler(newFilter, oldFilter){
                if(newFilter.name != oldFilter.name || newFilter.issuedAt != oldFilter.issuedAt || newFilter.canceledAt != oldFilter.canceledAt){
                    this.page = 1;
                    this.search();
                }
            }
        }
    }
}
</script>