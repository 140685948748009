<template>
    <console-content width="100%" max-width="1024" class="overflow-visible">
        <v-row class="mt-3 mt-md-8" align="center">
            <v-col cols="auto" class="py-0 headline text-start">포인트 내역</v-col>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>
        
        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mt-4">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-date-field v-model="filter.createdAt[0]" label="검색일자" placeholder=" " hide-details />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-date-field v-model="filter.createdAt[1]" label="검색일자" placeholder=" " hide-details />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색조건" placeholder=" " hide-details @input="filter.searchValue = null;" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field v-model="filter.searchValue" label="검색어" placeholder=" " hide-details :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true)" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text @click="$router.push({ query: {} }); $router.go(0)">
                        <v-icon small class="mt-1 mr-2">mdi-refresh</v-icon>초기화
                    </v-btn>
                    <v-btn color="primary" rounded text @click="page = 1; search(true)">
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>
        
        <v-row class="mt-4 px-1" align="end">
            <v-col cols="auto" class="subtitle-2">검색수 : {{ summary.totalCount.format() }}</v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table :items="points" :headers="pointsHeaders" hide-default-footer disable-sort disable-pagination class="mt-2 mx-1 elevation-1">
            <template #[`item.actions`]="{item}">
                <span><v-icon size="20" @click="$refs.pointDialog.point.username = item.member.username; $refs.pointDialog.open();">mdi-pencil</v-icon></span>
            </template>
            <span slot="item.amount" slot-scope="{item}">{{ item.amount.format() }}</span>
            <span slot="item.remain" slot-scope="{item}">{{ item.remain.format() }}</span>
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

        <v-btn color="accent" fab fixed bottom right dark @click="$refs.pointDialog.open()"><v-icon>mdi-pencil</v-icon></v-btn>

        <point-dialog ref="pointDialog" @submit="search()" />

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import PointDialog from "@/components/console/user/point-dialog.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent,
        VDateField,
        PointDialog
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                createdAt: [this.$route.query.createdAt?.[0] || '', this.$route.query.createdAt?.[1] || ''],
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
                type: "member"
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            points: [],
            pointsHeaders: [
                { text: "-", value: "actions", align: "center", /* width: 10 */ },
                { text: "아이디", value: "member.username", align: "center", /* width: 10 */ },
                { text: "이름", value: "member.name", align: "center", /* width: 10 */ },
                { text: "금액", value: "amount", align: "center", /* width: 10 */ },
                { text: "남은금액", value: "remain", align: "center", /* width: 10 */ },
                { text: "비고", value: "remark", align: "center", /* width: 10 */ },
                { text: "날짜", value: "createdAt", align: "center", /* width: 10 */ },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "이름", value: "member.name" },
                { text: "아이디", value: "member.username" },
                { text: "비고", value: "remark" },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, points } = await api.console.user.points.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.points = points;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        async excel(){
            let { points } = await api.console.user.points.gets({ headers: { limit: 0 }, params: this.filter });

            let rows = [];
            points.forEach(point => {
                rows.push({
                    "아이디": point.member?.username || "-",
                    "이름": point.member?.name || "-",
                    "금액": point.amount.format(),
                    "남은금액": point.remain.format(),
                    "비고": point.remark,
                    "날짜": this.$dayjs(point.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "포인트내역");
            XLSX.writeFile(workbook, "포인트내역.xlsx");
        }
    }
}
</script>