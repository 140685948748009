<template>
    <console-content max-width="1024">

        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="py-0 headline text-start">
                <span>기타 설정</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-row class="mt-3">
            <v-col cols="12" md="8" class="py-0 pr-md-1">
                <v-card class="mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">Risk Management</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-0"><v-text-field v-model="setting.riskManagement.cp.balances[0]" label="알람금액1" placeholder=" " hide-details></v-text-field></v-col>
                            <v-col class="py-0"><v-text-field v-model="setting.riskManagement.cp.balances[1]" label="알람금액2" placeholder=" " hide-details></v-text-field></v-col>
                            <v-col class="py-0"><v-text-field v-model="setting.riskManagement.cp.balances[2]" label="알람금액3" placeholder=" " hide-details></v-text-field></v-col>
                        </v-row>
                        <span class="d-flex caption primary--text mt-2">* CP 금액이 차감되어 알람금액1 -> 2 -> 3 도달시 알람이 발송됩니다</span>
                    </v-card-text>
                </v-card>

                <v-card class="mx-1 mt-3">
                    <v-card-title class="subtitle-2 font-weight-bold">RM 연락처</v-card-title>
                    <v-card-text>
                        <v-layout>
                            <v-chip v-for="(phone, index) in setting.riskManagement.cp.phones" :key="index" close @click:close="setting.riskManagement.cp.phones.splice(index, 1)" class="mr-2">{{ phone }}</v-chip>
                        </v-layout>
                        <v-row no-gutters align="end" class="mt-2">
                            <v-col class="py-0"><v-text-field v-model="phone" label="연락처" placeholder=" " hide-details/></v-col>
                            <v-col cols="auto" class="py-0"><v-btn color="secondary" rounded @click="setting.riskManagement.cp.phones.push(phone)"><v-icon small class="mr-2">mdi-plus</v-icon>연락처 등록</v-btn></v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="4" class="py-0 pl-md-1">
                <v-card class="mx-1 mt-3 mt-md-0">
                    <v-card-title>
                        <span class="subtitle-2">RM 반복수신</span>
                        <v-spacer/>
                        <v-switch v-model="setting.riskManagement.cp.isAlways" hide-details class="mt-0"/>
                    </v-card-title>
                    <v-card-text>
                        <span class="caption primary--text">* CP금액이 [알람금액3] 이하일때 5분 단위로 문자를 수신하는 기능입니다</span>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            setting: {},

            phone: null,
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { setting } = await api.console.common.setting.get();
            this.setting = setting;
        },
        async save(){
            await api.console.common.setting.put(this.setting);
            alert("저장되었습니다");
        }
    }
}
</script>