var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 mt-md-8 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("기타 설정")])]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0 pr-md-1",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("Risk Management")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "알람금액1",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.setting.riskManagement.cp.balances[0],
      callback: function ($$v) {
        _vm.$set(_vm.setting.riskManagement.cp.balances, 0, $$v);
      },
      expression: "setting.riskManagement.cp.balances[0]"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "알람금액2",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.setting.riskManagement.cp.balances[1],
      callback: function ($$v) {
        _vm.$set(_vm.setting.riskManagement.cp.balances, 1, $$v);
      },
      expression: "setting.riskManagement.cp.balances[1]"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "알람금액3",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.setting.riskManagement.cp.balances[2],
      callback: function ($$v) {
        _vm.$set(_vm.setting.riskManagement.cp.balances, 2, $$v);
      },
      expression: "setting.riskManagement.cp.balances[2]"
    }
  })], 1)], 1), _c('span', {
    staticClass: "d-flex caption primary--text mt-2"
  }, [_vm._v("* CP 금액이 차감되어 알람금액1 -> 2 -> 3 도달시 알람이 발송됩니다")])], 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-3"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("RM 연락처")]), _c('v-card-text', [_c('v-layout', _vm._l(_vm.setting.riskManagement.cp.phones, function (phone, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "mr-2",
      attrs: {
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          return _vm.setting.riskManagement.cp.phones.splice(index, 1);
        }
      }
    }, [_vm._v(_vm._s(phone))]);
  }), 1), _c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "no-gutters": "",
      "align": "end"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        return _vm.setting.riskManagement.cp.phones.push(_vm.phone);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("연락처 등록")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0 pl-md-1",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "mx-1 mt-3 mt-md-0"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("RM 반복수신")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.setting.riskManagement.cp.isAlways,
      callback: function ($$v) {
        _vm.$set(_vm.setting.riskManagement.cp, "isAlways", $$v);
      },
      expression: "setting.riskManagement.cp.isAlways"
    }
  })], 1), _c('v-card-text', [_c('span', {
    staticClass: "caption primary--text"
  }, [_vm._v("* CP금액이 [알람금액3] 이하일때 5분 단위로 문자를 수신하는 기능입니다")])])], 1)], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }