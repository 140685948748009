<template>
    <console-layout width="100%" max-width="800">
        <console-title>
            <span>상품권별 추가 문자메세지</span>
        </console-title>

        <console-body>
            <v-card>
                <v-card-title class="py-2">
                    <span class="subtitle-2">상품권</span>
                    <v-spacer />
                    <span>
                        <v-btn color="primary" class="my-0" @click="$refs.messageDialog.open()"><v-icon left>mdi-plus</v-icon>상품권 추가</v-btn>
                    </span>
                </v-card-title>
                <v-divider />
                <v-data-table :items="message.giftcards" :headers="giftcardsHeaders" disable-sort disable-pagination hide-default-footer>
                    <template #[`item.thumb`]="{item}">
                        <v-img :src="item.thumb.path || item.thumb.src" width="auto" height="96" contain/>
                    </template>
                    <template #[`item.actions`]="{item}">
                        <v-icon size="20" @click="remove(item._id)">mdi-delete</v-icon>
                    </template>

                </v-data-table>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>
                    <span class="subtitle-2">문자 메세지</span>
                </v-card-title>

                <v-card-text>
                    <v-textarea v-model="message.endMessage" outlined />
                </v-card-text>
            </v-card>
        </console-body>

        <v-layout class="my-4">
            <span class="mx-auto">
                <v-btn color="primary" @click="save()">저장</v-btn>
            </span>
        </v-layout>

        <message-dialog ref="messageDialog" @submit="insert" />

    </console-layout>
</template>

<script>
import api from "@/api";
import MessageDialog from "@/components/console/gshop/messages/message-dialog.vue";
export default {
    components: {
        MessageDialog
    },
    data(){
        return {
            message: {
                _giftcards: [],
                giftcards: [],

                endMessage: null
            },
            giftcardsHeaders: [
                { text: "썸네일", value: "thumb", width: "100" },
                { text: "상품권", value: "name" },
                { text: "actions", value: "actions", width: "100" },
            ],
        }
    },
    methods: {
        async init(){
            if(this.$route.params._id){
                let { message } = await api.console.gshop.messages.get({ _id: this.$route.params._id });
                this.message = message;
            }
        },
        insert(giftcards){
            giftcards.forEach(giftcard => {
                if(!this.message._giftcards.includes(giftcard._id)){
                    this.message._giftcards.push(giftcard._id);
                    this.message.giftcards.push(giftcard);
                }
            });

            this.$forceUpdate();
        },
        remove(_id){
            this.message._giftcards.splice(this.message._giftcards.findIndex(_giftcard => _giftcard == _id), 1);
            this.message.giftcards.splice(this.message.giftcards.findIndex(giftcard => giftcard._id == _id), 1);
        },
        async save(){
            await api.console.gshop.messages[this.message._id ? "put" : "post"](this.message);
            alert("저장되었습니다");
            this.$router.push("/console/gshop/messages");
        }
    },
    mounted(){
        this.init();
    }
}
</script>