<template>
    <console-content max-width="1024">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="notification._id">공지상세</span>
                <span v-else>공지등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-row class="mt-3">
            <v-col cols="12" md="8" class="py-0 pr-md-1">
                <v-card class="mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="notification.subject" label="제목" persistent-placeholder hide-details />
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="4" class="py-0 pl-md-1">
                <v-card class="mx-1 mt-3 mt-md-0">
                    <v-card-title>
                        <span class="subtitle-2">상단고정</span>
                        <v-spacer/>
                        <v-switch v-model="notification.isNotice" hide-details class="mt-0"/>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">상세정보</v-card-title>
            <v-card-text>
                <naver-smarteditor v-model="notification.content" />
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            notification: {
                _id: this.$route.params._notification,

                subject: null,
                content: null,

                isNotice: false,
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.notification._id){
                let { notification } = await api.console.center.notifications.get(this.notification);
                this.notification = notification;
            }
        },
        async save(){
            this.notification._id ? await api.console.center.notifications.put(this.notification) : await api.console.center.notifications.post(this.notification);
            alert("저장되었습니다");
            this.$router.go(-1);
        }
    }
}
</script>