<template>
    <v-autocomplete v-model="id" :items="giftcards" :item-text="itemText" item-value="_id" v-bind="$attrs" @click="alert" />
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: String, default: null },
    },
    data: () => ({
        id: null,
        giftcards: [],
    }),
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        id(id) {
            this.$emit("input", id);
        },
    },
    methods: {
        sync() {
            this.id = this.value;
        },
        async init() {
            this.giftcards = (await api.console.gshop.giftcards.gets({ params: {} }))?.giftcards || [];
        },
        itemText({ name }) {
            return `${name}`;
        },
        alert() {
            if (this.$attrs.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
        },
    },
};
</script>

<style></style>
