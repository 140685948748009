var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1280"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("재고관리")])]), _c('v-spacer')], 1), _vm._l(_vm.giftcard.options, function (option, index) {
    return [_vm.ready ? _c('giftcard-stock', {
      key: index,
      attrs: {
        "giftcard": _vm.giftcard,
        "option": option
      },
      on: {
        "remove": function ($event) {
          return _vm.remove(option);
        }
      }
    }) : _vm._e()];
  }), _c('option-dialog', {
    on: {
      "confirm": function (option) {
        return _vm.insert(option);
      }
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }