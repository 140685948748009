<template>
    <component :is="display" />
</template>

<script>
export default {
    mounted(){
        if(!this.scope.includes("root") && !this.scope.includes("admin")){
            alert("관리자 계정으로만 접속 가능합니다");
            this.$router.go(-1);
        }
    },
    computed: {
        scope() {
            return this.$store.state.scope;
        },
        display() {
            if(this.scope.includes("root") || this.scope.includes("admin")) return () => import("./gshop/giftcard/GiftcardList.vue");
            return null;
        },
        vuetify(){
            return this.$vuetify;
        }
    }
};
</script>
