<template>
    <console-content max-width="1280">

        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="py-0 headline text-start">탈퇴회원</v-col>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>
        
        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mt-3 mx-1">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="3" class="py-0">
                            <v-select v-model="filter.levelCode" label="회원등급" placeholder=" " hide-details :items="giftcardLevels" item-text="text" item-value="value"/>
                        </v-col>
                        <v-col cols="12" sm="3" class="py-0">
                            <v-select v-model="filter.searchKey" label="검색조건" placeholder=" " hide-details :items="searchKeys" item-text="text" item-value="value" @input="filter.searchValue = null;"/>
                        </v-col>
                        <v-col cols="12" sm="3" class="py-0">
                            <v-text-field v-model="filter.searchValue" label="검색어" placeholder=" " hide-details :disabled="!filter.searchKey" @keydown.enter="page=1; search(true)" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text @click="$router.push({ query: {} }); $router.go(0)">
                        <v-icon small class="mt-1 mr-2">mdi-refresh</v-icon>초기화
                    </v-btn>
                    <v-btn color="primary" rounded text @click="page = 1; search(true)">
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>
        
        <v-row class="mt-6 px-1" align="end">
            <v-col cols="auto" class="py-0">
                <span class="ception">검색된 회원수: {{ summary.totalCount.format() }}</span>
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="py-0">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table :loading="loading" :items="members" :headers="membersHeaders" hide-default-footer disable-sort class="mt-3 mx-1 elevation-1">
            <span slot="item.phone" slot-scope="{item}">{{ item?.phone?.phoneNumberFormat?.() ?? '-' }}</span>
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <span slot="item.withdrawnAt" slot-scope="{item}">{{ $dayjs(item.withdrawnAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <span slot="item.recovery" slot-scope="{item}">
                <v-btn small color="primary" @click="recovery(item)">탈퇴취소</v-btn>
            </span>
            <span slot="item.actions" slot-scope="{item}">
                <v-icon small @click="remove(item)">mdi-delete</v-icon>
            </span>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-4" @input="search(true)" />

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { mdiMicrosoftExcel, mdiPlusCircleMultipleOutline } from "@mdi/js";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";

export default {
    components: {
        ConsoleContent
    },
    data(){
        return {
            mdiMicrosoftExcel,
            mdiPlusCircleMultipleOutline,

            showSearch: true,

            filter: {
                levelCode: this.$route.query.levelCode || null,
                isWithdrawal: true,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "이름", value: "name" },
                { text: "아이디", value: "username" },
                { text: "연락처", value: "phone" },
                { text: "이메일", value: "email" },
            ],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,
            loading: true,

            members: [],
            summary: { totalCount: 0 },
            membersHeaders: [
                { text: "아이디", value: "username", align: "center" },
                { text: "회원이름", value: "name", align: "center" },
                { text: "연락처", value: "phone", align: "center" },
                { text: "이메일", value: "email", align: "center" },
                { text: "가입일자", value: "createdAt", align: "center" },
                { text: "탈퇴일자", value: "withdrawnAt", align: "center" },
                { text: "탈퇴취소", value: "recovery", align: "center" },
                { text: "회원삭제", value: "actions", align: "center" },
            ],

            giftcardLevels: [
                { text: ":: 전체 ::", value: null },
                { text: "normal", value: "normal" },
                { text: "vip", value: "vip" },
                { text: "vvip", value: "vvip" },
            ],
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            this.loading = true;
            this.members = [];
            let { summary, members } = await api.console.members.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.loading = false;
            this.members = members;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        async remove(member){
            if(confirm("회원삭제를 하시면 복구가 불가능합니다. 삭제하시겠습니까?")){
                await api.console.members.delete(member);
                this.search();
            }
        },
        async recovery(member){
            if(confirm("탈퇴취소를 하시면 다시 서비스 이용이 가능해집니다. 탈퇴취소 하시겠습니까?")){
                await api.console.members.recovery(member);
                this.search();
            }
        },

        async excel(){

            var { members } = await api.console.members.gets({
                headers: {
                    limit: 0
                },
                params: this.filter
            });

            var rows = [];
            members.forEach(member => {
                rows.push({
                    "아이디": member.username,
                    "회원이름": member.name,
                    "연락처": member.phone,
                    "이메일": member.email,
                    "가입일자": member.createdAt ? this.$dayjs(member.createdAt).format("YYYY-MM-DD HH:mm:ss") : '-',
                    "탈퇴일자": member.withdrawnAt ? this.$dayjs(member.withdrawnAt).format("YYYY-MM-DD HH:mm:ss") : '-'
                });
            });

            var workbook = new XLSX.utils.book_new();
            var worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "new");
            XLSX.writeFile(workbook, "탈퇴회원.xlsx");
        },
    }
}
</script>