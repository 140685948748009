var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 mt-md-8 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("가격별 주문명칭")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('v-data-table', {
    staticClass: "mt-4 mx-1 elevation-1",
    attrs: {
      "items": _vm.orderNames,
      "headers": _vm.orderNamesHeaders,
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "item.min-max",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.min.format()) + "(이상) ~ " + _vm._s(item.max.format()) + "(이하) ")];
      }
    }, {
      key: "item.actions",
      fn: function (_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return _c('span', {}, [_c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.details(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item, index);
            }
          }
        }, [_vm._v("mdi-delete")])], 1);
      }
    }])
  }), _c('v-btn', {
    attrs: {
      "fab": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.create();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('order-name-dialog', {
    ref: "orderNameDialog",
    attrs: {
      "orderNames": _vm.orderNames
    },
    on: {
      "save": function ($event) {
        return _vm.search();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }