var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-responsive', {
    staticClass: "overflow-auto",
    attrs: {
      "max-height": "70vh"
    }
  }, [_c('v-simple-table', [_c('thead', [_c('tr', [_c('th', [_vm._v("상품권")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("권종")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("-")])])]), _c('tbody', [_vm._l(_vm.giftcards, function (giftcard, index) {
    var _giftcard$faceamt, _giftcard$faceamt$for;
    return [_c('tr', {
      key: index
    }, [_c('td', [_c('div', {
      staticClass: "d-flex align-center justify-start"
    }, [_c('v-img', {
      staticClass: "d-inline-block mr-4",
      attrs: {
        "src": giftcard.thumb,
        "max-width": "96",
        "height": "96",
        "contain": ""
      }
    }), _c('div', [_vm._v(_vm._s(giftcard.name))])], 1)]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_giftcard$faceamt = giftcard.faceamt) === null || _giftcard$faceamt === void 0 ? void 0 : (_giftcard$faceamt$for = _giftcard$faceamt.format) === null || _giftcard$faceamt$for === void 0 ? void 0 : _giftcard$faceamt$for.call(_giftcard$faceamt)))]), _c('td', {
      staticClass: "text-center"
    }, [_c('v-btn', {
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.select(giftcard);
        }
      }
    }, [_vm._v("옵션 등록")])], 1)])];
  })], 2)])], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    staticClass: "ml-auto",
    attrs: {
      "text": "",
      "color": "grey"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("닫기")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }