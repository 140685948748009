<template>
    <console-content max-width="1024">

        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="terms._id">약관 상세</span>
                <span v-else>약관 등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-row class="mt-3">
            <v-col cols="12" md="8" class="pr-md-1">
                <v-card class="mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="terms.subject" label="제목" persistent-placeholder hide-details />
                        <v-text-field v-model="terms.code" label="코드" persistent-placeholder hide-details class="mt-4"/>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="4" class="pl-md-1">
                <v-card class="mx-1 mt-3 mt-md-0">
                    <v-card-title>
                        <span class="subtitle-2">필수여부 (미필수/필수)</span>
                        <v-spacer/>
                        <v-switch v-model="terms.required" hide-details class="mt-0"/>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">상세정보</v-card-title>
            <v-card-text>
                <naver-smarteditor v-model="terms.content" />
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            terms: {
                _id: this.$route.params._terms,

                name: null,
                code: null,
                subject: null,
                content: null,
                required: false,
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.terms._id){
                let { terms } = await api.console.terms.get(this.terms);
                this.terms = terms;
            }
        },
        async save(){
            this.terms._id ? await api.console.terms.put(this.terms) : await api.console.terms.post(this.terms);
            alert("저장되었습니다");
            this.$router.go(-1);
        }
    }
}
</script>