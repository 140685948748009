var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1280"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("팝업 목록")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('v-row', {
    staticClass: "mt-2 px-1",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', {
    staticClass: "subtitle-2",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("검색수 : " + _vm._s(_vm.summary.totalCount.format()))]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('v-data-table', {
    staticClass: "mt-3 mx-1 elevation-1",
    attrs: {
      "items": _vm.popups,
      "headers": _vm.popupsHeaders,
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }])
  }), _c('v-pagination', {
    staticClass: "mt-3",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-btn', {
    attrs: {
      "fab": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.popupDialog.open();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('popup-dialog', {
    ref: "popupDialog",
    on: {
      "close": function ($event) {
        return _vm.search(false);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }