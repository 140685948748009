<template>
    <v-dialog v-model="show" max-width="600">
        <v-card>
            <v-card-title>
                <span class="subtitle-1">상품권 추가</span>
            </v-card-title>
            <v-card-text>
                <v-combobox v-model="selected" label="상품권" outlined multiple persistent-placeholder hide-details :items="giftcards" item-text="name" height="auto" />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="grey" @click="close()">취소</v-btn>
                <v-btn text color="accent" @click="submit()">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";

export default {
    data(){
        return {
            show: false,

            giftcards: [],
            selected: [],
        }
    },
    methods: {
        async init(){
            let { giftcards } = await api.console.gshop.giftcards.gets({ headers: { limit: 0 }, params: { type: "giftcard" } });
            this.giftcards = giftcards;
        },
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
            this.selected = [];
        },
        submit(){
            this.$emit("submit", this.selected);
            this.close();
        }
    },
    mounted(){
        this.init();
    }
}
</script>