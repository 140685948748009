<template>
    <console-content max-width="1280">
        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="py-0 headline text-start">1:1 문의</v-col>
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>
        
        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mt-3 mx-1">
                <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="3" class="py-0">
                            <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색조건" placeholder=" " hide-details @input="filter.searchValue = null;" />
                        </v-col>
                        <v-col cols="12" sm="3" class="py-0">
                            <v-text-field v-model="filter.searchValue" label="검색어" placeholder=" " hide-details :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true)" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text @click="$router.push({ query: {} }); $router.go(0)">
                        <v-icon small class="mt-1 mr-2">mdi-refresh</v-icon>초기화
                    </v-btn>
                    <v-btn color="primary" rounded text @click="page = 1; search(true);">
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>

        <v-data-table :items="questions" :headers="questionsHeaders" hide-default-footer disable-sort disable-pagination class="mt-3 mx-1 elevation-1">
            <span slot="item.no" slot-scope="{item}">{{ (page - 1) * limit + +questions.indexOf(item) + 1 }}</span>
            <span slot="item.writer" slot-scope="{item}">
                <div>{{ item?.writer?.name }}</div>
                <div>{{ item?.writer?.phone }}</div>
                <div>{{ item?.writer?.email }}</div>
            </span>
            <span slot="item.answer" slot-scope="{item}">
                <span v-if="item.answer">답변</span>
                <span v-else>미답변</span>
            </span>
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <span slot="item.actions" slot-scope="{item}">
                <v-icon small @click="edit(item)">mdi-pencil</v-icon>
            </span>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            questions: [],
            questionsHeaders: [
                { text: "no", value: "no", align: "center", width: 100 },
                { text: "제목", value: "subject", align: "center" },
                { text: "작성자", value: "writer", align: "center", width: 180 },
                { text: "답변여부", value: "answer", align: "center", width: 100 },
                { text: "작성일자", value: "createdAt", align: "center", width: 180 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, questions } = await api.console.center.questions.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.questions = questions;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        edit(question){
            this.$router.push(`/console/center/questions/${question._id}`);
        },
        // async excel(){
        //     let { questions } = await api.console.center.questions.gets({ headers: { limit: 0 }, params: this.filter });

        //     let rows = [];
        //     questions.forEach(question => {
        //         rows.push({
        //             "no": question.no,
        //             "이름": question.name,
        //         });
        //     });

        //     let workbook = new XLSX.utils.book_new();
        //     let worksheet = XLSX.utils.json_to_sheet(rows);

        //     XLSX.utils.book_append_sheet(workbook, worksheet, "엑셀시트이름");
        //     XLSX.writeFile(workbook, "엑셀파일이름.xlsx");
        // }
    }
}
</script>