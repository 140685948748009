<template>
    <component :is="template" @edit="edit" @details="details" />
</template>

<script>
import ThemeDetails from "@/templates/console/theme/ThemeDetails.vue";
import ThemeInput from "@/templates/console/theme/ThemeInput.vue";

export default {
    components: {
        ThemeDetails,
        ThemeInput
    },
    data(){
        return {
            template: this.$route.params._theme ? "ThemeDetails" : "ThemeInput",
            templates: ["ThemeDetails", "ThemeInput"]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        edit(){
            this.template = "ThemeInput";
        },
        details(){
            this.template = "ThemeDetails";
        },
    },
}
</script>