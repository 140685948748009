<template>
    <console-content max-width="560">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">푸터 정보</v-col>
            <v-spacer />
        </v-row>

        <v-card class="mt-4">
            <term-desc-table v-model="setting.footerInfo" v-bind="{ initItems }" />
        </v-card>

        <v-layout justify-end class="mt-4"> <v-btn color="primary" @click="save">저장하기</v-btn> </v-layout>
    </console-content>
</template>

<script>
import api from "@/api";
import TermDescTable from "@/components/console/dumb/term-desc-table.vue";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import VImageField from "@/components/plugins/vuetify/v-image-field.vue";

let initItems = () => [
    { id: Math.random(), term: "회사명", desc: null },
    { id: Math.random(), term: "주소", desc: null },
    { id: Math.random(), term: "대표번호", desc: null },
    { id: Math.random(), term: "대표자", desc: null },
    { id: Math.random(), term: "대표핸드폰번호", desc: null },
    { id: Math.random(), term: "사업자등록번호", desc: null },
];

export default {
    components: {
        TermDescTable,
        ConsoleContent,
        VImageField,
    },
    created() {
        this.init();
    },
    data() {
        return {
            setting: {
                footerInfo: [],
            },

            initItems,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            var { setting } = await api.console.common.setting.get();
            this.setting = setting;
        },

        async save() {
            let { _id, footerInfo } = this.setting;
            await api.console.common.setting.put({ _id, footerInfo });
            alert("저장되었습니다.");
        },
    },
};
</script>
