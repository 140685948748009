var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', _vm._b({
    staticClass: "v-dragdrop-field",
    class: {
      'grey lighten-2': _vm.dragover
    },
    on: {
      "drop": function ($event) {
        $event.preventDefault();
        return _vm.onDrop($event);
      },
      "dragover": function ($event) {
        $event.preventDefault();
        _vm.dragover = true;
      },
      "dragenter": function ($event) {
        $event.preventDefault();
        _vm.dragover = true;
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        _vm.dragover = false;
      },
      "click": function ($event) {
        return _vm.$refs.input.$refs.input.click();
      }
    }
  }, 'v-sheet', _vm.$attrs, false), [_c('v-layout', {
    attrs: {
      "align-center": "",
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    attrs: {
      "size": "32"
    }
  }, [_vm._v(_vm._s(_vm.mdiCloudUpload))])], 1), _c('div', {
    staticClass: "mt-2 body-2"
  }, [_c('span', [_vm._v("파일을 이쪽으로 끌어오거나, 클릭하여 파일첨부")])])])], 1), _c('v-file-input', _vm._b({
    ref: "input",
    staticClass: "d-none",
    attrs: {
      "multiple": _vm.multiple
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, 'v-file-input', _vm.$attrs, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }