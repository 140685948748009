import { render, staticRenderFns } from "./ConfigView.vue?vue&type=template&id=4456cfcd"
import script from "./ConfigView.vue?vue&type=script&lang=js"
export * from "./ConfigView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports