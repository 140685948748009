var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-layout', {
    attrs: {
      "width": "100%",
      "max-width": "800"
    }
  }, [_c('console-title', [_c('span', [_vm._v("상품권별 추가 문자메세지")])]), _c('console-body', [_c('v-card', [_c('v-card-title', {
    staticClass: "py-2"
  }, [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("상품권")]), _c('v-spacer'), _c('span', [_c('v-btn', {
    staticClass: "my-0",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.messageDialog.open();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("상품권 추가")], 1)], 1)], 1), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "items": _vm.message.giftcards,
      "headers": _vm.giftcardsHeaders,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.thumb`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-img', {
          attrs: {
            "src": item.thumb.path || item.thumb.src,
            "width": "auto",
            "height": "96",
            "contain": ""
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          attrs: {
            "size": "20"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item._id);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }], null, true)
  })], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2"
  }, [_vm._v("문자 메세지")])]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": ""
    },
    model: {
      value: _vm.message.endMessage,
      callback: function ($$v) {
        _vm.$set(_vm.message, "endMessage", $$v);
      },
      expression: "message.endMessage"
    }
  })], 1)], 1)], 1), _c('v-layout', {
    staticClass: "my-4"
  }, [_c('span', {
    staticClass: "mx-auto"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("저장")])], 1)]), _c('message-dialog', {
    ref: "messageDialog",
    on: {
      "submit": _vm.insert
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }