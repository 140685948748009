var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.faq._id ? _c('span', [_vm._v("FAQ 상세")]) : _c('span', [_vm._v("FAQ 등록")])]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "Q. 질문",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.faq.question,
      callback: function ($$v) {
        _vm.$set(_vm.faq, "question", $$v);
      },
      expression: "faq.question"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "순서",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.faq.no,
      callback: function ($$v) {
        _vm.$set(_vm.faq, "no", $$v);
      },
      expression: "faq.no"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-text', [_c('v-textarea', {
    attrs: {
      "label": "A. 답변",
      "rows": "15",
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.faq.answer,
      callback: function ($$v) {
        _vm.$set(_vm.faq, "answer", $$v);
      },
      expression: "faq.answer"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }