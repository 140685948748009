<template>
    <console-content max-width="1024">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="board._id">게시글 상세</span>
                <span v-else>게시글 등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mt-2 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
            <v-card-text>

                <v-layout class="mx-n2">
                    <v-flex xs6 class="px-2">
                        <v-text-field v-model="board.subject" label="제목" persistent-placeholder hide-details />
                    </v-flex>
                    <v-flex xs6 class="px-2">
                        <v-text-field :value="$dayjs(board.createdAt).format('YYYY-MM-DD HH:mm:ss')" label="작성일자" persistent-placeholder hide-details disabled />
                    </v-flex>
                </v-layout>

                <v-layout class="mt-4 mx-n2">
                    <v-flex xs6 class="px-2">
                        <v-text-field v-model="board.writer.name" label="작성자 이름" persistent-placeholder hide-details />
                    </v-flex>
                    <v-flex xs6 class="px-2">
                        <v-text-field v-model="board.writer.email" label="작성자 이메일" persistent-placeholder hide-details />
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">문의내용</v-card-title>
            <v-card-text>
                <v-textarea v-model="board.content" outlined hide-details/>
            </v-card-text>
            <v-card-title class="subtitle-2 font-weight-bold">첨부파일</v-card-title>
            <v-card-text>
                <span v-if="!board.images.some(image => !!image)">없음</span>
                <template v-else v-for="(image, index) in board.images">
                    <v-btn :key="index" color="primary" outlined class="mr-2" @click="download(image, `첨부이미지_${index + 1}`)"><v-icon size="20" class="mr-2">{{ mdiDownload }}</v-icon>이미지 다운로드 {{ index + 1}}</v-btn>
                </template>
            </v-card-text>
        </v-card>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">답변내용</v-card-title>
            <v-card-text>
                <naver-smarteditor v-model="board.reply" outlined />
            </v-card-text>
        </v-card>

        <v-row class="my-4">
            <v-spacer/>
            <v-col cols="auto" class="">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import { mdiDownload } from "@mdi/js";
import download from "downloadjs";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            mdiDownload,

            board: {
                _id: this.$route.params._board,
                
                code: "event",
                subject: null,
                content: null,

                isNotice: false
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.board._id){
                let { board } = await api.console.boards.get(this.board);
                this.board = board;
            }
        },
        async save(){
            this.board._id ? await api.console.boards.put(this.board) : await api.console.boards.post(this.board);
            alert("저장되었습니다");
            this.$router.go(-1);
        },

        download(path = "", rename){
            api.getResource(path).then(file => download(file, rename + path.substr(path.lastIndexOf('.')) ))
        }
    }
}
</script>