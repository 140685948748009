var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "mt-0 mt-md-4 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.level._id ? _c('span', [_vm._v("회원등급 상세")]) : _c('span', [_vm._v("회원등급 등록")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-layout', {
    staticClass: "mx-n2"
  }, [_c('v-flex', {
    staticClass: "px-2"
  }, [_c('v-text-field', {
    attrs: {
      "label": "회원등급명칭",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.level.name,
      callback: function ($$v) {
        _vm.$set(_vm.level, "name", $$v);
      },
      expression: "level.name"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-2"
  }, [_c('v-text-field', {
    attrs: {
      "label": "회원등급코드",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.level.code,
      callback: function ($$v) {
        _vm.$set(_vm.level, "code", $$v);
      },
      expression: "level.code"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("포인트 적립")])]), _c('v-card-text', [_c('v-layout', {
    staticClass: "mx-n2"
  }, [_c('v-flex', {
    staticClass: "px-2"
  }, [_c('v-text-field', {
    attrs: {
      "label": "포인트 적립금",
      "persistent-placeholder": "",
      "hide-details": "",
      "suffix": "원"
    },
    model: {
      value: _vm.level.pointPrice.purchase,
      callback: function ($$v) {
        _vm.$set(_vm.level.pointPrice, "purchase", $$v);
      },
      expression: "level.pointPrice.purchase"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-2"
  }, [_c('v-text-field', {
    attrs: {
      "label": "포인트 적립률",
      "persistent-placeholder": "",
      "hide-details": "",
      "suffix": "%"
    },
    model: {
      value: _vm.level.pointPercent.purchase,
      callback: function ($$v) {
        _vm.$set(_vm.level.pointPercent, "purchase", $$v);
      },
      expression: "level.pointPercent.purchase"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('div', {
    staticClass: "ml-2 caption primary--text"
  }, [_vm._v("* 상품구매시 포인트를 적립을 설정합니다.")])])], 1), _vm.$route.params._level ? _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("회원목록")]), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "items": _vm.members,
      "headers": _vm.membersHeaders,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "header.loginFailedCount",
      fn: function (_ref) {
        var header = _ref.header;
        return [_c('v-layout', {
          attrs: {
            "align-center": ""
          }
        }, [_c('div', [_vm._v("로그인"), _c('br'), _vm._v("실패횟수")])])];
      }
    }, {
      key: "item.phone",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.phone ? _c('span', [_vm._v(_vm._s(item.phone.phoneNumberFormat()))]) : _c('span', [_vm._v("-")])];
      }
    }, {
      key: "item.isBlock",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-switch', {
          staticClass: "pa-0 ma-0 d-inline-block",
          attrs: {
            "hide-details": "",
            "color": "red lighten-2",
            "disabled": ""
          },
          model: {
            value: item.isBlock,
            callback: function ($$v) {
              _vm.$set(item, "isBlock", $$v);
            },
            expression: "item.isBlock"
          }
        })];
      }
    }, {
      key: "item.isDormancy",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-switch', {
          staticClass: "pa-0 ma-0 d-inline-block",
          attrs: {
            "hide-details": "",
            "color": "red lighten-2",
            "disabled": ""
          },
          model: {
            value: item.isDormancy,
            callback: function ($$v) {
              _vm.$set(item, "isDormancy", $$v);
            },
            expression: "item.isDormancy"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "ml-3",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.withdrawal(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }, {
      key: "item.cash",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(_vm._s(item.cash.format()))];
      }
    }, {
      key: "item.point",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(_vm._s(item.point.format()))];
      }
    }, {
      key: "item.loginFailedCount",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.loginFailedCount[_vm.$dayjs().format("YYYY-MM-DD")] || 0) + " / 5 ")];
      }
    }, {
      key: "item.createdAt",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(_vm._s(_vm.$dayjs(item.createdAt).format("YYYY-MM-DD")))];
      }
    }, {
      key: "item.visitedAt",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(_vm._s(_vm.$dayjs(item.visitedAt).format("YYYY-MM-DD")))];
      }
    }], null, false, 2483109969)
  }), _c('v-divider'), _c('v-card-actions', [_c('v-pagination', {
    staticClass: "mx-auto",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }