var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-layout', {
    attrs: {
      "width": "100%",
      "max-width": "800"
    }
  }, [_c('console-title', [_c('span', [_vm._v("상품권별 추가 문자메세지")])]), _c('console-body', [_c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "items": _vm.messages,
      "headers": _vm.messagesHeaders,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.no`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(_vm.messages.indexOf(item) + 1))])];
      }
    }, {
      key: `item.giftcards`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', _vm._l(item.giftcards, function (giftcard) {
          return _c('div', {
            key: giftcard._id
          }, [_c('span', [_vm._v(_vm._s(giftcard.name))])]);
        }), 0)];
      }
    }, {
      key: `item.endMessage`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "d-block"
        }, [_c('div', {
          domProps: {
            "innerHTML": _vm._s(item.endMessage.replace(/\n/g, `<br />`))
          }
        })])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('span', [_c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "size": "20"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "size": "20"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])], 1)];
      }
    }], null, true)
  })], 1), _c('v-btn', {
    attrs: {
      "fab": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "color": "accent"
    },
    on: {
      "click": _vm.create
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }