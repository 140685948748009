var render = function render(){
  var _vm$popup;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800px"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.show ? _c('v-card', [_c('v-card-title', [(_vm$popup = _vm.popup) !== null && _vm$popup !== void 0 && _vm$popup._id ? _c('span', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("팝업수정")]) : _c('span', {
    staticClass: "subtitle-1 font-weight-bold"
  }, [_vm._v("팝업추가")])]), _c('v-card-text', [_c('v-layout', {
    staticClass: "mt-4"
  }, [_c('v-flex', [_c('v-text-field', {
    attrs: {
      "label": "명칭",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.popup.name,
      callback: function ($$v) {
        _vm.$set(_vm.popup, "name", $$v);
      },
      expression: "popup.name"
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "mt-8"
  }, [_c('v-flex', [_c('v-text-field', {
    attrs: {
      "label": "순서",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.popup.no,
      callback: function ($$v) {
        _vm.$set(_vm.popup, "no", $$v);
      },
      expression: "popup.no"
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "mt-8"
  }, [_c('v-flex', [_c('v-text-field', {
    attrs: {
      "label": "URL",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.popup.url,
      callback: function ($$v) {
        _vm.$set(_vm.popup, "url", $$v);
      },
      expression: "popup.url"
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "mt-8 mx-n2"
  }, [_c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "위치(x)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.popup.left,
      callback: function ($$v) {
        _vm.$set(_vm.popup, "left", $$v);
      },
      expression: "popup.left"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "위치(y)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.popup.top,
      callback: function ($$v) {
        _vm.$set(_vm.popup, "top", $$v);
      },
      expression: "popup.top"
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "mt-8 mx-n2"
  }, [_c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "크기(가로)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.popup.width,
      callback: function ($$v) {
        _vm.$set(_vm.popup, "width", $$v);
      },
      expression: "popup.width"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "크기(세로)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.popup.height,
      callback: function ($$v) {
        _vm.$set(_vm.popup, "height", $$v);
      },
      expression: "popup.height"
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "mt-8 mx-n2"
  }, [_c('v-flex', {
    staticClass: "px-2"
  }, [_vm._v("전시여부 (숨김/보임)")]), _c('v-spacer'), _c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "shrink": ""
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 px-2",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.popup.display.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.popup.display, "enabled", $$v);
      },
      expression: "popup.display.enabled"
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "mt-4 mx-n2"
  }, [_c('v-flex', {
    staticClass: "px-2"
  }, [_vm._v("전시기간")]), _c('v-spacer'), _c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "shrink": ""
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 px-2",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.popup.display.period,
      callback: function ($$v) {
        _vm.$set(_vm.popup.display, "period", $$v);
      },
      expression: "popup.display.period"
    }
  })], 1)], 1), _vm.popup.display.period ? _c('v-layout', {
    staticClass: "mt-8 mx-n2"
  }, [_c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-menu', {
    ref: "startDateMenu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.popup.display.startDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.popup.display, "startDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.popup.display, "startDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "전시일자",
            "persistent-placeholder": "",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.popup.display.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.popup.display, "startDate", $$v);
            },
            expression: "popup.display.startDate"
          }
        }, on))];
      }
    }], null, false, 902231156)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.startDateMenu.save(_vm.popup.display.startDate);
      }
    },
    model: {
      value: _vm.popup.display.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.popup.display, "startDate", $$v);
      },
      expression: "popup.display.startDate"
    }
  })], 1)], 1), _c('v-flex', {
    staticClass: "px-2",
    attrs: {
      "xs6": ""
    }
  }, [_c('v-menu', {
    ref: "endDateMenu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.popup.display.endDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.popup.display, "endDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.popup.display, "endDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "전시일자",
            "persistent-placeholder": "",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.popup.display.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.popup.display, "endDate", $$v);
            },
            expression: "popup.display.endDate"
          }
        }, on))];
      }
    }], null, false, 2018672539)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.endDateMenu.save(_vm.popup.display.endDate);
      }
    },
    model: {
      value: _vm.popup.display.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.popup.display, "endDate", $$v);
      },
      expression: "popup.display.endDate"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-layout', {
    staticClass: "mt-6"
  }, [_c('v-flex', [_c('naver-smarteditor', {
    model: {
      value: _vm.popup.content,
      callback: function ($$v) {
        _vm.$set(_vm.popup, "content", $$v);
      },
      expression: "popup.content"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }