<template>
    <console-content max-width="1024">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span>게시판 설정</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mt-4">
            <v-card-title class="subtitle-2">기본정보</v-card-title>
            <v-card-text>
                <v-layout class="mx-n2">
                    <v-flex class="px-2"><v-text-field v-model="config.name" label="게시판명칭" persistent-placeholder hide-details /></v-flex>
                    <v-flex class="px-2"><v-text-field v-model="config.code" label="게시판코드" persistent-placeholder hide-details /></v-flex>
                </v-layout>
            </v-card-text>
        </v-card>

        <v-card class="mt-4">
            <v-card-title class="subtitle-2">필터링 설정</v-card-title>
            <v-card-text>
                <v-textarea v-model="config.swearWord" label="욕설·비방글 필터링" persistent-placeholder outlined hide-details rows="3" />
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            config: {
                _id: this.$route.params._config
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.config._id){
                let { config } = await api.console.board.configs.get(this.config);
                this.config = config;
            }
        },
        async save(){
            this.config._id ? await api.console.board.configs.put(this.config) : await api.console.board.configs.post(this.config);
            alert("저장되었습니다");
            this.$router.go(-1);
        }
    }
}
</script>