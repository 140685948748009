<template>
    <v-card v-if="siteInfo?.orderWithSalesSum">
        <v-row class="ma-0 text-center">
            <v-col cols="auto" class="pa-2 caption font-weight-bold grey lighten-4">총매출액</v-col>
            <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col>
            <v-col class="pa-1 subtitle-1 font-weight-bold primary--text text--lighten-1">{{ saleAmountSum?.format?.() || 0 }}</v-col>
        </v-row>
    </v-card>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
export default {
    data: () => ({
        saleAmountSum: 0,
    }),
    computed: {
        ...mapState(["siteInfo"]),
        params() {
            return { ...this.$route.query };
        },
    },
    mounted() {
        if (this.siteInfo.orderWithSalesSum) this.getSum();
    },
    watch: {
        params() {
            this.getSum();
        },
    },
    methods: {
        async getSum() {
            let { params } = this;
            let { summary } = (await api.console.gshop.purchases.getSum({ params })) || {};
            this.saleAmountSum = summary.saleAmountSum;
        },
    },
};
</script>

<style></style>
