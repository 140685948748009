<template>
    
</template>

<script>
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent,
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {}
        }
    }
}
</script>