<template>
    <console-content max-width="1280">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">배너 관리</v-col>
            <v-spacer/>
        </v-row>
        
        <v-row class="mt-4 px-1" align="end">
            <v-col cols="auto" class="subtitle-2">검색수 : {{ summary.totalCount.format() }}</v-col>
            <v-spacer />
            <v-col cols="auto">
            </v-col>
        </v-row>

        <v-data-table :items="banners" :headers="bannersHeaders" hide-default-footer disable-sort disable-pagination class="mt-2 mx-1 elevation-1">
            <template slot="item.actions" slot-scope="{item}">
                <v-icon small @click="edit(item)" class="mx-1">mdi-pencil</v-icon>
                <v-icon small @click="remove(item)" class="mx-1">mdi-delete</v-icon>
            </template>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

        <v-btn fab fixed bottom right color="accent" @click="create"><v-icon>mdi-pencil</v-icon></v-btn>

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            banners: [],
            bannersHeaders: [
                { text: "코드", value: "code", align: "center", /* width: 10 */ },
                { text: "이름", value: "name", align: "center", /* width: 10 */ },
                { text: "actions", value: "actions", align: "center", /* width: 10 */ },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, banners } = await api.console.common.banners.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.banners = banners;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        create(){
            this.$router.push(`/console/banners/create`);
        },
        edit(banner){
            this.$router.push(`/console/banners/${banner._id}`);
        },
        async remove(banner){
            if(confirm("삭제하시겠습니까?")){
                await api.console.common.banners.delete(banner);
                this.search(false);
            }
        },
        
        async excel(){
            let { banners } = await api.console.common.banners.gets({ headers: { limit: 0 }, params: this.filter });

            let rows = [];
            banners.forEach(banner => {
                rows.push({
                    "no": banner.no,
                    "이름": banner.name,
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "엑셀시트이름");
            XLSX.writeFile(workbook, "엑셀파일이름.xlsx");
        }
    }
}
</script>