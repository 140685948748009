<template>
    <v-dialog v-model="show" max-width="800">
        <v-card>
            <v-responsive max-height="70vh" class="overflow-auto">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>상품권</th>
                            <th class="text-center">권종</th>
                            <th class="text-center">-</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="giftcard, index in giftcards">
                            <tr :key="index">
                                <td>
                                    <div class="d-flex align-center justify-start">
                                        <v-img :src="giftcard.thumb" max-width="96" height="96" contain class="d-inline-block mr-4"/>
                                        <div>{{ giftcard.name }}</div>
                                    </div>
                                </td>
                                <td class="text-center">{{ giftcard.faceamt?.format?.() }}</td>
                                <td class="text-center">
                                    <v-btn color="primary" @click="select(giftcard)">옵션 등록</v-btn>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </v-simple-table>
            </v-responsive>
            <v-divider />
            <v-card-actions>
                <v-btn text color="grey" class="ml-auto" @click="close">닫기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
export default {
    data(){
        return {
            show: false,

            giftcards: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { summary, giftcards } = await api.plugins.nanuri.giftcard.gets();

            this.giftcards = giftcards;
        },
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        select(giftcard){
            this.$emit("select", giftcard);
        }
    }
}
</script>