<template>
    <v-data-table v-model="selected" v-bind="{ ...$attrs, headers, items }" item-key="id" show-select single-select disable-sort disable-filtering disable-pagination hide-default-footer :items-per-page="-1">
        <template #[`item.term`]="{ item, value }">
            <v-edit-dialog>
                <div>{{ value || "-" }}</div>
                <template #input>
                    <v-select v-if="terms.length" v-model="item.term" :items="terms" v-bind="inputAttrs" class="my-3" @input="emit" />
                    <v-text-field v-else v-model="item.term" v-bind="inputAttrs" class="my-3" @input="emit" />
                </template>
            </v-edit-dialog>
        </template>
        <template #[`item.remove`]="{ item }">
            <v-btn fab text small color="red" @click="remove(item)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </template>
        <template #[`item.desc`]="{ item, value }">
            <v-edit-dialog>
                <div>{{ value || "-" }}</div>
                <template #input>
                    <v-text-field v-model="item.desc" v-bind="inputAttrs" class="my-3" @input="emit" />
                </template>
            </v-edit-dialog>
        </template>
        <template #footer>
            <v-divider />
            <v-row class="ma-0">
                <v-col cols="auto" class="py-1 px-3">
                    <v-btn fab text small width="32" :disabled="upDisabled" @click="upItem"> <v-icon>mdi-chevron-up</v-icon> </v-btn>
                    <v-btn fab text small width="32" :disabled="downDisabled" @click="downItem"> <v-icon>mdi-chevron-down</v-icon> </v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="py-1 px-4">
                    <v-btn fab text small color="primary" @click="add()"> <v-icon>mdi-plus</v-icon> </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
let inputAttrs = {
    dense: true,
    outlined: true,
    hideDetails: true,
    class: "pa-0 ma-0",
};

let headers = [
    { value: "term", text: "항목명", width: "25%" },
    { value: "desc", text: "항목상세" },
    { value: "remove", text: "", width: 72 },
];
export default {
    props: {
        value: { type: Array, default: () => [] },
        terms: { type: Array, default: () => [] },
        initItems: { type: Function, default: () => () => [] },
    },
    data: () => ({
        items: [],
        selected: [],

        headers,
        inputAttrs,
    }),
    computed: {
        upDisabled() {
            if ((this.items || []).length < 1 || !this.selected[0] || !this.items.some(({ id }) => id == this.selected[0]?.id)) return true;
            return this.selected?.[0]?.id == this.items?.[0]?.id;
        },
        downDisabled() {
            if ((this.items || []).length < 1 || !this.selected[0] || !this.items.some(({ id }) => id == this.selected[0]?.id)) return true;
            return [...(this.selected || [])]?.pop()?.id == [...(this.items || [])]?.pop()?.id;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
    },
    methods: {
        init() {
            if (this.value.length < 1) this.items = this.initItems();
            else this.items = this.value.map((item) => ({ id: Math.random(), ...item }));
        },
        emit() {
            this.$emit("input", this.items);
        },
        add() {
            this.items.push({ id: Math.random(), term: null, desc: null });
            this.emit();
        },
        remove({ id }) {
            this.items = this.items.filter((item) => item?.id !== id);
            this.emit();
        },
        upItem() {
            const index = (this.items || []).findIndex(({ id }) => id == this.selected[0]?.id);
            this.items.splice(index - 1, 2, this.items[index], this.items[index - 1]);
            this.emit();
        },
        downItem() {
            const index = (this.items || []).findIndex(({ id }) => id == this.selected[0]?.id);
            this.items.splice(index, 2, this.items[index + 1], this.items[index]);
            this.emit();
        },
    },
};
</script>

<style></style>
