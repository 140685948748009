var render = function render(){
  var _vm$siteInfo, _vm$saleAmountSum, _vm$saleAmountSum$for;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$siteInfo = _vm.siteInfo) !== null && _vm$siteInfo !== void 0 && _vm$siteInfo.orderWithSalesSum ? _c('v-card', [_c('v-row', {
    staticClass: "ma-0 text-center"
  }, [_c('v-col', {
    staticClass: "pa-2 caption font-weight-bold grey lighten-4",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("총매출액")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-1 subtitle-1 font-weight-bold primary--text text--lighten-1"
  }, [_vm._v(_vm._s(((_vm$saleAmountSum = _vm.saleAmountSum) === null || _vm$saleAmountSum === void 0 ? void 0 : (_vm$saleAmountSum$for = _vm$saleAmountSum.format) === null || _vm$saleAmountSum$for === void 0 ? void 0 : _vm$saleAmountSum$for.call(_vm$saleAmountSum)) || 0))])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }