<template>
    <v-dialog v-model="show" max-width="320">
        <template v-slot:activator="{ on }">
            <v-btn fab fixed bottom right color="primary" @click="open()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="subtitle-2">
                <b>엑셀 옵션 등록</b>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="option.name" label="옵션이름" dense outlined hide-details />
                <v-text-field v-model="option.faceamt" label="액면가" type="number" dense outlined hide-details class="mt-2" />
                <v-text-field v-model="option.salePrice" label="판매가" type="number" dense outlined hide-details class="mt-2" />
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" text class="ml-auto" @click="close()">취소</v-btn>
                <v-btn color="primary" text class="ml-2" @click="confirm()">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            show: false,

            option: {
                name: null,
                faceamt: null,
                salePrice: null,
            }
        }
    },
    methods: {
        open(){
            this.option = {
                name: null,
                faceamt: null,
                salePrice: null
            };

            this.show = true;
        },
        close(){
            this.show = false;
        },
        confirm(){
            this.$emit("confirm", this.option);
            this.close();
        }
    },
    computed: {
        scope(){
            return this.$store.state.scope || [];
        },
    }
}
</script>