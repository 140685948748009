<template>
    <console-content max-width="1024">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="banner._id">배너 상세</span>
                <span v-else>배너 등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-row class="mt-3">
            <v-col cols="12" class="pr-md-1">
                <v-card class="mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="banner.name" label="명칭" persistent-placeholder hide-details />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="banner.code" label="코드" persistent-placeholder hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">슬라이드</v-card-title>
            <v-card-text v-show="banner.slides.length">
                <vue-draggable v-model="banner.slides">
                    <template v-for="(slide, index) in banner.slides">
                        <v-layout :key="index" align-center class="grey lighten-5" :class="{'mt-4': 0 < index}" style="padding-right: 50px">
                            <v-flex grow class="pa-2">
                                <v-layout column wrap>
                                    <v-image-field v-model="slide.image" width="100%" height="100"/>
                                    <v-text-field v-model="slide.url" placeholder="link" dense outlined hide-details class="mt-2">
                                        <v-icon slot="prepend-inner" size="16" class="mt-1 mr-2">{{ mdiLinkVariant }}</v-icon>
                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                            <v-flex shrink class="pa-2">
                                <v-icon size="20" class="mx-1" @click="remove(slide, index)">mdi-delete</v-icon>
                            </v-flex>
                        </v-layout>
                    </template>
                </vue-draggable>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-dragdrop-field width="100%" height="100" multiple @input="upload"/>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="show" max-width="500">
            <v-card>
                <v-card-title>
                    <span class="subtitle-1">URL</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="slide.url" label="클릭시 페이지 이동" persistent-hint outlined clearable hide-details />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="primary" @click="show = false">확인</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import { mdiLinkVariant } from "@mdi/js";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import VImageField from "@/components/plugins/vuetify/v-image-field.vue";
import VDragdropField from "@/components/plugins/vuetify/v-dragdrop-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import VueDraggable from "vuedraggable";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor,
        VueDraggable,
        VImageField,
        VDragdropField
    },
    data(){
        return {
            mdiLinkVariant,

            show: false,

            slide: {},
            banner: {
                _id: this.$route.params._banner,

                name: null,
                code: null,

                slides: []
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.banner._id){
                let { banner } = await api.console.common.banners.get(this.banner);
                this.banner = banner;

                for(let slide of banner.slides){
                    if(slide.image) slide.image = await api.getResource(slide.image);
                }
            }
        },
        edit(slide){
            this.slide = slide;
            this.show = true;
        },
        remove(slide, index){
            this.banner.slides.splice(index, 1);
        },
        upload(files){
            console.log(files);
            if(!files?.length) files = [files];
            files.forEach(file => {
                this.banner.slides.push({
                    image: file,
                    url: ""
                });
            })
        },
        async save(){

            let { banner } = this.banner._id 
            ? await api.console.common.banners.put({
                ...this.banner,
                slides: this.banner.slides.map(slide => ({ image: null, url: slide.url }))
            }) 
            : await api.console.common.banners.post({
                ...this.banner,
                slides: this.banner.slides.map(slide => ({ image: null, url: slide.url }))
            });

            for(let slide of this.banner.slides){
                if(slide.image) await api.console.common.banners.images.post(banner._id, slide.image, this.banner.slides.indexOf(slide));
            }

            alert("저장되었습니다");
            this.$router.go(-1);
        }
    }
}
</script>