<template>
    <console-content max-width="800">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">메타 정보</v-col>
            <v-spacer />
        </v-row>

        <v-row class="mx-n2 my-2">
            <v-col cols="12" md="5" class="pa-2">
                <v-card>
                    <div class="pa-3 subtitle-2 font-weight-bold grey lighten-5">메타 이미지</div>
                    <v-divider />
                    <v-card-text>
                        <v-responsive :aspect-ratio="1 / 1">
                            <v-image-field v-model="setting.metaImage" class="fill-height grey lighten-2" :aspect-ratio="1 / 1" />
                        </v-responsive>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="7" class="pa-2">
                <v-card class="fill-height">
                    <div class="pa-3 subtitle-2 font-weight-bold grey lighten-5">메타 데이터</div>
                    <v-divider />
                    <term-desc-table v-model="setting.metaInfo" v-bind="{ initItems, terms }" />
                </v-card>
            </v-col>
        </v-row>

        <v-layout justify-end class="mt-4"> <v-btn color="primary" @click="save">저장하기</v-btn> </v-layout>
    </console-content>
</template>

<script>
import api from "@/api";
import TermDescTable from "@/components/console/dumb/term-desc-table.vue";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import VImageField from "@/components/plugins/vuetify/v-image-field.vue";

let initItems = () => [
    { id: Math.random(), term: "og:url", desc: null },
    { id: Math.random(), term: "og:type", desc: null },
    { id: Math.random(), term: "og:title", desc: null },
    { id: Math.random(), term: "og:description", desc: null },
];
let terms = ["og:type", "og:url", "og:title", "og:image", "og:description", "og:site_name", "og:locale", "og:image:width", "og:image:height"];

export default {
    components: {
        TermDescTable,
        ConsoleContent,
        VImageField,
    },
    created() {
        this.init();
    },
    data() {
        return {
            setting: {
                metaImage: null,
                metaInfo: [],
            },
            initItems,
            terms,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            var { setting } = await api.console.common.setting.get();
            if (setting?.metaImage) setting.metaImage = await api.getResource(setting.metaImage);
            this.setting = setting;
        },

        async save() {
            let { _id, metaInfo, metaImage } = this.setting;
            if (metaImage) await api.console.common.setting.metaImage.post({ metaImage });
            else await api.console.common.setting.metaImage.delete();
            await api.console.common.setting.put({ _id, metaInfo });
            alert("저장되었습니다.");
        },
    },
};
</script>
