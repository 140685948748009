<template>
    <v-menu ref="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y max-width="290px" min-width="auto">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="date" :label="label" readonly outlined dense hide-details v-bind="attrs" v-on="on" class="shrink" style="width: 160px">
                <template v-slot:prepend-inner>
                    <v-icon @click.prevent="date = $dayjs(date).add(-1, 'month').format('YYYY-MM')">mdi-chevron-left</v-icon>
                </template>
                <template v-slot:append>
                    <v-icon @click.prevent="date = $dayjs(date).add(1, 'month').format('YYYY-MM')">mdi-chevron-right</v-icon>
                </template>
            </v-text-field>
        </template>
        <v-date-picker v-model="date" type="month" no-title scrollable @input="value => $refs.menu.save(value)" />
    </v-menu>
</template>

<script>
export default {
    props: {
        value: { type: String, default: null },
        label: { type: String, default: null },
    },
    data(){
        return {
            date: this.$props.value || null,
            menu: null
        }
    },
    watch: {
        value(){
            this.date = this.value;
        },
        date(){
            this.$emit("input", this.date);
        }
    }
}
</script>

<style scoped>
* >>> input {
    text-align: center !important;
}
</style>