<template>
    <console-content max-width="1024">

        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="py-0 headline text-start">
                <span v-if="question._id">문의상세</span>
                <span v-else>문의등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-row class="mt-3">
            <v-col cols="12">
                <v-card class="mx-1">
                    <v-card-title class="subtitle-2 font-weight-bold">기본정보</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6" class="py-0">
                                <v-text-field v-model="question.subject" label="제목" placeholder=" " hide-details />
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                                <v-text-field :value="$dayjs(question.createdAt).format('YYYY-MM-DD HH:mm:ss')" label="문의일자" placeholder=" " hide-details readonly/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">문의내용</v-card-title>
            <v-card-text>
                <v-textarea v-model="question.content" outlined hide-details />
            </v-card-text>
        </v-card>

        <v-card class="mt-4 mx-1">
            <v-card-title class="subtitle-2 font-weight-bold">답변</v-card-title>
            <v-card-text>
                <v-textarea v-model="question.answer" outlined hide-details />
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            question: {
                _id: this.$route.params._question
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            if(this.question._id){
                let { question } = await api.console.center.questions.get(this.question);
                this.question = question;
            }
        },
        async save(){
            this.question._id ? await api.console.center.questions.put(this.question) : await api.console.center.questions.post(this.question);
            alert("저장되었습니다");
            this.$router.go(-1);
        }
    }
}
</script>