var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.banner._id ? _c('span', [_vm._v("배너 상세")]) : _c('span', [_vm._v("배너 등록")])]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "pr-md-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "명칭",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.banner.name,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "name", $$v);
      },
      expression: "banner.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "코드",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.banner.code,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "code", $$v);
      },
      expression: "banner.code"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("슬라이드")]), _c('v-card-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.banner.slides.length,
      expression: "banner.slides.length"
    }]
  }, [_c('vue-draggable', {
    model: {
      value: _vm.banner.slides,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "slides", $$v);
      },
      expression: "banner.slides"
    }
  }, [_vm._l(_vm.banner.slides, function (slide, index) {
    return [_c('v-layout', {
      key: index,
      staticClass: "grey lighten-5",
      class: {
        'mt-4': 0 < index
      },
      staticStyle: {
        "padding-right": "50px"
      },
      attrs: {
        "align-center": ""
      }
    }, [_c('v-flex', {
      staticClass: "pa-2",
      attrs: {
        "grow": ""
      }
    }, [_c('v-layout', {
      attrs: {
        "column": "",
        "wrap": ""
      }
    }, [_c('v-image-field', {
      attrs: {
        "width": "100%",
        "height": "100"
      },
      model: {
        value: slide.image,
        callback: function ($$v) {
          _vm.$set(slide, "image", $$v);
        },
        expression: "slide.image"
      }
    }), _c('v-text-field', {
      staticClass: "mt-2",
      attrs: {
        "placeholder": "link",
        "dense": "",
        "outlined": "",
        "hide-details": ""
      },
      model: {
        value: slide.url,
        callback: function ($$v) {
          _vm.$set(slide, "url", $$v);
        },
        expression: "slide.url"
      }
    }, [_c('v-icon', {
      staticClass: "mt-1 mr-2",
      attrs: {
        "slot": "prepend-inner",
        "size": "16"
      },
      slot: "prepend-inner"
    }, [_vm._v(_vm._s(_vm.mdiLinkVariant))])], 1)], 1)], 1), _c('v-flex', {
      staticClass: "pa-2",
      attrs: {
        "shrink": ""
      }
    }, [_c('v-icon', {
      staticClass: "mx-1",
      attrs: {
        "size": "20"
      },
      on: {
        "click": function ($event) {
          return _vm.remove(slide, index);
        }
      }
    }, [_vm._v("mdi-delete")])], 1)], 1)];
  })], 2)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-dragdrop-field', {
    attrs: {
      "width": "100%",
      "height": "100",
      "multiple": ""
    },
    on: {
      "input": _vm.upload
    }
  })], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("URL")])]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "클릭시 페이지 이동",
      "persistent-hint": "",
      "outlined": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.slide.url,
      callback: function ($$v) {
        _vm.$set(_vm.slide, "url", $$v);
      },
      expression: "slide.url"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.show = false;
      }
    }
  }, [_vm._v("확인")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }