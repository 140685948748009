var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1720"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("발송번호 수집동의 목록")]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mt-4 px-1",
    attrs: {
      "align": "end"
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "green--text px-2 ml-1",
    attrs: {
      "color": "white"
    },
    on: {
      "click": _vm.excel
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.mdiMicrosoftExcel))]), _vm._v("엑셀다운로드 ")], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "mt-2 mx-1 elevation-1",
    attrs: {
      "items": _vm.collection,
      "item-key": "_id",
      "headers": _vm.collectionHeaders,
      "hide-default-footer": "",
      "disable-pagination": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: "item.createdAt",
      fn: function (_ref) {
        var item = _ref.item;
        return _c('span', {}, [_vm._v(_vm._s(_vm.$dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")))]);
      }
    }, {
      key: "item.pins",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.modal(item);
            }
          }
        }, [_vm._v(_vm._s(_vm.mdiTextBoxSearch))])];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('v-pagination', {
    staticClass: "mt-3",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }