<template>
    <console-content max-width="1720">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline text-start">발주/발송 관리</v-col>
            <v-spacer/>
            <v-col cols="auto">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>
        
        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mt-2 mx-1">
                <v-card-title class="subtitle-2"><b>검색필터</b></v-card-title>
                <v-card-text class="pb-0">
                    <v-row align="center">
                        <v-col cols="12" sm="6">
                            <v-date-field v-model="filter.createdAt[0]" label="주문일자" persistent-placeholder hide-details />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-date-field v-model="filter.createdAt[1]" label="주문일자" persistent-placeholder hide-details />
                        </v-col>
                    </v-row>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색조건" persistent-placeholder hide-details @input="filter.searchValue = null;" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details clearable :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true)" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text @click="page = 1; search(true)">
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>
        
        <v-row class="mt-4 px-1" align="end">
            <v-col cols="auto" class="subtitle-2">검색수 : {{ summary.totalCount.format() }}</v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn color="white" class="green--text px-2 ml-1" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table v-model="selected" :items="purchases" item-key="_id" :headers="purchasesHeaders" show-select hide-default-footer disable-pagination disable-sort class="mt-2 mx-1 elevation-1">
            <span slot="item.quantity" slot-scope="{item}">{{ item.quantity.format() }}개</span>
            <v-layout slot="item.giftcard" slot-scope="{item}" align-center>
                <v-img :src="item.thumb?.path || item.thumb?.src" max-width="96" max-height="96" contain/>
                <div class="pl-4 text-start">
                    <div><b>{{ item.name }}</b></div>
                    <div>{{ item.salePrice.format() }}원</div>
                </div>
            </v-layout>
            <div slot="item.order.buyer" slot-scope="{item}">
                <div>{{ item.order.buyer.name }} ({{ item.order.buyer.username || "비회원" }})</div>
                <div>{{ item.order.buyer.phone }}</div>
                <div>{{ item.order.buyer.email }}</div>
            </div>
            <div slot="item.order.receiver" slot-scope="{item}">
                <div>{{ item.order.receiver.name }}</div>
                <div>{{ item.order.receiver.phone }}</div>
                <div>{{ item.order.receiver.email }}</div>
            </div>
            <div slot="item.order.shippingAddress" slot-scope="{item}">
                <div>[{{ item.order.shippingAddress.postcode }}]</div>
                <div>{{ item.order.shippingAddress.address1 }}</div>
                <div>{{ item.order.shippingAddress.address2 }}</div>
            </div>
            <div slot="item.shippingInfo" slot-scope="{item}">
                <div>{{ item.shippingInfo.method }}</div>
                <div>{{ item.shippingInfo.invoiceNumber }}</div>
                <div>{{ couriers.find(courier => courier.value == item.shippingInfo.courier)?.text }}</div>
            </div>
            <span slot="item.order.totalAmount" slot-scope="{item}">{{ item.order.totalAmount.format() }}원</span>
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <template slot="item.pins" slot-scope="{item}">
                <v-icon small @click="modal(item)">{{ mdiTextBoxSearch }}</v-icon>
            </template>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-4" @input="search(true)"/>

        <v-card class="mt-4 mx-1 mb-4">
            <v-card-text>
                <v-layout align-center>
                    <v-flex shrink>
                        <v-sheet width="100">발행승인</v-sheet>
                    </v-flex>
                    <v-flex>
                        <v-btn outlined color="primary" @click="issue()">발행승인</v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-text>
                <v-layout align-center>
                    <v-flex shrink>
                        <v-sheet width="100">취소처리</v-sheet>
                    </v-flex>
                    <v-flex>
                        <v-btn outlined color="primary" @click="cancel()">판매취소</v-btn>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>

        <pin-dialog ref="pinDialog" />

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import PinDialog from "@/components/console/gshop/purchase/pin-dialog.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import { mdiMicrosoftExcel, mdiTextBoxSearch } from "@mdi/js";
import couriers from "@/assets/data/couriers.json";

export default {
    components: {
        ConsoleContent,
        PinDialog,
        VDateField
    },
    data(){
        return {
            mdiMicrosoftExcel,
            mdiTextBoxSearch,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
                createdAt: [ this.$route.query.createdAt?.[0] || '', this.$route.query.createdAt?.[1] || '' ],
                status: this.$route.query.status ? [...this.$route.query.status.toString().split(",")] : ["미발행", "발행완료"],
                paymentStatus: "결제완료"
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            selected: [],

            summary: { totalCount: 0 },
            purchases: [],
            purchasesHeaders: [
                { text: "주문일자", value: "createdAt", align: "center", width: 120 },
                { text: "주문번호", value: "order.orderNo", align: "center", width: 100 },
                { text: "결제방법", value: "order.paymentMethod", align: "center", width: 100 },
                { text: "결제금액", value: "order.totalAmount", align: "center", width: 100 },
                { text: "구매자", value: "order.buyer", align: "center", width: 160 },
                { text: "수취인", value: "order.receiver", align: "center", width: 100 },
                { text: "구매번호", value: "purchaseNo", align: "center", width: 100 },
                { text: "구매상품", value: "giftcard", align: "center", width: 300 },
                { text: "구매수량", value: "quantity", align: "center", width: 80 },
                { text: "발행상태", value: "status", align: "center", width: 90 },
                { text: "결제상태", value: "paymentStatus", align: "center", width: 90 },
                { text: "PG", value: "order.paymentGateway", align: "center", width: 90 },
                { text: "핀 발행 내역", value: "pins", align: "center", width: 120 },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "주문번호", value: "order.orderNo" },
                { text: "구매번호", value: "purchaseNo" },
                { text: "구매자 이름", value: "order.buyer.name" },
                { text: "구매자 아이디", value: "order.buyer.username" },
                { text: "구매자 연락처", value: "order.buyer.phone" },
                { text: "구매자 이메일", value: "order.buyer.email" },
                { text: "수취인 이름", value: "order.receiver.name" },
                { text: "수취인 연락처", value: "order.receiver.phone" },
                { text: "수취인 이메일", value: "order.receiver.email" },
            ],

            couriers
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, purchases } = await api.console.gshop.purchases.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.purchases = purchases;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },

        modal(purchase){
            console.log(this.$refs.pinDialog);
            this.$refs.pinDialog.pins = purchase.pins;
            this.$refs.pinDialog.open();
        },

        async issue(){
            if(!this.selected.length){
                alert("구매내역을 선택해주세요");
                return;
            }
            if(confirm("발행승인 하시겠습니까?")){
                for(let purchase of this.selected){
                    await api.console.gshop.purchases.issue.post({
                        _id: purchase._id,
                    });
                }
                alert("발행승인 처리되었습니다");
                this.search(false);
            }
        },

        // 판매취소
        async cancel(){
            if(!this.selected.length){
                alert("구매내역을 선택해주세요");
                return;
            }
            if(confirm("판매취소 처리하시겠습니까?")){
                for(let purchase of this.selected){
                    await api.console.gshop.purchases.put({
                        _id: purchase._id,
                        status: "취소완료",
                        paymentStatus: "환불완료"
                    });
                }
                alert("판매취소 처리되었습니다");
                this.search(false);

                // 발송 문자 · 메일
                let orderMap = this.selected.reduce((orderMap, purchase) => {
                    orderMap.set(purchase._order, [...(orderMap.get(purchase._order) || []), purchase._id ])
                    return orderMap;
                }, new Map());

                for(let _order of orderMap.keys()){
                    api.console.common.receptions.send({
                        code: "cancel",
                        _order,
                        _purchases: orderMap.get(_order)
                    });
                }
            }
        },

        async excel(){
            let { purchases } = await api.console.gshop.purchases.gets({ headers: { limit: 0 }, params: this.filter });

            let rows = [];
            purchases.forEach(purchase => {
                rows.push({
                    "주문일자": this.$dayjs(purchase?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                    "주문번호": purchase?.order?.orderNo,
                    "결제방법": purchase?.order?.paymentMethod,
                    "결제금액": purchase?.order?.totalAmount?.format?.(),
                    "구매자 연락처": purchase?.order?.buyer?.phone,
                    "수취인 연락처": purchase?.order?.receiver?.phone,
                    "구매번호": purchase?.purchaseNo,
                    "구매상품": purchase?.giftcard?.name,
                    "구매수량": purchase?.quantity?.format?.() + "개",
                    "발행상태": purchase?.status,
                    "결제상태": purchase?.paymentStatus,
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "발주_발송내역");
            XLSX.writeFile(workbook, "발주_발송내역.xlsx");
        },
    }
}
</script>

<style scoped>
* >>> table > tbody > tr > td:nth-child(1),
* >>> table > thead > tr > th:nth-child(1)
{
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 1;
    background: white;
  }
* >>> table > thead > tr > th:nth-child(1),
* >>> table > thead > tr > th:nth-child(2) {
    z-index: 1;
  }
</style>