var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "width": "100%",
      "max-width": "1200"
    }
  }, [_c('v-row', {
    staticClass: "mt-md-4 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "subtitle-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', [_vm.reception._id ? _c('span', [_vm._v("문자 / 알림톡 / 메일링 수정")]) : _c('span', [_vm._v("문자 / 알림톡 / 메일링 등록")])])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-layout', {
    staticClass: "mx-n4",
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "px-4",
    attrs: {
      "xs12": "",
      "md8": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "명칭",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.reception.name,
      callback: function ($$v) {
        _vm.$set(_vm.reception, "name", $$v);
      },
      expression: "reception.name"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-4",
    attrs: {
      "xs12": "",
      "md4": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "코드",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.reception.code,
      callback: function ($$v) {
        _vm.$set(_vm.reception, "code", $$v);
      },
      expression: "reception.code"
    }
  })], 1)], 1)], 1)], 1), _c('v-layout', {
    staticClass: "mx-n1",
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "px-1",
    attrs: {
      "xs12": "",
      "md8": ""
    }
  }, [_c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("문자 / 알림톡")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "rows": "10"
    },
    model: {
      value: _vm.reception.message.content,
      callback: function ($$v) {
        _vm.$set(_vm.reception.message, "content", $$v);
      },
      expression: "reception.message.content"
    }
  })], 1)], 1)], 1), _c('v-flex', {
    staticClass: "px-1",
    attrs: {
      "xs12": "",
      "md4": ""
    }
  }, [_c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("고객수신")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.reception.message.memberEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.reception.message, "memberEnabled", $$v);
      },
      expression: "reception.message.memberEnabled"
    }
  })], 1), _c('v-divider'), _c('v-card-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.reception.message.memberEnabled,
      expression: "reception.message.memberEnabled"
    }]
  }, [_vm._l(_vm.reception.message.memberPhones, function (_, index) {
    return _c('v-layout', {
      key: index,
      staticClass: "mb-4"
    }, [_c('v-flex', {
      staticClass: "pr-2"
    }, [_c('v-text-field', {
      attrs: {
        "label": `사용자 연락처 ${index + 1}`,
        "outlined": "",
        "dense": "",
        "hide-details": ""
      },
      model: {
        value: _vm.reception.message.memberPhones[index],
        callback: function ($$v) {
          _vm.$set(_vm.reception.message.memberPhones, index, $$v);
        },
        expression: "reception.message.memberPhones[index]"
      }
    })], 1), _c('v-flex', {
      attrs: {
        "shrink": ""
      }
    }, [_c('v-btn', {
      attrs: {
        "width": "40",
        "height": "40",
        "min-width": "auto",
        "color": "error",
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.reception.message.memberPhones.splice(index, 1);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)], 1)], 1);
  }), _c('v-btn', {
    staticStyle: {
      "min-width": "auto"
    },
    attrs: {
      "width": "100%",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.reception.message.memberPhones.push('');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("사용자 연락처 추가")], 1)], 2)], 1), _c('v-card', {
    staticClass: "mt-2 mx-1"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("관리자 수신")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.reception.message.adminEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.reception.message, "adminEnabled", $$v);
      },
      expression: "reception.message.adminEnabled"
    }
  })], 1), _c('v-divider'), _c('v-card-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.reception.message.adminEnabled,
      expression: "reception.message.adminEnabled"
    }]
  }, [_vm._l(_vm.reception.message.adminPhones, function (_, index) {
    return _c('v-layout', {
      key: index,
      staticClass: "mb-4"
    }, [_c('v-flex', {
      staticClass: "pr-2"
    }, [_c('v-text-field', {
      attrs: {
        "label": `관리자 연락처 ${index + 1}`,
        "outlined": "",
        "dense": "",
        "hide-details": ""
      },
      model: {
        value: _vm.reception.message.adminPhones[index],
        callback: function ($$v) {
          _vm.$set(_vm.reception.message.adminPhones, index, $$v);
        },
        expression: "reception.message.adminPhones[index]"
      }
    })], 1), _c('v-flex', {
      attrs: {
        "shrink": ""
      }
    }, [_c('v-btn', {
      attrs: {
        "width": "40",
        "height": "40",
        "min-width": "auto",
        "color": "error",
        "outlined": ""
      },
      on: {
        "click": function ($event) {
          return _vm.reception.message.adminPhones.splice(index, 1);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)], 1)], 1);
  }), _c('v-btn', {
    staticStyle: {
      "min-width": "auto"
    },
    attrs: {
      "width": "100%",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.reception.message.adminPhones.push('');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v("관리자 연락처 추가")], 1)], 2)], 1), _c('v-card', {
    staticClass: "mt-2 mx-1"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("카카오 알림톡")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.reception.message.kakaoEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.reception.message, "kakaoEnabled", $$v);
      },
      expression: "reception.message.kakaoEnabled"
    }
  })], 1), _c('v-divider'), _c('v-card-title', [_c('v-text-field', {
    attrs: {
      "label": "카카오 템플릿 코드",
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.reception.message.kakaoTemplateCode,
      callback: function ($$v) {
        _vm.$set(_vm.reception.message, "kakaoTemplateCode", $$v);
      },
      expression: "reception.message.kakaoTemplateCode"
    }
  })], 1)], 1)], 1)], 1), _c('v-layout', {
    staticClass: "mx-n1",
    attrs: {
      "wrap": ""
    }
  }, [_c('v-flex', {
    staticClass: "px-1",
    attrs: {
      "xs12": "",
      "md8": ""
    }
  }, [_c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("이메일")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "placeholder": "메일 제목",
      "hide-details": ""
    },
    model: {
      value: _vm.reception.email.subject,
      callback: function ($$v) {
        _vm.$set(_vm.reception.email, "subject", $$v);
      },
      expression: "reception.email.subject"
    }
  }), _c('naver-smarteditor', {
    staticClass: "mt-4",
    model: {
      value: _vm.reception.email.content,
      callback: function ($$v) {
        _vm.$set(_vm.reception.email, "content", $$v);
      },
      expression: "reception.email.content"
    }
  })], 1)], 1)], 1), _c('v-flex', {
    staticClass: "px-1",
    attrs: {
      "xs12": "",
      "md4": ""
    }
  }, [_c('v-card', {
    staticClass: "mt-4 mx-1"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("고객수신")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.reception.email.memberEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.reception.email, "memberEnabled", $$v);
      },
      expression: "reception.email.memberEnabled"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-2 mx-1"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("관리자수신")]), _c('v-spacer'), _c('v-switch', {
    staticClass: "ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.reception.email.adminEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.reception.email, "adminEnabled", $$v);
      },
      expression: "reception.email.adminEnabled"
    }
  })], 1), _c('v-divider'), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.reception.email.adminEnabled,
      expression: "reception.email.adminEnabled"
    }]
  }, [_vm._l(_vm.adminEmailCount, function (i, index) {
    return _c('v-flex', {
      key: index,
      staticClass: "px-1"
    }, [_c('v-text-field', {
      attrs: {
        "label": `관리자${i}`,
        "outlined": "",
        "dense": ""
      },
      model: {
        value: _vm.reception.email.adminEmails[index],
        callback: function ($$v) {
          _vm.$set(_vm.reception.email.adminEmails, index, $$v);
        },
        expression: "reception.email.adminEmails[index]"
      }
    })], 1);
  }), _vm.adminEmailCount < 5 ? _c('v-flex', {
    staticClass: "px-1",
    attrs: {
      "xs2": ""
    }
  }, [_c('v-btn', {
    staticClass: "px-1",
    staticStyle: {
      "min-width": "auto"
    },
    attrs: {
      "width": "36",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.adminEmailCount++;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1) : _vm._e(), _vm.adminEmailCount > 1 ? _c('v-flex', {
    staticClass: "px-1",
    attrs: {
      "xs2": ""
    }
  }, [_c('v-btn', {
    staticClass: "px-1",
    staticStyle: {
      "min-width": "auto"
    },
    attrs: {
      "width": "36",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.adminEmailCount--;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)], 1) : _vm._e()], 2)], 1)], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }