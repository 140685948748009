<template>
    <console-content max-width="1024">

        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">
                <span v-if="faq._id">FAQ 상세</span>
                <span v-else>FAQ 등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-row no-gutters class="mt-6">
            <v-col cols="8">
                <v-card class="mx-1">
                    <v-card-text>
                        <v-text-field v-model="faq.question" label="Q. 질문" persistent-placeholder hide-details />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="mx-1">
                    <v-card-text>
                        <v-text-field v-model="faq.no" label="순서" persistent-placeholder hide-details />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mt-4 mx-1">
            <v-card-text>
                <v-textarea v-model="faq.answer" label="A. 답변" rows="15" outlined persistent-placeholder hide-details />
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>

    </console-content>
</template>

<script>
import api from "@/api";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ConsoleContent,
        NaverSmarteditor
    },
    data(){
        return {
            faq: {
                _id: this.$route.params._faq,
                question:  "",
                answer: "",
                no: 0
            }
        }
    },
    async mounted(){
        await this.init();
    },
    methods: {
        async init(){
            if(this.faq._id){
                let { faq } = await api.console.center.faqs.get(this.faq);
                this.faq = faq;
            }
            else{
                let { summary } = await api.console.center.faqs.gets({ headers: { limit: 1 } });
                this.faq.no = summary.totalCount + 1;
            }
        },
        async save(){
            this.faq._id ? await api.console.center.faqs.put(this.faq) : await api.console.center.faqs.post(this.faq);
            alert("저장되었습니다");
            this.$router.go(-1);
        }
    }
}
</script>