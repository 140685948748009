var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('console-content', {
    attrs: {
      "max-width": "1024"
    }
  }, [_c('v-row', {
    staticClass: "px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("게시판 설정")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-layout', {
    staticClass: "mx-n2"
  }, [_c('v-flex', {
    staticClass: "px-2"
  }, [_c('v-text-field', {
    attrs: {
      "label": "게시판명칭",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.config.name,
      callback: function ($$v) {
        _vm.$set(_vm.config, "name", $$v);
      },
      expression: "config.name"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-2"
  }, [_c('v-text-field', {
    attrs: {
      "label": "게시판코드",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.config.code,
      callback: function ($$v) {
        _vm.$set(_vm.config, "code", $$v);
      },
      expression: "config.code"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("필터링 설정")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "label": "욕설·비방글 필터링",
      "persistent-placeholder": "",
      "outlined": "",
      "hide-details": "",
      "rows": "3"
    },
    model: {
      value: _vm.config.swearWord,
      callback: function ($$v) {
        _vm.$set(_vm.config, "swearWord", $$v);
      },
      expression: "config.swearWord"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }