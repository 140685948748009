var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', _vm._b({
    staticClass: "v-image-field",
    attrs: {
      "color": "transparent"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.input.$refs.input.click();
      }
    }
  }, 'v-sheet', _vm.$attrs, false), [_vm.image ? _c('v-img', _vm._b({
    attrs: {
      "src": _vm.createObjectURL(_vm.image),
      "contain": ""
    }
  }, 'v-img', _vm.$attrs, false)) : _vm._e(), _c('v-file-input', {
    ref: "input",
    staticClass: "d-none",
    attrs: {
      "accept": "image/*"
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }