<template>
<v-card tile elevation="0">

    <v-card-text class="pb-0">
        <v-layout>
            <v-flex xs12 sm2 class="pt-2">옵션입력</v-flex>
            <v-flex xs12 sm10>
                <v-layout align-center class="mx-n1">
                    <v-flex xs3 class="px-1 text-center">권종이름</v-flex>
                    <v-flex xs3 class="px-1 text-center">코드</v-flex>
                    <v-flex xs3 class="px-1 text-center">액면가</v-flex>
                    <v-flex xs3 class="px-1 text-center">판매가</v-flex>
                    <v-flex shrink class="px-1">
                        <v-sheet width="36" height="36">
                            <v-btn width="36" color="primary" class="px-1" style="min-width: auto" @click="insert()"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-sheet>
                    </v-flex>
                </v-layout>
                <v-divider class="mt-2"/>
                <v-layout v-for="(nanuriGiftcard, index) in nanuriGiftcards" :key="index" class="mt-2 mx-n1">
                    <v-flex xs3 class="px-1">
                        <v-text-field v-model="nanuriGiftcard.name" dense hide-details outlined :placeholder="placeholders[index]?.name || ''"></v-text-field>
                    </v-flex>
                    <v-flex xs3 class="px-1">
                        <v-text-field v-model="nanuriGiftcard.lv" dense hide-details outlined :placeholder="placeholders[index]?.lv || ''"></v-text-field>
                    </v-flex>
                    <v-flex xs3 class="px-1">
                        <v-text-field v-model="nanuriGiftcard.faceamt" dense hide-details outlined :placeholder="placeholders[index]?.faceamt || ''"></v-text-field>
                    </v-flex>
                    <v-flex xs3 class="px-1">
                        <v-text-field v-model="nanuriGiftcard.salePrice" dense hide-details outlined :placeholder="placeholders[index]?.salePrice || ''"></v-text-field>
                    </v-flex>
                    <v-flex shrink class="px-1">
                        <v-sheet width="36" height="36">
                            <v-btn color="white" class="px-1 mr-1 primary--text" width="36" height="36" style="min-width: auto" :disabled="nanuriGiftcards.length <= 1" @click="remove(nanuriGiftcard, index)"><v-icon>mdi-minus</v-icon></v-btn>
                        </v-sheet>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-card-text>

    <v-divider class="mt-4"></v-divider>

    <template>
        <v-tabs v-model="tabIndex">
            <v-tab>전체</v-tab>
            <v-tab>발행완료</v-tab>
            <v-tab>취소</v-tab>
        </v-tabs>

        <v-divider/>

        <v-tabs-items v-model="tabIndex" touchless>
            <v-tab-item>
                <v-data-table :items="pins" :headers="pinsHeaders">
                    <template slot="item.faceamt" slot-scope="{item}">{{ item.faceamt?.format?.() }}</template>
                    <template slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                    <template v-if="item.issuedAt" slot="item.issuedAt" slot-scope="{item}">{{ $dayjs(item.issuedAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item>
                <v-data-table :items="pins.filter(pin => pin.status == '발행완료')" :headers="pinsHeaders">
                    <template slot="item.faceamt" slot-scope="{item}">{{ item.faceamt?.format?.() }}</template>
                    <template slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                    <template v-if="item.issuedAt" slot="item.issuedAt" slot-scope="{item}">{{ $dayjs(item.issuedAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item>
                <v-data-table :items="pins.filter(pin => pin.status == '취소완료')" :headers="pinsHeaders">
                    <template slot="item.faceamt" slot-scope="{item}">{{ item.faceamt?.format?.() }}</template>
                    <template slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                    <template v-if="item.issuedAt" slot="item.issuedAt" slot-scope="{item}">{{ $dayjs(item.issuedAt).format("YYYY-MM-DD HH:mm:ss") }}</template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>
    </template>
</v-card>
</template>

<script>
export default {
    props: {
        value: { type: [Object, Array], default(){ return [] } },
        giftcard: { type: Object },
    },
    data() {
        return {
            nanuriGiftcards: this.$props.value || [],
            nanuriGiftcardsHeaders: [
                { text: "옵션값", align: "center", sortable: false, value: "optionalValues" },
                { text: "판매가", align: "center", width: 140, value: "salePrice" },
                { text: "재고수량", align: "center", width: 140, value: "stock" },
                { text: "actions", align: "center", width: 100, sortable: false, value: "actions" }
            ],

            placeholders: [
                { name: "ex) ○○○ 상품권 1만원관", lv: "ex) xm", faceamt: "ex) 10000",  value: "ex) 10000" },
                { name: "ex) ○○○ 상품권 3만원관", lv: "ex) sm", faceamt: "ex) 30000",  value: "ex) 30000" },
                { name: "ex) ○○○ 상품권 5만원관", lv: "ex) om", faceamt: "ex) 50000",  value: "ex) 50000" },
            ],
            
            pins: this.giftcard.pins,
            pinsHeaders: [
                { text: "상품권 이름", value: "name", align: "center", width: 200 },
                { text: "핀번호", value: "pno", align: "center", width: 200 },
                { text: "액면가", value: "faceamt", align: "center", width: 150 },
                { text: "상태", value: "status", align: "center", width: 150 },
                { text: "등록일자", value: "createdAt", align: "center", width: 200 },
                { text: "발행일자", value: "issuedAt", align: "center", width: 200 },
            ],

            tabIndex: 0
        };
    },
    methods: {
        remove(nanuriGiftcard, index){
            nanuriGiftcard._id ? this.$set(nanuriGiftcard, "isDeleted", true) : this.nanuriGiftcards.splice(index, 1);
        },

        insert(){
            this.nanuriGiftcards.push({
                name: "",
                type: "nanuri",
                platform: "nanuri",
                lv: "",
                salePrice: 0,
                faceamt: 0
            });
        },
    },
    watch: {
        value(){
            this.nanuriGiftcards = this.value || [];
        },
        nanuriGiftcards: {
            deep: true,
            handler(){ this.$emit("input", this.nanuriGiftcards) }
        },
        giftcard(){
            this.pins = this.giftcard.pins;
        }
    }
}
</script>

<style>
.shop-nanuriGiftcards .v-input.text-center input { text-align: center; }
</style>
