<template>
    <console-content max-width="1280">
        <v-row class="px-1" align="center">
            <v-col cols="auto" class="headline">팝업 목록</v-col>
            <v-spacer/>
            <v-col cols="auto"></v-col>
        </v-row>
        
        <v-row class="mt-2 px-1" align="end">
            <v-col cols="auto" class="subtitle-2">검색수 : {{ summary.totalCount.format() }}</v-col>
            <v-spacer />
            <v-col cols="auto"></v-col>
        </v-row>

        <v-data-table :items="popups" :headers="popupsHeaders" hide-default-footer disable-sort disable-pagination class="mt-3 mx-1 elevation-1">
            <template slot="item.actions" slot-scope="{item}">
                <v-icon small class="mx-1" @click="edit(item)">mdi-pencil</v-icon>
                <v-icon small class="mx-1" @click="remove(item)">mdi-delete</v-icon>
            </template>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

        <v-btn fab fixed bottom right color="accent" @click="$refs.popupDialog.open()"><v-icon>mdi-pencil</v-icon></v-btn>

        <popup-dialog ref="popupDialog" @close="search(false)" />

    </console-content>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import ConsoleContent from "@/templates/console/ConsoleContent.vue";
import PopupDialog from "@/components/console/popup/popup-dialog.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        ConsoleContent,
        PopupDialog
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            popups: [],
            popupsHeaders: [
                { text: "no", value: "no", align: "center", width: 100 },
                { text: "이름", value: "name", align: "center", /* width: 10 */ },
                { text: "URL", value: "url", align: "center", /* width: 10 */ },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, popups } = await api.console.common.popups.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.popups = popups;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        async edit(popup){
            this.$refs.popupDialog.popup = popup;
            this.$refs.popupDialog.open();
        },
        
        async remove(popup){
            if(confirm("삭제하시겠습니까?")) {
                await api.console.common.popups.delete(popup);
                await this.search();
            }
        },

        async excel(){
            let { popups } = await api.console.common.popups.gets({ headers: { limit: 0 }, params: this.filter });

            let rows = [];
            popups.forEach(popup => {
                rows.push({
                    "no": popup.no,
                    "이름": popup.name,
                });
            });

            let workbook = new XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(rows);

            XLSX.utils.book_append_sheet(workbook, worksheet, "엑셀시트이름");
            XLSX.writeFile(workbook, "엑셀파일이름.xlsx");
        }
    }
}
</script>