<template>
<v-responsive class="overflow-visible">
    <v-card class="mx-1">
        <v-card-title>
            <v-layout align-center>
                <span class="subtitle-2"><b>상품권 옵션 목록</b></span>
                <v-spacer/>
                <v-btn v-if="(scope.includes('root') || siteInfo.pinBtn) && siteInfo.nanuriGiftcardEnabled" color="secondary" small height="36" @click="add({ platform: `nanuri-giftcard` })" class="mr-2"><v-icon left>mdi-plus</v-icon>나누리 대행 옵션 추가</v-btn>
                <v-btn v-if="scope.includes('root') || siteInfo.pinBtn" color="primary" small height="36" @click="add({ platform: 'nanuri' })" class="mr-2"><v-icon left>mdi-plus</v-icon>나누리핀  옵션 추가</v-btn>
                <v-btn color="primary" small height="36" @click="add({ platform: 'excel' })" class="mr-2"><v-icon left>mdi-plus</v-icon>엑셀 옵션 추가</v-btn>
            </v-layout>
        </v-card-title>
        <v-divider/>
        <v-data-table :items="options.filter(option => !option.isDeleted)" :headers="optionsHeaders.filter(header => !header.scope || scope.includes(header.scope))">
            <v-text-field slot="item.name" slot-scope="{item, index}" v-model="item.name" dense hide-details outlined :placeholder="placeholders[index]?.name || ''"></v-text-field>
            <v-select slot="item.platform" slot-scope="{item, index}" v-model="item.platform" :items="[`excel`, `nanuri`, `nanuri-api`, `nanuri-excel`, `nanuri-giftcard`]" outlined hide-details dense :disabled="!scope.includes('root')" />
            <v-text-field slot="item.code" slot-scope="{item, index}" v-model="item.code" dense hide-details outlined :placeholder="placeholders[index]?.code || ''"></v-text-field>
            <v-text-field slot="item.faceamt" slot-scope="{item, index}" v-model="item.faceamt" dense hide-details outlined suffix="원" :placeholder="placeholders[index]?.faceamt || ''"></v-text-field>
            <v-text-field slot="item.salePrice" slot-scope="{item, index}" v-model="item.salePrice" dense hide-details outlined suffix="원" :placeholder="placeholders[index]?.salePrice || ''"></v-text-field>
            <span slot="item.commissionPrice" slot-scope="{item, index}">
                {{ (Math.floor(item.salePrice * giftcard.commission.mobile / (1000 * giftcard.commission.cutUnit)) * giftcard.commission.cutUnit * 10).format() }}원
            </span>

            <template slot="item.actions" slot-scope="{item, index}">
                <v-btn color="white" class="px-1 mr-1 primary--text" width="36" height="36" style="min-width: auto" @click="remove(options, item, index)"><v-icon>mdi-minus</v-icon></v-btn>
            </template>
        </v-data-table>
    </v-card>

    <v-card class="mx-1 mt-4" :disabled="loading">
        <v-card-title>
            <v-layout align-center>
                <span class="subtitle-2"><b>핀 누적 집계</b></span>
                <v-spacer/>
            </v-layout>
        </v-card-title>

        <v-divider />

        <v-simple-table dense>
            <tbody class="text-end">
                <tr>
                    <th class="text-end">전체</th>
                    <th class="text-end">미발행</th>
                    <th class="text-end">발행완료</th>
                    <th class="text-end">취소완료</th>
                </tr>
                <tr>
                    <td>{{ summaries.reduce((totalCount, summary) => totalCount + +summary.totalCount, 0)?.format?.() }}건</td>
                    <td>{{ summaries.reduce((totalCount, summary) => totalCount + (summary.status == "미발행" ? summary.totalCount : 0), 0)?.format?.() }}건</td>
                    <td>{{ summaries.reduce((totalCount, summary) => totalCount + (summary.status == "발행완료" ? summary.totalCount : 0), 0)?.format?.() }}건</td>
                    <td>{{ summaries.reduce((totalCount, summary) => totalCount + (summary.status == "취소완료" ? summary.totalCount : 0), 0)?.format?.() }}건</td>
                </tr>
                <tr>
                    <td>{{ summaries.reduce((totalAmount, summary) => totalAmount + summary.totalCount * summary.faceamt, 0)?.format?.() }}원</td>
                    <td>{{ summaries.reduce((totalAmount, summary) => totalAmount + (summary.status == "미발행" ? summary.totalCount * summary.faceamt : 0), 0)?.format?.() }}원</td>
                    <td>{{ summaries.reduce((totalAmount, summary) => totalAmount + (summary.status == "발행완료" ? summary.totalCount * summary.faceamt : 0), 0)?.format?.() }}원</td>
                    <td>{{ summaries.reduce((totalAmount, summary) => totalAmount + (summary.status == "취소완료" ? summary.totalCount * summary.faceamt : 0), 0)?.format?.() }}원</td>
                </tr>
                <tr></tr>
            </tbody>
        </v-simple-table>
    </v-card>

    <v-card class="mx-1 mt-4" :disabled="loading">
        <v-card-title>
            <v-layout align-center>
                <span class="subtitle-2"><b>핀 목록</b></span>
                <v-spacer/>
                <v-btn :disabled="!giftcard._id" color="green" outlined height="36" class="my-auto mr-2" :to="`/console/gshop/giftcards/${$route.params._giftcard}/stock`">
                    <v-icon left>{{ mdiMicrosoftExcel }}</v-icon>
                    <span>재고관리 페이지</span>
                </v-btn>
            </v-layout>
        </v-card-title>

        <v-divider/>

        <template v-if="!loading">
            <v-tabs v-model="tabIndex" height="56">
                <v-tab>미발행({{ idle?.totalCount?.format?.() || 0 }})</v-tab>
                <v-tab>발행({{ issued?.totalCount?.format?.() || 0 }})</v-tab>
                <v-tab>취소({{ canceled?.totalCount?.format?.() || 0 }})</v-tab>
                <v-spacer/>
                <div class="d-flex mt-2 pr-6">
                    <v-date-month v-if="0 < tabIndex" v-model="filter.date" :label="tabIndex == 1 ? '발행일자' : '취소일자'" />
                    <v-select v-model="filter.name" label="상품권 이름(필터)" :items="[].concat([{ text: ':: 전체 ::', value: null }], options.filter(option => !!option.name).map(option => ({ text: option.name, value: option.name })))" persistent-placeholder dense outlined hide-details class="ml-2" />
                </div>
            </v-tabs>

            <v-divider/>

            <v-tabs-items v-model="tabIndex" touchless>
                <v-tab-item>
                    <giftcard-pins :giftcard="giftcard" :filter="{ status: `미발행`, name: filter.name }" @search="summary => idle = summary" />
                </v-tab-item>
                <v-tab-item>
                    <giftcard-pins :giftcard="giftcard" :filter="{ status: `발행완료`, name: filter.name, issuedAt: filter.date }" @search="summary => issued = summary" />
                </v-tab-item>
                <v-tab-item>
                    <giftcard-pins :giftcard="giftcard" :filter="{ status: `취소완료`, name: filter.name, canceledAt: filter.date }" @search="summary => canceled = summary" />
                </v-tab-item>
            </v-tabs-items>
        </template>
        <template v-else>
            <v-layout align-center justify-center class="py-10">
                <v-progress-circular indeterminate color="primary" size="24" />
            </v-layout>
        </template>
    </v-card>
    
    <upload-dialog ref="uploadDialog" @input="upload" />
    <nanuri-giftcard-dialog v-if="siteInfo.nanuriGiftcardEnabled" ref="nanuriGiftcardDialog" />
</v-responsive>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";

import UploadDialog from "@/components/console/gshop/giftcards/upload-dialog.vue";
import GiftcardPins from "@/components/console/gshop/giftcards/giftcard-pins.vue";
import NanuriGiftcardDialog from '@/components/console/nanuri/nanuri-giftcard-dialog.vue';
import vDateMonth from '@/components/plugins/vuetify/v-date-month.vue';
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        UploadDialog,
        GiftcardPins,
        NanuriGiftcardDialog,
        vDateMonth
    },
    props: {
        value: { type: [Object, Array], default(){ return [] } },
        giftcard: { type: Object },
        thumb: { type: Object },
        images: { type: [Object, Array] },
    },
    data() {
        return {
            loading: false,
            mdiMicrosoftExcel,

            options: this.$props.value || [],
            optionsHeaders: [
                { text: "옵션이름", align: "center", sortable: false, value: "name" },
                { text: "플랫폼", align: "center", width: 140, value: "platform" },
                { text: "코드(api)", align: "center", width: 180, value: "code" },
                { text: "액면가", align: "center", width: 180, value: "faceamt" },
                { text: "판매가", align: "center", width: 180, value: "salePrice" },
                { text: "수수료[모바일](원)", align: "center", width: 180, value: "commissionPrice" },
                { text: "actions", align: "center", width: 50, sortable: false, value: "actions", scope: "root" }
            ],

            placeholders: [
                { name: "ex) ○○○ 상품권 1만원관", code: "ex) xm", faceamt: "ex) 10000",  value: "ex) 10000" },
                { name: "ex) ○○○ 상품권 3만원관", code: "ex) sm", faceamt: "ex) 30000",  value: "ex) 30000" },
                { name: "ex) ○○○ 상품권 5만원관", code: "ex) om", faceamt: "ex) 50000",  value: "ex) 50000" },
            ],
            
            filter: {
                menu: null,
                name: null,
                date: this.$dayjs().format("YYYY-MM"),
            },

            idle: { totalCount: 0 },
            issued: { totalCount: 0 },
            canceled: { totalCount: 0 },

            summaries: [],

            tabIndex: 0,
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search();
        },
        async search(status = ""){
            
            if(!this.giftcard._id) return;

            this.loading = true;

            let { summaries } = await api.console.gshop.giftcards.pins.summaries.gets(this.giftcard._id);

            this.summaries = summaries;
            this.idle.totalCount = summaries.reduce((totalCount, summary) => totalCount + (summary.status == "미발행" ? summary.totalCount : 0), 0);

            var { summary } = await api.console.gshop.giftcards.pins.gets(this.giftcard._id, {
                headers: { skip: 0, limit: 1 },
                params: { status: "발행완료", issuedAt: this.filter.date },
            });
            this.issued.totalCount = summary.totalCount;

            var { summary } = await api.console.gshop.giftcards.pins.gets(this.giftcard._id, {
                headers: { skip: 0, limit: 1 },
                params: { status: "취소완료", canceledAt: this.filter.date },
            });
            this.canceled.totalCount = summary.totalCount;

            this.loading = false;
            this.$forceUpdate();
        },

        remove(items, item, index){
            item._id ? this.$set(item, "isDeleted", true) : items.splice(index, 1);
        },

        add(option){
            switch(option.platform){
                case "nanuri-giftcard": {
                    this.$refs.nanuriGiftcardDialog.$off();
                    this.$refs.nanuriGiftcardDialog.$on("select", (giftcard) => {
                        this.options.push({
                            name: giftcard.name,
                            code: giftcard._id,
                            type: "option",
                            salePrice: giftcard.faceamt || 0,
                            faceamt: giftcard.faceamt || 0,
                            ...option
                        });

                        this.giftcard.name = this.giftcard.name || giftcard.name;

                        api.getResource(giftcard.thumb).then(file => {
                            if(!this.giftcard.thumb) this.$emit("update:thumb", file);
                            if(!this.giftcard.images[0]) this.$emit("update:images", file);
                        });
                    });
                    this.$refs.nanuriGiftcardDialog.open();
                    break;
                }

                default: {
                    this.options.push({
                        name: null,
                        type: "option",
                        salePrice: 0,
                        faceamt: 0,
                        ...option
                    });
                }
            }
        },

        upload(pins = []){
            for(let { name, pno, faceamt, status = "미발행" } of pins){
                if(!this.options.find(option => option.name == name && !option.isDeleted)){
                    this.options.push({ 
                        name,
                        type: "option",
                        platform: "excel",
                        stock: 0,
                        salePrice: faceamt,
                        faceamt
                    });
                }
                
                this.pins.unshift({
                    name,
                    pno,
                    platform: "excel",
                    faceamt,
                    status,
                    createdAt: Date.now()
                });
            }
        }
    },
    computed: {
        scope(){
            return this.$store.state.scope || [];
        },
        ...mapState(["siteInfo"]),
    },
    watch: {
        value(){
            this.options = this.value || [];
        },
        options: {
            deep: true,
            handler(){ this.$emit("input", this.options) }
        },
        giftcard(){
            this.pins = this.giftcard.pins;
        }
    }
}
</script>

<style>
.shop-options .v-input.text-center input { text-align: center; }
</style>
