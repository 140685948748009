<template>
    <console-layout width="100%" max-width="800">
        <console-title>
            <span>상품권별 추가 문자메세지</span>
        </console-title>

        <console-body>
                
            <v-data-table :items="messages" :headers="messagesHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template #[`item.no`]="{item}">
                    <span>{{ messages.indexOf(item) + 1 }}</span>
                </template>
                <template #[`item.giftcards`]="{item}">
                    <div>
                        <div v-for="giftcard in item.giftcards" :key="giftcard._id">
                            <span>{{ giftcard.name }}</span>
                        </div>
                    </div>
                </template>
                <template #[`item.endMessage`]="{item}">
                    <div class="d-block">
                        <div v-html="item.endMessage.replace(/\n/g, `<br />`)" />
                    </div>
                </template>
                <template #[`item.actions`]="{item}">
                    <span>
                        <v-icon size="20" class="mx-1" @click="edit(item)">mdi-pencil</v-icon>
                        <v-icon size="20" class="mx-1" @click="remove(item)">mdi-delete</v-icon>
                    </span>
                </template>
            </v-data-table>

        </console-body>
        
        <v-btn fab fixed bottom right color="accent" @click="create"><v-icon>mdi-pencil</v-icon></v-btn>

    </console-layout>
</template>

<script>
import api from "@/api";
import MessageDialog from "@/components/console/gshop/messages/message-dialog.vue";
export default {
    components: {
        MessageDialog
    },
    data(){
        return {
            messages: [],
            messagesHeaders: [
                { text: "no", value: "no", width: "100", align: "center" },
                { text: "상품권", value: "giftcards", width: "200" },
                { text: "문자내용", value: "endMessage" },
                { text: "actions", value: "actions", width: "100", align: "center" },
            ],
        }
    },
    methods: {
        async init(){
            let { messages } = await api.console.gshop.messages.gets({ headers: { limit: 0 } });
            this.messages = messages;
        },
        create(){
            this.$router.push("/console/gshop/messages/create");
        },
        edit(message){
            this.$router.push(`/console/gshop/messages/${message._id}`);
        },
        async remove(message){
            if(confirm("삭제하시겠습니까?")){
                await api.console.gshop.messages.delete(message);
                alert("삭제되었습니다");
                this.init();
            }
        }
    },

    mounted(){
        this.init();
    }
}
</script>