<template>
    <v-dialog v-model="show" max-width="800">
        <v-card>
            <v-card-title>
                <span class="subtitle-1">엑셀 핀 업로드</span>
                <v-spacer />
                <v-btn color="green" outlined @click="download()">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>
                    <span>엑셀 양식 다운로드</span>
                </v-btn>
            </v-card-title>
            <v-divider/>
            <v-dragdrop-field v-model="file" class="py-4" height="120" @input="upload" />
            <v-divider/>
            <v-data-table :items="pins" :headers="pinsHeaders">
                <template v-slot:item.name="{item}"><span :class="{'red--text': option && option.faceamt != item.faceamt }">{{ item.name }}</span></template>
                <template v-slot:item.pno="{item}"><span :class="{'red--text': option && option.faceamt != item.faceamt }">{{ item.pno }}</span></template>
                <template v-slot:item.status="{item}"><span :class="{'red--text': option && option.faceamt != item.faceamt }">{{ item.status }}</span></template>
                <template v-slot:item.faceamt="{item}"><span :class="{'red--text': option && option.faceamt != item.faceamt }">{{ parseInt(item.faceamt).format() }}</span></template>
            </v-data-table>
            <v-divider />
            <v-card-actions>
                <span class="caption primary--text">
                    <b>* 액면가가 일치 하지 않는 항목은 스킵됩니다.</b>
                </span>
                <v-spacer/>
                <v-btn text color="grey" @click="close">취소</v-btn>
                <v-btn text color="primary" @click="confirm">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import download from "downloadjs";
import { mdiMicrosoftExcel } from "@mdi/js";
import VDragdropField from "@/components/plugins/vuetify/v-dragdrop-field.vue";
export default {
    components: {
        VDragdropField
    },
    props: {
        option: { type: Object, default: null },
        platform: { type: String, default: null }
    },
    data(){
        return {
            mdiMicrosoftExcel,

            show: false,

            file: null,
            excelSheet: [],
            pinsHeaders: [
                { text: "상품권이름", value: "name", align: "center" },
                { text: "핀번호", value: "pno", align: "center" },
                { text: "상태", value: "status", align: "center" },
                { text: "액면가", value: "faceamt", align: "center" },
            ]
        }
    },
    methods: {
        download(){
            api.getResource("/data/example.xls").then(file => download(file, "엑셀 양식.xls"))
        },
        open(){
            this.file = null;
            this.excelSheet = [];
            this.show = true;
        },
        close(){
            this.show = false;
        },
        confirm(){
            this.$emit("input", this.pins);
            this.close();
        },
        upload(file){
            if(!file) return;

            let reader = new FileReader();
            reader.onload = () => {
                let workSheets = [];
                let workBook = XLSX.read(reader.result, { type: "binary" });
                workBook.SheetNames.forEach(sheetName => {
                    let sheet = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
                    workSheets.push(sheet);
                });

                this.excelSheet = workSheets[0];
            }
            reader.readAsBinaryString(file);
        },
    },
    computed: {
        pins(){
            let pins = [];
            for(let row of this.excelSheet){
                pins.push({
                    platform: this.platform || "excel",
                    name: row["상품권이름"],
                    pno: row["핀번호"],
                    status: row["상태"],
                    faceamt: +row["액면가"] || 0,
                })
            }
            return pins;
        }
    }
}
</script>